import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { Text } from '../Text'
import { OverlayService } from '../Overlay'
import { OverlayInstance } from '../Overlay/service'

const TOAST_GROUP = 'bmu_toast'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Toast {
  private static _create({
    content,
    onBackdropPress
  }: {
    content: React.ReactNode
    onBackdropPress?: () => void
  }) {
    return OverlayService.create({
      transparent: true,
      visible: true,
      content: (
        <View style={styles.container}>
          <Text>{content}</Text>
        </View>
      ),
      onBackdropPress,
      contentStyle: {
        marginTop: '-50%'
      },
      group: TOAST_GROUP
    })
  }

  static show(content: React.ReactNode, delay = 2000): OverlayInstance {
    const toast = this._create({
      content:
        typeof content === 'string' ? (
          <Text color='white'>{content}</Text>
        ) : (
          content
        ),
      onBackdropPress: () => toast.destroy()
    })
    const timer = setTimeout(() => {
      toast.destroy()
      clearTimeout(timer)
    }, delay)
    return toast
  }

  static error(content: string, delay = 2000): OverlayInstance {
    return this.show(
      <>
        <View style={styles.content}>❌</View>
        <Text color='white'>{content}</Text>
      </>,
      delay
    )
  }

  static success(content: string, delay = 2000): OverlayInstance {
    return this.show(
      <>
        <View style={styles.content}>
          <Text color='success' size='2xl'>
            √
          </Text>
        </View>
        <Text color='white'>{content}</Text>
      </>,
      delay
    )
  }

  static loading(content: string): OverlayInstance {
    return this._create({
      content: (
        <View
          style={{
            width: 80,
            height: 80,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ActivityIndicator
            style={styles.content}
            color='#f3f4f6'
            size='large'
          />
          <Text color='gray-100'>{content}</Text>
        </View>
      )
    })
  }

  static hide(): void {
    OverlayService.destroyAll(TOAST_GROUP)
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 6,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 3,
    shadowColor: '#000'
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 4
  }
})
