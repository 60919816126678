import { useRef, useEffect } from 'react'
import { Form, FormConfigs, Layout } from '@/components'
import { feedbackType$ } from '@/services/apis'
import { useGlobalData } from '@/hooks/useGlobalData'
import appApi from '@/services/request/app.api'

const configs: FormConfigs = [
  {
    type: 'tagSelect',
    name: 'feedbackType',
    label: '反馈类型',
    required: true,
    props: {
      checkType: 'radio',
      asyncData: feedbackType$,
      containStyle: { paddingVertical: 8 }
    }
  },
  {
    type: 'input',
    name: 'contentDesc',
    label: '内容描述',
    required: true,
    props: {
      placeholder:
        '请详细描述您的问题。（添加截图有助于解决您的问题，期待您的反馈）',
      numberOfLines: 4,
      maxLength: 500,
      multiline: true
    }
  },
  {
    type: 'imageUpload',
    name: 'feedbackImages',
    label: '反馈截图',
    props: {
      limit: 3,
      multiple: true
    }
  },
  {
    type: 'input',
    name: 'mobile',
    label: '手机号',
    props: {
      placeholder: '请输入手机号方便我们与您联系',
      type: 'noline',
      keyboardType: 'numeric',
      maxLength: 11,
      returnKeyType: 'done'
    },
    rules: ['phoneNumber']
  }
]

export default function QuestionFeedback({ navigation }: any) {
  const form = useRef<any>(null)
  const { userInfo } = useGlobalData()

  useEffect(() => {
    form?.current?.setPathValue({
      mobile: userInfo?.mobile
    })
  }, [])

  const onSubmit = async ({
    feedbackType,
    contentDesc,
    feedbackImages,
    mobile
  }: any) =>
    appApi
      .problemFeedbackSumbitUsingPOST({
        feedbackType,
        contentDesc,
        feedbackImages,
        mobile
      })
      .then(() => {
        navigation.replace('Result')
        return true
      })

  return (
    <Layout>
      <Form
        ref={form}
        layout='vertical'
        configs={configs}
        onSubmit={onSubmit}
        subtext='提交'
      />
    </Layout>
  )
}
