/* eslint-disable no-unused-vars */
import { FC, useState, useEffect } from 'react'
import {
  View,
  TouchableOpacity,
  Text,
  StyleProp,
  ViewStyle
} from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { uniqueId } from 'lodash'
import { FileView, Checkbox } from '@/components'
import { Toast } from '../Toast'

interface FileListItem {
  label: string
  link?: string
  html?: string
  key?: string
  onConfirm?: () => void
  signAgree?: boolean
}

type FileItem = FileListItem & {
  key: string
}

export interface AgreeProps {
  value?: boolean
  list: FileListItem[]
  onChange?: (checked: boolean) => void
  containStyle?: StyleProp<ViewStyle>
}

export const Agree: FC<AgreeProps> = (props) => {
  const { value = false, onChange, list = [], containStyle } = props
  const [checked, setChecked] = useState<boolean>(false)
  const [file, setFile] = useState<FileItem>()
  const [fileList, setFileList] = useState<FileItem[]>([])
  const [signedFileKeys, setSignedFileKeys] = useState<string[]>([])
  const tailwind = useTailwind()

  useEffect(() => {
    setChecked(!!value)
  }, [value])

  useEffect(() => {
    const result = list.map((item, index) => ({ ...item, key: `${index}` }))
    setFileList(result)
  }, [list])

  const onCheckChange = () => {
    const signedList = fileList.filter((item) => item?.signAgree)
    const signedKeys = signedList.map((item) => item?.key)
    if (signedKeys.length !== signedFileKeys.length) {
      setChecked(false)
      onChange?.(false)
      return Toast.show('请阅读并签署协议')
    }
    setChecked(!checked)
    onChange?.(!checked)
  }

  const onCancel = () => {
    setFile(undefined)
  }

  const onFileConfirm = () => {
    if (file?.signAgree && file?.key) {
      setSignedFileKeys([...signedFileKeys, file.key])
    }
    return file?.onConfirm?.()
  }

  const renderFileLabel = () => {
    return fileList.map((item) => (
      <TouchableOpacity key={uniqueId()} onPress={() => setFile(item)}>
        <Text style={tailwind('text-primary')}>《{item.label}》</Text>
      </TouchableOpacity>
    ))
  }

  const renderFile = () => {
    if (file) {
      return (
        <FileView
          type={file.link ? 'pdf' : 'html'}
          title={file.label}
          show={!!file}
          contentData={file.link || file.html}
          onCancel={onCancel}
          onConfirm={onFileConfirm}
          signAgree={file?.signAgree}
          isSignFile={signedFileKeys.includes(file.key)}
        />
      )
    }
    return null
  }

  return (
    <View
      style={[tailwind('bg-fill-2 px-1 flex-row py-2 flex-wrap'), containStyle]}
    >
      <Checkbox
        value={checked}
        onChange={onCheckChange}
        containStyle={tailwind('items-center mr-2')}
      />
      <Text>已阅读并同意</Text>
      {renderFileLabel()}
      {renderFile()}
    </View>
  )
}
