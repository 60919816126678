import { useRef, useMemo, useState } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { uniqueId } from 'lodash'
import { Layout, Form, Text, Button, FormConfigs } from '@/components'
import { TRADEHISTORYSHOW } from '@/constants'
import env from '@/envs'
import appApi from '@/services/request/app.api'

export default function HistoryTradeQuery(props: any) {
  const { navigation } = props
  const tailwind = useTailwind()
  const form = useRef<any>()
  const demonForm = useRef<any>()
  const [isDemon, setIsDemon] = useState<boolean>(false)
  const mode = env.mode
  const info = TRADEHISTORYSHOW[mode]

  const onSubmit = (demon: boolean) => {
    if (demon) {
      navigation.navigate('HistoryTradeDetail', {
        page: 'demon'
      })
    } else {
      form.current!.setPathDirty(true)
      if (form.current!.getPathValid()) {
        const result = form.current!.getPathValue()
        const { buyer = {}, seller = {} } = result
        appApi
          .getHistoryTradeDetail({
            buyerOrgCode: buyer.code,
            sellerOrgCode: seller.code
          })
          .then(() => {
            navigation.navigate('HistoryTradeDetail', {
              buyCode: buyer.code,
              sellCode: seller.code,
              bugName: buyer.name,
              sellName: seller.name
            })
          })
      }
    }
  }

  const configs: FormConfigs = useMemo(
    () => [
      {
        type: 'select',
        name: 'buyer',
        label: '买方企业名称',
        required: true,
        props: () => {
          return {
            containerStyle: { border: 'none' },
            placeholder: '请填写企业名称',
            type: 'noline',
            returnKeyType: 'next',
            throttle: true,
            search: true,
            supportInput: false,
            seachOnlyInput: true,
            pagination: true,
            searchKeyword: 'dictValue',
            data: ({ keyword }: any): any =>
              appApi
                .decisionForestCompanyFuzzyQueryUsingGET({
                  companyName: keyword
                })
                .then((res) => {
                  return res.map((item: any) => ({
                    value: {
                      code: item?.creditCode,
                      name: item?.name
                    },
                    label: item?.name,
                    origin: item,
                    key: uniqueId()
                  }))
                })
          }
        }
      },
      {
        type: 'select',
        name: 'seller',
        label: '卖方企业名称',
        required: true,
        deps: [{ path: 'buyer', props: true }],
        props: {
          containerStyle: { border: 'none' },
          placeholder: '请填写企业名称',
          type: 'noline',
          returnKeyType: 'next',
          throttle: true,
          search: true,
          supportInput: false,
          seachOnlyInput: true,
          pagination: true,
          searchKeyword: 'dictValue',
          data: ({ keyword }: any): any =>
            appApi
              .decisionForestCompanyFuzzyQueryUsingGET({
                companyName: keyword
              })
              .then((res) => {
                return res.map((item: any) => ({
                  value: {
                    code: item?.creditCode,
                    name: item?.name
                  },
                  label: item?.name,
                  origin: item,
                  key: uniqueId()
                }))
              })
        },
        rules: [
          {
            message: () => '买卖双方的企业名称不能为同一个，请重新选择',
            validator: (property: any, handler: any) => {
              const { value } = property
              return handler.getPathValue('buyer')?.code !== value?.code
            }
          }
        ]
      },
      {
        render: () => {
          return (
            <View style={tailwind('bg-fill-2')}>
              <Text style={tailwind('text-slate-500')}>说明：</Text>
              <Text style={tailwind('text-slate-500')}>
                您可以输入买卖双方企业名称查询其历史交易记录
              </Text>
            </View>
          )
        }
      },
      {
        name: 'checkTrad',
        deps: [
          { path: 'seller', props: true },
          { path: 'buyer', props: true }
        ],
        render: () => {
          return (
            <Button type='primary' onPress={() => onSubmit(false)}>
              查看交易明细
            </Button>
          )
        }
      },
      {
        name: 'showDemon',
        render: () => {
          return (
            <View style={tailwind('bg-fill-2')}>
              <View style={tailwind('flex-row')}>
                <Text style={tailwind('text-slate-500')}>示例：</Text>
                <TouchableOpacity onPress={() => setIsDemon(true)}>
                  <Text color='primary'>查看演示企业</Text>
                </TouchableOpacity>
              </View>
              <Text style={tailwind('text-slate-500')}>
                您也可以直接点击&quot;查看演示企业&quot;买卖方会自动赋值，然后点击“查看交易明细”来帮助您了解交易明细的相关内容。
              </Text>
            </View>
          )
        }
      }
    ],
    [isDemon]
  )

  const demonConfig = useMemo(
    () => [
      {
        type: 'input',
        name: 'buyerShow',
        label: '买方企业名称',
        value: info.buyCompanyShow,
        props: () => {
          return {
            type: 'noline',
            disabled: true
          }
        }
      },
      {
        type: 'input',
        name: 'sellerShow',
        label: '卖方企业名称',
        value: info.sellCompanyShow,
        props: {
          type: 'noline',
          disabled: true
        }
      },
      {
        render: () => {
          return (
            <View style={tailwind('bg-fill-2')}>
              <Text style={tailwind('text-slate-500')}>说明：</Text>
              <Text style={tailwind('text-slate-500')}>
                您可以输入买卖双方企业名称查询其历史交易记录
              </Text>
            </View>
          )
        }
      },
      {
        name: 'checkTrad',
        deps: [
          { path: 'seller', props: true },
          { path: 'buyer', props: true }
        ],
        render: () => {
          return (
            <Button type='primary' onPress={() => onSubmit(true)}>
              查看交易明细
            </Button>
          )
        }
      },
      {
        name: 'quiteDemon',
        render: () => {
          return (
            <Button type='primary' onPress={() => setIsDemon(false)}>
              退出演示模式
            </Button>
          )
        }
      }
    ],
    []
  )

  const renderForm = () => {
    if (isDemon) {
      return <Form ref={demonForm} configs={demonConfig} />
    }
    return <Form ref={form} configs={configs} />
  }

  return <Layout hasFooterText>{renderForm()}</Layout>
}
