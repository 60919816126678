import { ReactNode } from 'react'
import { View, StyleProp, ViewStyle, Dimensions, Image } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { Text } from '@/components'

const { width } = Dimensions.get('window')

export interface LayoutProps {
  /**
   * @description 内容
   */
  children?: ReactNode
  /**
   * @description 样式
   */
  style?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  /**
   * @description 是否有底部标题
   */
  hasFooterText?: boolean
  /**
   * @description 加载中
   */
  loading?: boolean
  /**
   * @description 加载文案
   * @description 加载中，请稍后
   */
  loadText?: string
}

export const Layout = (props: LayoutProps) => {
  const {
    children,
    style,
    hasFooterText,
    loading = false,
    loadText = '加载中，请稍后',
    containerStyle
  } = props
  const tailwind = useTailwind()

  const renderLoading = () => {
    if (!loading) return null
    return (
      <View
        style={[
          tailwind(
            'w-full h-full absolute bg-black items-center justify-center bg-opacity-20 z-40'
          )
        ]}
      >
        <View style={tailwind('bg-white p-8 rounded')}>
          <Image
            source={{
              uri: require('@img/form-loading.png')
            }}
            resizeMode='contain'
            style={{ width: 100, height: 120, marginBottom: 20 }}
          />
          <Text weight='bold'>{loadText}</Text>
        </View>
      </View>
    )
  }
  return (
    <View style={[tailwind('flex-1 bg-fill-2 relative'), containerStyle]}>
      <View style={[tailwind('flex-1 p-4'), style]}>
        {children}
        {hasFooterText && (
          <View
            style={[
              tailwind('flex items-center fixed bottom-16'),
              { left: width / 2 - 38 }
            ]}
          >
            <Text style={tailwind('text-slate-400 ')}>- 融信易查 -</Text>
          </View>
        )}
      </View>
      {renderLoading()}
    </View>
  )
}
