import { LinkingOptions } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import { keys } from 'lodash'
import configs, { NavigationConfigs } from './NavigatorConfigs'

type LinkValueType = {
  path: string
  screens?: LinkType
}

type LinkType = {
  [path: string]: LinkValueType
}

//  解析路由
const resolveLink = (config: NavigationConfigs) => {
  const links: LinkType = {}
  keys(config).forEach((key) => {
    const path = key
      .replace(/([A-Z]+)/g, '/$1')
      .replace(/^\//, '')
      .toLowerCase()
    const { children } = config[key]
    if (children) {
      const childLinks = resolveLink(children)
      links[key] = { screens: childLinks, path }
    } else {
      links[key] = { path }
    }
  })
  return links
}

export const links = resolveLink(configs)

const linking: LinkingOptions<RNav.RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      // Root: {
      //   screens: {
      //     Home: 'home',
      //     Report: 'report',
      //     Myself: 'myself'
      //   }
      // },
      ...links,
      NotFound: '*'
    }
  }
}

export default linking
