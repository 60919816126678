/* eslint-disable no-shadow */
export const AES_KEY = '15PMe0FTo3fyK0Ps'
export const XXTEA_KEY = '789aa4f8888f20fb0781f6f6bb94b0fe'

export const INCLUDE_METHODS = ['POST', 'PUT', 'PATCH', 'post', 'put', 'patch']
export const EXCLUDE_APIS = ['/rx-file/file/upload']

export const STORAGE_KEYS = {
  accessToken: 'accessToken',
  from: '' // 来源   小程序：miniprogram
}

export const NO_ENCRYPT_STORAGE_KEYS = ['accessToken', 'openid']

export type StorageVariable = typeof STORAGE_KEYS

export enum ReportCreateStatus {
  //  待授权
  AUTH_WAIT = 0,
  //  授权中
  AUTH_PEND = 1,
  //  授权失败
  AUTH_FAIL = 2,
  //  已授权待支付
  AUTH_PAY = 3,
  // 生成中
  AUTH_CREATING = 4,
  //  已取消
  AUTH_ABORT = 5,
  //  已完成
  AUTH_DONE = 6
}

//  历史交易明细演示企业
export const TRADEHISTORYSHOW: any = {
  development: {
    buyCompany: '昂歌信息科技有限公司',
    buyCode: '13370302DAQ9UQMP67',
    buyCompanyShow: '*********',
    sellCompany: '艾提科信传媒有限公司',
    sellCode: '526328227HR1PA6646',
    sellCompanyShow: '*************'
  },
  production: {
    buyCompany: '睿博纸业（深圳）有限公司',
    buyCode: '91440300MA5F901052',
    buyCompanyShow: '************',
    sellCompany: '深圳市创锦纸业有限公司',
    sellCode: '91440300311750183Q',
    sellCompanyShow: '***********'
  }
}

// pdf字典值
export enum SystemPdfTypes {
  PDF_ELECTRONIC_SIGNATURE = '1', // 电子签章服务协议
  PDF_PERSONAL_QUOTA_CONTRACT = '2', // 个人借款额度合同
  PDF_PERSONAL_INFORMATION_AUTH = '3', // 个人信息主体授权书
  PDF_DISCLAIMER = '4', // 免责声明
  PDF_ENTERPRISE_INFORMATION_AUTH = '5', // 企业信息主体授权书
  PDF_SIGNATURE = '6', // 签章
  PDF_PRIVACY_INFORMATION_PROTECTION = '7', // 隐私及信息保护政策
  PDF_USER_SERVICE_AGREEMENT = '8' // 用户服务协议
}
