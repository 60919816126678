import request from '@bigtree/request'
import axios from 'axios'

const RxAppApi = {
  /**
   * 查询报告详情
   */
  selectDetailUsingGET: (query: any) => {
    return request
      .request<any>({
        url: '/rx-app/report/detail',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 被尽调人安心签开户
   */
  personalOpenAccountUsingPOST: (query: any) => {
    return request
      .request<any>({
        url: '/rx-app/noauth/respondent/open-person-account',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 被尽调人授权信息
   */
  getAuthInfoUsingGET: (query: { inviteCode: string }) => {
    return request
      .request<any>({
        url: '/rx-app/noauth/respondent/auth-info',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 被尽调人身份认证
   */
  authInfoUsingPOST: (authInfoVo: RxApp.RespondentAuthInfoVo) => {
    return request
      .request<RxApp.RespondentAuthInfoVo>({
        url: '/rx-app/noauth/respondent/identity-auth',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: authInfoVo
      })
      .then((data) => data.data)
  },
  /**
   * 发送授权码
   */
  sendVerificationCodeUsingPOST: (query: any) => {
    return request
      .request<any>({
        url: '/rx-app/noauth/respondent/send-verification-code',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 被尽调人获取微风企链接
   */
  getNoauthTaxAuthUrlUsingGET: (taxAuthVo: RxApp.TaxAuthVo) => {
    return request
      .request<any>({
        url: '/rx-app/noauth/respondent-auth-url',
        method: 'POST',
        data: taxAuthVo
      })
      .then((data) => data.data)
  },
  /**
   * 确认授权码
   */
  confirmVerificationCodeUsingPOST1: (signVerificationVo: {
    // 授权码
    code?: string
    // 报告编号
    reportId?: string
  }) => {
    return request
      .request<any>({
        url: '/rx-app/noauth/respondent/confirm-verification-code',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: signVerificationVo
      })
      .then((data) => data.data)
  },
  /**
   * 获取报告邀请码
   */
  selectReportInviteCodeUsingGET: (reportId: any) => {
    return request
      .request<RxApp.InviteCodeResultVo>({
        url: `/rx-app/report/get-invite-code/${reportId}`,
        method: 'GET'
      })
      .then((data) => data.data)
  },
  /**
   * 更新授权邀请方式-微信短信
   */
  updateGrantAuthInvitUsingGET: (reportGrantAuthVo: any) => {
    return request
      .request<any>({
        url: '/rx-app/grant-auth/update-auth',
        method: 'POST',
        data: reportGrantAuthVo
      })
      .then((data) => data.data)
  },
  /**
   * 更新授权邀请方式-微风企
   */
  updateGrantAuthUsingGET: (reportGrantAuthVo: any) => {
    return request
      .request<any>({
        url: '/rx-app/grant-auth/update-auth-way',
        method: 'POST',
        data: reportGrantAuthVo
      })
      .then((data) => data.data)
  },
  /**
   * 报告新增
   */
  insertUsingPOST: (saveVo: RxApp.ReportOrderBscSaveVo) => {
    return request
      .request<any>({
        url: '/rx-app/report/insert',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: saveVo
      })
      .then((data) => data.data)
  },
  /**
   * 发送短信邀请
   */
  sendMsgPOST: (payVo: any) => {
    return request
      .request<any>({
        url: '/rx-app/grant-auth-invite/send-sms',
        method: 'POST',
        data: payVo
      })
      .then((data) => data.data)
  },
  /**
   * 上传头像
   */
  updateUserHeadGET: (params: any) => {
    return request
      .request<any>({
        url: '/rx-app/update-headImage',
        method: 'GET',
        params
      })
      .then((data) => data.data)
  },
  /**
   * 用户信息
   */
  selfUsingGET: () => {
    return request
      .request<RxApp.UserBaseInfoVo>({
        url: '/rx-app/self',
        method: 'GET'
      })
      .then((data) => data.data)
  },
  /**
   * 签署免责声明
   */
  signAgreeDisclaimersPOST: (params: any) => {
    return request
      .request<any>({
        url: '/rx-app/sign/sign-disclaimers',
        method: 'POST',
        data: params
      })
      .then((data) => data.data)
  },
  /**
   * 查询报告授权结果
   */
  queryNoauthReportResultPOST: (params: any) => {
    return request
      .request<any>({
        url: '/rx-app/noauth/query-grant-auth-result',
        method: 'POST',
        data: params
      })
      .then((data) => data.data)
  },
  /**
   * 获取滑块验证码相关源图及坐标信息
   */
  getImageVerificationInfoUsingGET: () => {
    return axios
      .create({ baseURL: '/api' })
      .request<any>({
        url: '/rx-app/noauth/captcha/slide-image',
        method: 'GET'
      })
      .then((data) => data.data.data)
  },
  /**
   * 获取微风企授权链接
   */
  getTaxAuthUrlUsingGET: (taxAuthVo: RxApp.TaxAuthVo) => {
    return request
      .request<any>({
        url: '/rx-app/grant-auth/tax-auth-url',
        method: 'GET',
        params: taxAuthVo
      })
      .then((data) => data.data)
  },
  /**
   * 获取微风企授权链接(新)
   */
  getAuthUrlUsingGET: (taxAuthVo: any) => {
    return request
      .request<any>({
        url: '/rx-app/noauth/rpa/getWfqAuthUrl',
        method: 'GET',
        params: taxAuthVo
      })
      .then((data) => data.data)
  },
  /**
   * 报告取消
   */
  cancelUsingPOST: (reportCancelVo: RxApp.ReportCancelVo) => {
    return request
      .request<RxApp.CommonLogicVo>({
        url: '/rx-app/report/cancel',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: reportCancelVo
      })
      .then((data) => data.data)
  },
  /**
   * 历史交易明细
   */
  getHistoryTradeDetail: (params: any) => {
    return request
      .request<any>({
        url: '/rx-app/history-trade-detail',
        method: 'GET',
        params
      })
      .then((data) => data.data)
  },
  /**
   * 决策森林模糊查询企业名称
   */
  decisionForestCompanyFuzzyQueryUsingGET: (query: { companyName: string }) => {
    return request
      .request<RxApp.CompanyFuzzyQueryResultDto[]>({
        url: '/rx-app/third-party/company-name-fuzzy-query',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 历史交易明细演示
   */
  getDemonHistoryTradeDetail: () => {
    return request
      .request<any>({
        url: '/rx-app/history-trade-detail-demo',
        method: 'GET'
      })
      .then((data) => data.data)
  },
  /**
   * 登录
   */
  apploginUsingPOST: (params: any) => {
    return request
      .request<any>({
        url: '/rx-app/noauth/applogin',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: params
      })
      .then((data) => data.data)
  },
  /**
   * 注册
   */
  registUsingPOST: (params: any) => {
    return request
      .request<any>({
        url: '/rx-app/noauth/regist',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: params
      })
      .then((data) => data.data)
  },
  /**
   * 发送短信验证码
   */
  sendSmsUsingPOST: (params: any) => {
    return request
      .request<RxApp.AppMessageResultVo>({
        url: '/rx-app/noauth/send-sms',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: params
      })
      .then((data) => data.data)
  },
  /**
   * 获取微信支付下单配置接口
   */
  getWxPayConfig: (params: any) => {
    return request
      .request<any>({
        url: `/rx-app/pay/report`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: params
      })
      .then((data) => data.data)
  },
  /**
   * js-sdk配置信息
   */
  getJssdkConfigUsingGET: (query: { url: string }) => {
    return request
      .request<any>({
        url: '/rx-app/wechat/noauth/jssdk-config',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 支付订单状态查询
   */
  getPayStatus: (params: any) => {
    return request
      .request<any>({
        url: '/rx-app/pay/query',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: params
      })
      .then((data) => data.data)
  },
  /**
   * 退出登录
   */
  logoutUsingPOST: () => {
    return request
      .request<any>({
        url: '/rx-app/logout',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      })
      .then((data) => data.data)
  },
  /**
   * 问题反馈提交
   */
  problemFeedbackSumbitUsingPOST: (vo: RxApp.WenTiFanKuiCanShu) => {
    return request
      .request<any>({
        url: '/rx-app/problem-feedback-sumbit',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: vo
      })
      .then((data) => data.data)
  },
  /**
   * 电子签章验证码确认
   */
  confirmVerificationCodeUsingGET: (query: { code: string }) => {
    return request
      .request<any>({
        url: '/rx-app/confirm-code',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 电子签章发送验证码
   */
  sendVerificationCodeUsingGET: () => {
    return request
      .request<any>({
        url: '/rx-app/send-code',
        method: 'GET'
      })
      .then((data) => data.data)
  },
  /**
   * 修改登录密码
   */
  updateLoginPassWordUsingGET: (query: any) => {
    return request
      .request<any>({
        url: '/rx-app/update-password',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 校验个人三要素
   */
  checkPersonThreeElementUsingPOST: (vo: RxApp.InquireUserThreeElementVo) => {
    return request
      .request<RxApp.PersonThreeElementResultDto>({
        url: '/rx-app/check-person-three-element',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: vo
      })
      .then((data) => data.data)
  },
  /**
   * 我的企业详情
   */
  inquireUserOrgInfoUsingGET: () => {
    return request
      .request<RxApp.InquireUserOrgInfoVo>({
        url: '/rx-app/orginfo/detail',
        method: 'GET'
      })
      .then((data) => data.data)
  },
  /**
   * 更新我的企业
   */
  orgInfoUpdateUsingPOST: (vo: RxApp.InquireUserOrgInfoVo) => {
    return request
      .request<any>({
        url: '/rx-app/orginfo/update',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: vo
      })
      .then((data) => data.data)
  },
  /**
   * 修改手机号
   */
  updateMobileUsingGET: (query: any) => {
    return request
      .request<any>({
        url: '/rx-app/update-mobile',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 修改城市
   */
  updateCityUsingGET: (query: { inquireCity: string }) => {
    return request
      .request<any>({
        url: '/rx-app/update-city',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 重置密码-短信验证
   */
  resetPasswordUsingPOST: (resetPasswordVo: RxApp.AppResetPasswordVo) => {
    return request
      .request<any>({
        url: '/rx-app/noauth/reset-password',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: resetPasswordVo
      })
      .then((data) => data.data)
  },
  /**
   * 根据报告类型获取报告费用
   */
  getExpenseByTypeUsingGET: (reportType: number) => {
    return request
      .request<RxApp.ExpenseConfigDetailVo>({
        url: `/rx-app/report/getExpenseByType/${reportType}`,
        method: 'GET'
      })
      .then((data) => data.data)
  },
  /**
   * 校验滑块结果
   */
  checkImageSlideResultUsingGET: (query: any) => {
    return request
      .request<RxApp.CaptchaResultDto>({
        url: '/rx-app/noauth/captcha/slide-image/result',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 绑定
   */
  bindingWxPOST: (params: any) => {
    return request
      .request<any>({
        url: '/rx-app/noauth/binding',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: params
      })
      .then((data) => data.data)
  },
  /**
   * 更新我的企业
   */
  orgInfoApplyAddPOST: (params: any) => {
    return request
      .request<any>({
        url: '/rx-app/orginfo/apply-add',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: params
      })
      .then((data) => data.data)
  },
  /**
   * 我的企业详情-申请人
   */
  orginfoApplyDetailGET: () => {
    return request
      .request<RxApp.CaptchaResultDto>({
        url: '/rx-app/orginfo/apply-detail',
        method: 'GET'
      })
      .then((data) => data.data)
  }
}

export default RxAppApi
