import { useEffect, useRef, useState } from 'react'
import { View, Image } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { StackNavigationProp } from '@react-navigation/stack'
import { ScreenParamsList } from '@/navigation/NavigatorConfigs'
import * as ImagePicker from 'expo-image-picker'
import {
  MenuItem,
  Avatar,
  Button,
  Modal,
  Text,
  Layout,
  Toast
} from '@/components'
import { uniqueId } from 'lodash'
import { OverlayInstance } from '@/components/Overlay/service'
import { uploadImageApi, downloadImageApi } from '@/services/file'
import { resolveBinaryFile, logoutUser } from '@/utils/utils'
import { useGlobalData } from '@/hooks/useGlobalData'
import appApi from '@/services/request/app.api'

type Props = {
  navigation: StackNavigationProp<
    RNav.RootTabParamList & ScreenParamsList,
    'Setting'
  >
}

export default function Setting({ navigation }: Props) {
  const tailwind = useTailwind()
  const modalInstance = useRef<OverlayInstance>()
  const [headImg, setHeadImg] = useState<string>()
  const [upImgLoading, setUpImgLoading] = useState<boolean>(false)
  const { userInfo, setGlobalData } = useGlobalData()

  useEffect(() => {
    if (userInfo?.headImage) {
      setUpImgLoading(true)
      downloadImageApi({ fileId: userInfo.headImage }).then(
        async (file: any) => {
          const url = (await resolveBinaryFile(file)) as string
          setHeadImg(url)
          setUpImgLoading(false)
        }
      )
    }
  }, [])

  const onLogout = () => {
    modalInstance.current = Modal.show({
      title: '退出登录',
      confirmText: '确定',
      cancelText: '取消',
      containStyle: tailwind('w-screen'),
      content: (
        <View style={tailwind('justify-center items-center')}>
          <Image
            source={{ uri: require('@img/logout.png'), width: 90, height: 100 }}
          />
          <Text style={tailwind('mt-2')}>确认退出登录吗？</Text>
        </View>
      ),
      onConfirm: () => {
        appApi.logoutUsingPOST().then(async () => {
          await modalInstance.current?.destroy()
          await logoutUser()
        })
      }
    })
  }

  const uploadImage = () => {
    ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      allowsMultipleSelection: false,
      aspect: [4, 4],
      quality: 1
    })
      .then((res: any) => {
        setUpImgLoading(true)
        uploadImageApi({ uri: res?.uri }).then((file) => {
          appApi
            .updateUserHeadGET({ headImage: file?.data?.data?.fileId })
            .then(() => {
              setGlobalData({
                userInfo: { ...userInfo, headImage: file?.data?.data?.fileId }
              })
              setHeadImg(res?.uri)
            })
            .finally(() => setUpImgLoading(false))
        })
      })
      .catch(() => {
        Toast.show('上传图片失败')
      })
  }

  const menus = [
    {
      title: '头像',
      path: 'PersonalIdent',
      subTitle: () => (
        <Avatar
          source={headImg || require('@img/rx-logo.png')}
          style={tailwind('mr-2')}
        />
      ),
      onPress: uploadImage,
      loading: upImgLoading
    },
    { title: '姓名', subTitle: userInfo?.realName || '-', showArrow: false },
    {
      title: '所在城市',
      subTitle: userInfo?.inquireCity?.label,
      onPress: () => navigation.navigate('CityModify')
    },
    {
      title: '手机号',
      subTitle: userInfo?.mobile,
      onPress: () => navigation.navigate('MobileModify')
    },
    {
      title: '修改登录密码',
      onPress: () => navigation.navigate('PasswordModify')
    },
    {
      title: '我的协议',
      onPress: () => navigation.navigate('MyAgree')
    }
  ]

  const renderMenu = () =>
    menus.map((menu) => (
      <MenuItem
        key={uniqueId()}
        title={menu.title}
        subTitle={menu.subTitle}
        onPress={menu.onPress}
        showArrow={menu?.showArrow || true}
        containStyle={tailwind('rounded-lg mb-2 p-4')}
        subTitleStyle={tailwind('pr-2')}
        loading={menu?.loading || false}
      />
    ))

  return (
    <Layout style={tailwind('justify-between')}>
      <View>{renderMenu()}</View>
      <Button type='primary' onPress={onLogout}>
        退出登录
      </Button>
    </Layout>
  )
}
