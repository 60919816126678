export const rules = {
  email: {
    message: () => `邮箱格式不正确！`,
    validator:
      /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
  },
  phoneNumber: {
    message: () => `手机号格式不正确！`,
    validator: ({ value }: any) => !value || /^1\d{10}$/.test(value)
  },
  validCode: {
    message: () => `请输入6位验证码`,
    validator: /^\d{6}$/
  },
  password: {
    message: () => `请输入8~20位，包含数字和字母的密码格式`,
    validator: /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{8,20}$/
  },
  name: {
    message: () => `姓名格式不正确！`,
    validator: /^[\u4e00-\u9fa5]{2,20}$/
  },
  idCard: {
    message: () => `身份证格式不正确！`,
    validator: /^\d{17}[\dX]$/
  },
  contract: {
    message: () => `请阅读并同意相关协议`,
    validator: ({ value }: any) => !!value
  },
  orgName: {
    message: () => `请输入6~32位字符，包含汉字或括号`,
    validator: /^[\u4e00-\u9fa5()（）]{6,32}$/
  },
  orgNo: {
    message: () => `请输入15~32位，包含数字和大写字母的格式`,
    validator: /^[A-Z0-9]{15,32}$/
  }
}

export type FormRules = keyof typeof rules
