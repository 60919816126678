import React, { useMemo } from 'react'
import { ScrollView, useWindowDimensions } from 'react-native'
import ReactECharts from 'echarts-for-react'

export interface IReportBarChartProps {
  data: any
}

export default function ReportBarChart(props: IReportBarChartProps) {
  const { data = {} } = props

  const { width: windowWidth } = useWindowDimensions()

  const option: any = useMemo(() => {
    return {
      grid: { top: 20, right: 8, bottom: 40, left: 0, containLabel: true },
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: data.categories
      },
      series: data.series?.map((item: any) => ({
        data: item.data,
        type: 'bar',
        name: item.name,
        colorBy: 'data',
        barWidth: 30,
        label: {
          show: true,
          position: 'inside',
          color: '#fff'
        }
      })),
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        type: 'plain',
        bottom: 10
      },
      color: data.series?.[0].data.map((item: any) => item.color)
    }
  }, [data])

  return (
    <ScrollView horizontal style={{ flex: 1 }}>
      <ReactECharts option={option} style={{ width: windowWidth - 50 }} />
    </ScrollView>
  )
}
