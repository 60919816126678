import { useState, useEffect } from 'react'
import * as Font from 'expo-font'
import { Ionicons } from '@expo/vector-icons'
import * as SplashScreen from 'expo-splash-screen'
import to from 'await-to-js'
import { noAuthConfig } from '@/navigation/NavigatorConfigs'
// import { isWeixin, formatUrlParams } from '@/utils/utils'
import { Storage } from '@/utils/storage'
import { initRequestConfig } from '@/utils/request'
// import { wxSilenceAuth } from '@/utils/wx'
import appApi from '@/services/request/app.api'
import * as Linking from 'expo-linking'
import { STORAGE_KEYS } from '@/constants'

const font = require('../assets/fonts/SpaceMono-Regular.ttf')

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false)

  const tasks = [
    async () => {
      await Font.loadAsync({
        ...Ionicons.font,
        'space-mono': font
      })
      await Storage.initItem()
      await initRequestConfig()

      if (global.accessToken && !global.userInfo) {
        const [error, res] = await to(appApi.selfUsingGET())
        global.userInfo = error ? undefined : res
      }
    }
  ]

  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      const initUrl = await Linking.getInitialURL()
      const {
        queryParams: { accessToken, from }
      } = Linking.parse(initUrl!)
      if (accessToken) {
        await Storage.setItem(STORAGE_KEYS.accessToken, accessToken)
        await Storage.setItem(STORAGE_KEYS.from, from)
      }
      await SplashScreen.preventAutoHideAsync()

      if (noAuthConfig.includes(location.pathname)) {
        await Storage.removeItem('accessToken')
      }
      // if (location.pathname === '/' && isWeixin) {
      //   await Storage.getItem('accessToken')

      //   return global.accessToken
      //     ? location.replace(`${location.origin}/home`)
      //     : wxSilenceAuth('/login')
      // }
      // const openid = formatUrlParams(location.search, 'openid')
      // if (openid) {
      //   global.openid = openid
      //   await Storage.setOpenid(openid)
      //   return location.assign(location.origin + location.pathname)
      // }
      Promise.allSettled(tasks.map((task) => task())).finally(() => {
        setLoadingComplete(true)
        SplashScreen.hideAsync()
      })
    }

    loadResourcesAndDataAsync()
  }, [])

  return isLoadingComplete
}
