import React, { useEffect } from 'react'

export default function App(props: any) {
  const { route } = props
  const { t } = route?.params || {}

  useEffect(() => {
    if (t) {
      window.location.href = `weixin://dl/business/?t=${t}`
    }
  }, [])
  return <view />
}
