import React, { useEffect, useMemo, useState, useContext } from 'react'
import {
  View,
  StyleSheet,
  ImageBackground,
  Image,
  Text,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions
} from 'react-native'
import { images$, repayMethodDict } from '@/services/apis/dict.api'
import { AntDesign } from '@expo/vector-icons'
import { Popup, Grid } from 'antd-mobile'
import { formatMoney } from '@/utils/utils'
import ReportDownload from './components/ReportDownload'
import { ReportContext } from './index'

const imgUrl = `${location.origin}/api/rx-file/file/noauth/preview/`
const levels: any[] = [
  {
    level: 'A',
    color: '#00BB2B',
    range: '[85,100]',
    text: '经营状况优秀，发展趋势好，基本不会发生逾期'
  },
  {
    level: 'B',
    color: '#96CE18',
    range: '(77,85]',
    text: '运营情况良好，业务稳步发展，逾期风险低'
  },
  {
    level: 'C',
    color: '#D3C609',
    range: '(67,77]',
    text: '经营状况一般，逾期风险较低'
  },
  {
    level: 'D',
    color: '#EFA924',
    range: '(60,67]',
    text: '经营状况一般，逾期风险较高'
  },
  {
    level: 'E',
    color: '#D26C13',
    range: '(45,60]',
    text: '经营状况不佳，有一定概率发生逾期'
  },
  {
    level: 'F',
    color: '#B92C00',
    range: '[0,45]',
    text: '经营状况极差，大概率发生逾期'
  }
]

export interface IReportIndexProps {
  createTime?: string
  riskLevel?: string
  riskScore?: string | number
  showRiskResult?: boolean
  title?: string
  enterpriseName?: string
  // showYunKuaiJi?: boolean
  amount?: string
  amountTerm?: string
  compositeRate?: string
  repayModeTerm?: Array<any>
  // 0 默认融信logo 1 云会计 2 云会计
  source?: string
  onImgLoad?: () => void
}

export default function ReportIndex(props: IReportIndexProps) {
  const {
    createTime,
    riskLevel,
    riskScore,
    showRiskResult,
    title,
    enterpriseName,
    // showYunKuaiJi,
    source,
    amount,
    amountTerm,
    compositeRate,
    repayModeTerm = [],
    onImgLoad
  } = props

  const reportContext = useContext(ReportContext)

  const [imgs, setImgs] = useState<any[]>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [repaymentDict, setRepaymentDice] = useState<any[]>([])
  const [repayList, setrepayList] = useState<any[]>([])

  const { height: windowHeight } = useWindowDimensions()

  useEffect(() => {
    images$().then((res: any[]) => {
      setImgs(res)
    })
    repayMethodDict().then((res: any) => {
      setRepaymentDice(res)
    })
  }, [])

  useEffect(() => {
    if (repayModeTerm?.length) {
      setrepayList(
        repayModeTerm.map((item) => {
          return {
            ...item,
            repayMode: repaymentDict.find((i) => i.value === item.repayMode)
              ?.label
          }
        })
      )
    }
  }, [repaymentDict, repayModeTerm])

  const leverBgColor = useMemo(() => {
    switch (riskLevel) {
      case 'A':
        return '#00BB2B'
      case 'B':
        return '#96CE18'
      case 'C':
        return '#D3C609'
      case 'D':
        return '#EFA924'
      case 'E':
        return '#D26C13'
      case 'F':
        return '#B92C00'
      default:
        return ''
    }
  }, [riskLevel])

  const textColor = useMemo(() => {
    switch (source) {
      case '2':
        return '#000000'
      default:
        return '#ffffff'
    }
  }, [source])

  const handleOpenPop = () => {
    setVisible(true)
  }

  const renderLogo = () => {
    if (source === '1') {
      return (
        <Image
          source={{ uri: require('@img/logo-yunkuaiji.png') }}
          style={styles.logo_ykj}
        />
      )
    }

    if (source === '2') {
      return (
        <Image
          // source={{ uri: require('@img/logo-yunkuaiji-white.jpg') }}
          source={{ uri: require('@img/logo-yunkuaiji-2.png') }}
          style={styles.logo_ykj_white}
        />
      )
    }

    return (
      <>
        <Image
          source={{ uri: require('@img/logo_white.png') }}
          style={styles.logo}
        />
        <View>
          <Text style={styles.headerText}>029-85328357</Text>
          <Text style={styles.headerText}>https://new.13315.org</Text>
        </View>
      </>
    )
  }

  return (
    <View style={styles.container}>
      <ImageBackground
        // eslint-disable-next-line no-unsafe-optional-chaining
        source={{
          uri:
            source === '2'
              ? require('@img/background-yunkuaiji.png')
              : imgUrl + imgs[2]?.label
        }}
        resizeMode='cover'
        style={styles.imgBg}
        onLoadEnd={onImgLoad}
      >
        <ScrollView
          // @ts-ignore
          style={[styles.content, { height: windowHeight - 8 }]}
        >
          <View style={styles.header}>{renderLogo()}</View>
          <View style={styles.title}>
            <Text style={[styles.titleText, { color: textColor }]}>
              {title}
            </Text>
            <Text style={[styles.titleDes, { color: textColor }]}>
              {enterpriseName}
            </Text>
          </View>
          {showRiskResult && (
            <View style={styles.score}>
              <View>
                <Text style={[styles.scoreNumTitle, { color: textColor }]}>
                  {source === '2' ? '综合评分' : '信用评分'}
                </Text>
                <View style={styles.scoreNum}>
                  <Text style={[styles.scoreNumBText, { color: textColor }]}>
                    {riskScore}
                  </Text>
                  <Text style={[styles.scoreNumSText, { color: textColor }]}>
                    /100
                  </Text>
                </View>
              </View>
              <View>
                <View style={styles.scoreLevelTitleWrap}>
                  <Text style={[styles.scoreLevelTitle, { color: textColor }]}>
                    {source === '2' ? '综合评级' : '信用评级'}
                  </Text>
                  <TouchableOpacity onPress={handleOpenPop}>
                    <AntDesign
                      name='questioncircle'
                      size={14}
                      style={[{ color: textColor }]}
                    />
                  </TouchableOpacity>
                </View>
                <View
                  style={[
                    styles.scoreLevelIcon,
                    { backgroundColor: leverBgColor }
                  ]}
                >
                  <Text style={styles.scoreLevelText}>{riskLevel}</Text>
                </View>
              </View>
            </View>
          )}
          {source === '1' && (
            <View style={styles.ykjWrap}>
              <Text style={styles.ykjTitle}>授信信息</Text>
              <View style={styles.ykjInfoWrap}>
                <View style={styles.ykjItem}>
                  <Text style={styles.ykjItemL}>授信额度（元）</Text>
                  <Text style={styles.ykjItemR}>{formatMoney(amount)}</Text>
                </View>
                <View style={styles.ykjItem}>
                  <Text style={styles.ykjItemL}>额度期限（月）</Text>
                  <Text style={styles.ykjItemR}>{amountTerm}</Text>
                </View>
                <View style={[styles.ykjItem, { borderBottomWidth: 0 }]}>
                  <Text style={styles.ykjItemL}>综合费率（%）</Text>
                  <Text style={styles.ykjItemR}>{compositeRate}</Text>
                </View>
              </View>
              <Text style={[styles.ykjTitle]}>还款方式及期限</Text>
              <View style={styles.repayWrap}>
                <Grid columns={2}>
                  <Grid.Item
                    style={{
                      backgroundColor: '#7FA0FF'
                    }}
                  >
                    <View style={styles.repayItem}>
                      <Text style={styles.repayItemTitle}>还款方式</Text>
                    </View>
                  </Grid.Item>
                  <Grid.Item style={{ backgroundColor: '#7FA0FF' }}>
                    <View style={styles.repayItem}>
                      <Text style={styles.repayItemTitle}>还款期限（月）</Text>
                    </View>
                  </Grid.Item>
                  {repayList.map((item, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid.Item span={2} key={String(index)}>
                        <View style={styles.repayItemWrap}>
                          <View style={styles.repayItem}>
                            <Text style={[styles.repayItemText]}>
                              {item?.repayMode}
                            </Text>
                          </View>
                          <View style={styles.repayItem}>
                            <Text style={styles.repayItemText}>
                              {item?.repayTerm}
                            </Text>
                          </View>
                        </View>
                      </Grid.Item>
                    )
                  })}
                </Grid>
              </View>
            </View>
          )}
          <View style={styles.footer}>
            <Text
              style={[
                styles.footerText,
                styles.footerTimeText,
                { color: textColor }
              ]}
            >{`本报告生成时间为${createTime}，您所看的内容为截止时间点的数据快照。`}</Text>
            <Text style={[styles.footerText, { color: textColor }]}>
              {`本报告内容为截至该时间点${
                source === '2' ? '企业' : '信用主体'
              }在相关信息公示、公开信息数据整理分析所得，我司不对本报告的全面、准确、真实性进行分辨和核验，不负相关法律责任，但承诺在信息整合、汇总、展示的全过程中保持客观、中立的地位。本报告内容不构成我们对任何人或企业之明示或暗示的观点或保证，仅为您提供参考，数据真实性请以各官方网站公布信息为准。`}
            </Text>
            <Text style={[styles.footerText, { color: textColor }]}>
              本报告仅供商业决策参考之用，不构成对任何商业决策的建议，不得用作法律诉讼的依据或是其他非法或未经授权的用途。未经我司同意或授权，不得向第三人透露本报告任何内容，在任何情况下，对由于参考本报告所造成的损失，我司不承担任何责任。如对本报告内容有任何疑问，请与公开信息数据平台通过其公示联系方式取得联系。
            </Text>
          </View>
        </ScrollView>
      </ImageBackground>
      <Popup
        visible={visible}
        closeOnMaskClick
        position='bottom'
        showCloseButton
        bodyStyle={{
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px'
        }}
        onClose={() => {
          setVisible(false)
        }}
      >
        <View style={styles.popContainer}>
          <Text style={styles.popTitle}>综合评级说明</Text>
          <Text style={styles.popDes}>
            在分析评价查询企业时，综合考虑了企业的工商行为、司法涉诉情况、交易表现、纳税行为、财务状况等信息，最终呈现的评分、评级仅供用户参考。综合评级的含义如下：
          </Text>
          <View style={styles.popLevels}>
            {levels.map((item) => (
              <View
                key={item.level}
                style={[styles.popLevelItem, { borderLeftColor: item.color }]}
              >
                <Text style={{ fontWeight: '600', fontSize: 20 }}>
                  {item.level}
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    color: 'rgba(0,0,0,0.8)',
                    marginLeft: 8
                  }}
                >
                  {item.range}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    color: 'rgba(0,0,0,0.8)',
                    marginLeft: 8
                  }}
                >
                  {item.text}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </Popup>
      <ReportDownload onConfirm={reportContext.onDownload} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  imgBg: {
    flex: 1
  },
  content: {
    paddingHorizontal: 24,
    paddingVertical: 32
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  logo: {
    width: 89,
    height: 32
  },
  logo_ykj: {
    width: 150,
    height: 63
  },
  logo_ykj_white: {
    width: 137,
    height: 36
  },
  headerText: {
    color: '#fff'
  },
  title: {
    marginTop: '20%'
  },
  titleText: {
    fontSize: 28,
    fontWeight: '600',
    color: '#fff'
  },
  titleDes: {
    fontSize: 25,
    color: '#fff'
  },
  score: {
    marginTop: '15%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  scoreNum: {
    marginTop: 8,
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  scoreNumTitle: {
    fontSize: 14,
    color: '#fff'
  },
  scoreNumBText: {
    fontSize: 48,
    color: '#fff'
  },
  scoreNumSText: {
    fontSize: 20,
    color: '#fff',
    lineHeight: 38,
    marginLeft: 8
  },
  scoreLevelTitleWrap: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  scoreLevelTitle: {
    fontSize: 14,
    color: '#fff',
    marginRight: 16
  },
  scoreLevelIcon: {
    width: 54,
    height: 54,
    borderRadius: 54,
    marginTop: 8,
    backgroundColor: 'red',
    justifyContent: 'center',
    alignItems: 'center'
  },
  scoreLevelText: {
    fontSize: 40,
    color: '#fff'
  },
  ykjWrap: {
    marginTop: 24,
    padding: 16,
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: 6
  },
  ykjTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#ffffff'
  },
  ykjInfoWrap: {
    marginTop: 8,
    marginBottom: 16,
    width: '100%',
    backgroundColor: '#ffffff',
    borderRadius: 6,
    paddingLeft: 16,
    paddingRight: 16
  },
  ykjItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.2)'
  },
  ykjItemL: {
    fontSize: 14,
    color: 'rgba(0,0,0,0.8)',
    fontWeight: '600'
  },
  ykjItemR: {
    fontSize: 14,
    color: 'rgba(0,0,0,0.5)'
  },
  repayWrap: {
    marginTop: 8,
    backgroundColor: '#ffffff',
    borderRadius: 6,
    overflow: 'hidden'
  },
  repayItemWrap: {
    flexDirection: 'row'
  },
  repayItem: {
    flex: 1,
    justifyContent: 'center',
    padding: 16,
    borderRightWidth: 1,
    borderRightColor: 'rgba(0,0,0,0.2)',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.2)'
  },
  repayItemTitle: {
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 'bold'
  },
  repayItemText: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: 14
  },
  footer: {
    marginTop: '15%'
  },
  footerText: {
    fontSize: 12,
    color: '#fff',
    marginBottom: 16
  },
  footerTimeText: {
    fontWeight: '600'
  },
  popContainer: {
    padding: 16
  },
  popTitle: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: 21
  },
  popDes: {
    fontSize: 14,
    color: 'rgba(0,0,0,0.7)',
    lineHeight: 24,
    marginTop: 32
  },
  popLevels: {
    marginVertical: 16
  },
  popLevelItem: {
    borderRadius: 4,
    borderLeftWidth: 8,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    height: 36,
    backgroundColor: '#F7F7F7',
    padding: 8
  }
})
