import * as React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { useTailwind } from 'tailwind-rn'

export interface DividerProps {
  /**
   * @description 水平还是垂直类型
   * @default default
   */
  type?: 'horizontal' | 'vertical'
  style?: StyleProp<ViewStyle>
}

export const Divider: React.FC<DividerProps> = ({
  type = 'horizontal',
  style
}) => {
  const tailwind = useTailwind()
  return (
    <View
      style={[
        tailwind('border-gray-200'),
        type === 'vertical'
          ? {
              borderLeftWidth: StyleSheet.hairlineWidth,
              marginHorizontal: 8
            }
          : {
              borderTopWidth: StyleSheet.hairlineWidth,
              marginVertical: 8
            },
        style
      ]}
    />
  )
}
