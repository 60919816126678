/* eslint-disable no-unused-vars */
import { cached } from '@/utils/utils'
import { get, identity } from 'lodash'
import RxSystemApi from './system.api'

export type DictRequestOptions = {
  labelField?: string
  valueField?: string
  labelFormatter?: (label: string) => string
  valueFormatter?: (value: any) => any
  filterParams?: any
  dctGrp?: string
}

/**
 * 获取数据
 * @param dctTpCd 字典库查询关键字
 * @param filterParams
 * @param options.labelField label 字段
 * @param options.valueField value 字段
 * @param options.labelFormatter 格式化 label
 * @param options.valueFormatter 格式化 value
 */
export function getDict(
  dictType: string,
  options?: DictRequestOptions
): Promise<any> {
  return RxSystemApi.pageNoAuthUsingGET({
    dictType,
    page: 1,
    limit: 999,
    ...options?.filterParams
  })
    .then((data: any) => {
      const {
        labelField = 'dictLabel',
        valueField = 'dictValue',
        labelFormatter = identity,
        valueFormatter = identity
      } = options || {}

      if (!Array.isArray(data)) {
        console.error(
          'parseDictResp(): 数据字典 response 解析错误, data 必须是数组类型'
        )
        return []
      }

      return data.map((item: any) => ({
        value: valueFormatter(get(item, valueField)),
        label: labelFormatter(get(item, labelField)),
        origin: item
      }))
    })
    .catch((err) => {
      console.error(err)
    })
}

export const dictUrl = '/rx-system/dict-data/page'

/**
 * 报告类型
 */
export const reportType$ = cached(() => getDict('ReportType'))

/**
 * 报告状态
 */
export const reportStatus$ = cached(() => getDict('ReportStatus'))

/**
 * 反馈类型
 */
export const feedbackType$ = cached(() => getDict('feedbacktype'))

/**
 * 地区
 */
export const areaCity$ = cached((options) => getDict('area_city', options))

/**
 * 图片
 */
export const images$ = cached(() => getDict('SystemPng'))
/**
 * 还款方式
 */
export const repayMethodDict = cached(() => getDict('RepayMethod'))
