import { AES, enc, mode, pad } from 'crypto-js'
import { AES_KEY } from '@/constants'

const iv = enc.Utf8.parse(AES_KEY)
/**
 * 加密
 * @param message
 * @param key
 */
export const encrypt = (message: string, key = AES_KEY) => {
  const parseKey = enc.Utf8.parse(key)
  return AES.encrypt(message, parseKey, {
    iv,
    mode: mode.ECB,
    padding: pad.Pkcs7
  }).toString()
}

/**
 * 解密
 * @param encryptData
 * @param key
 */
export const decrypt = (encryptData: string, key = AES_KEY) => {
  const parseKey = enc.Utf8.parse(key)
  return AES.decrypt(encryptData, parseKey, {
    iv,
    mode: mode.ECB,
    padding: pad.Pkcs7
  }).toString(enc.Utf8)
}
