/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState, useEffect, ReactElement } from 'react'
import { TouchableOpacity, View, ActivityIndicator, Image } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import { ImagePickerResult } from 'expo-image-picker'
import { clone, isBoolean, sum, get, uniqueId } from 'lodash'
import { useTailwind } from 'tailwind-rn'
import { Text, Modal, Toast } from '@/components'
import { AntDesign } from '@expo/vector-icons'
import {
  isWeb,
  isBase64Img,
  getBase64ImgSize,
  compressImage,
  isPromise,
  resolveBinaryFile
} from '@/utils/utils'
import {
  eachGetFileUrl,
  uploadImageApi,
  downloadImageApi
} from '@/services/file'

export type callbackDataType = {
  fileId: string
  fileIdDesc: string
  uri: string
}

export interface ImageUploadProps {
  style?: any

  children?: ReactElement

  title?: string // 提示
  value?: string | string[]

  decryptValue?: string | string[]

  imgName?: string // 上传图片名字
  beforeUpload?: (
    img: ImagePickerResult,
    result: callbackDataType[] | null,
    otherFiles: any | any[]
  ) => boolean | Promise<boolean> // 上传之前，控制选中文件是否可以上传
  onChange?: (result: callbackDataType | callbackDataType[] | null) => void
  enableDownloadCallback?: boolean

  updateImg?: boolean // default true 是否需要上传图片
  justUpImg?: boolean // default false 仅上传图片，不展示图片
  disabled?: boolean // default false 禁用
  disabledMsg?: string // default '' 禁用提示
  maxImageSize?: number // default 5
  isIdCard?: boolean // 身份证有固定压缩规则，和 PC 一致
  multiple?: boolean

  limit?: number // 限制上传图片数量，multiple为true生效  默认1
  totalMaxSize?: number // 限制上传图片总大小 单位MB 0为不限制
  uploadUrl?: string //  上传地址
  downloadUrl?: string //  下载地址
  uploadConfig?: any //  上传http请求配置
  downloadConfig?: any //  下载http请求配置
  onlyFileId?: boolean
}

export const ImageUpload: React.FC<any> = (props: any) => {
  const {
    title = '点击上传',
    disabled = false,
    children = <AntDesign name='upload' size={16} color='gray' />,
    multiple = false,
    enableDownloadCallback = true,
    updateImg = true,
    isIdCard = false,
    maxImageSize = 5,
    uploadUrl = '/rx-file/file/upload',
    downloadUrl = `/rx-file/file/download`,
    uploadConfig = {},
    downloadConfig = {},
    style,
    limit,
    value,
    decryptValue,
    onChange,
    imgName,
    disabledMsg,
    totalMaxSize,
    beforeUpload,
    onlyFileId = true
  } = props
  const tailwind = useTailwind()
  const [selectedImage, setSelectedImage] = useState<string[]>([])
  const [otherFiles, setOtherFiles] = useState<any[]>([])
  const [fileIds, setFileIds] = useState<string[]>([])
  const [fileIdDescs, setFileIdDescs] = useState<string[]>([])
  const [totalSize, setTotalSize] = useState<number>(0)
  const [sizeArr, setSizeArr] = useState<number[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showImg, setShowImg] = useState<string>()
  const [downLoading, setDownLoading] = useState<boolean>(false)

  const onEchoSetFileIds = async (
    _fileIds: any[],
    imgUrls: any[],
    cb?: any
  ) => {
    if (isWeb) {
      const imgs: string[] = []
      const _sizeArr: number[] = []

      for (const item of _fileIds) {
        setDownLoading(true)
        const data = await downloadImageApi({
          fileId: item,
          downloadUrl,
          downloadConfig
        })
        if (data.data?.size > 0) {
          if (!/^image\/.+/.test(data.data.type)) {
            const fileName =
              data?.headers['content-disposition']?.replace(
                /^.+filename=(.+)$/,
                '$1'
              ) || ''
            otherFiles[imgs.length] = { fileName }
            setOtherFiles(otherFiles)
          }
          const url = (await resolveBinaryFile(data)) as string
          setSelectedImage([...imgs, url])
          setDownLoading(false)
          _sizeArr.push(data.data?.size)
          setSizeArr(_sizeArr)
          setTotalSize(sum(_sizeArr))
          cb && cb()
        }
      }
    } else {
      setSelectedImage(imgUrls)
      cb && cb()
    }
  }

  useEffect(() => {
    if (value) {
      const fIds = onlyFileId ? value.split(',') : value
      if (decryptValue && get(fIds, '[0]', '').includes('FID')) return
      const decryptfIds = decryptValue
        ? Array.isArray(decryptValue)
          ? decryptValue
          : [decryptValue]
        : null
      const imgs = eachGetFileUrl(fIds)

      if (fileIds.length !== fIds.length) {
        onEchoSetFileIds(fIds, imgs, () => {
          setFileIdDescs(decryptfIds || fIds)
          setFileIds(fIds)
        })
        if (onChange && enableDownloadCallback) {
          const result = multiple
            ? imgs.map((url, i) => ({
                uri: url,
                fileId: fIds[i],
                fileIdDesc: (decryptfIds || fIds)[i]
              }))
            : {
                uri: imgs[0],
                fileId: fIds[0],
                fileIdDesc: (decryptfIds || fIds)[0]
              }

          onChange(onlyFileId ? (multiple ? fIds.join() : fIds[0]) : result)
        }
      }
    }
  }, [value])

  const _updateImg = (uri: string, name: string) => {
    setLoading(true)
    let extension
    if (isWeb) {
      extension = uri.match(/image\/(\S*);/)?.[1] || ''
    } else {
      extension = uri.split('.')[1]
    }
    const imageName = `${name}.${extension}`
    uploadImageApi({ uri, uploadUrl, uploadConfig, imgName: imageName })
      .then((data) => {
        setLoading(false)
        const { fileId, fileIdDesc } = data?.data?.data || {}
        // 图片上传成功后再显示图片
        if (fileId) {
          const selectedImageResult = [...selectedImage, uri]
          const fileIdsResult = [...fileIds, fileId]
          const fileIdDescsResult = [...fileIdDescs, fileIdDesc]
          setSelectedImage(selectedImageResult)
          setFileIds(fileIdsResult)
          setFileIdDescs(fileIdDescsResult)

          if (onChange) {
            const result = multiple
              ? selectedImageResult.map((url, i) => ({
                  uri: url,
                  fileId: fileIdsResult[i],
                  fileIdDesc: fileIdDescsResult[i]
                }))
              : {
                  uri: selectedImageResult[0],
                  fileId: fileIdsResult[0],
                  fileIdDesc: fileIdDescsResult[0]
                }
            onChange(
              onlyFileId
                ? multiple
                  ? fileIdsResult.join()
                  : fileIdsResult[0]
                : result
            )
          }
        }
      })
      .catch(() => {
        Toast.show('上传图片失败')
        setLoading(false)
      })
  }

  const uploadImage = (base64Str: string) => {
    // 网络请求
    if (updateImg) {
      _updateImg(base64Str, imgName)
    } else {
      const selectedImageResult = [...selectedImage, base64Str]
      setSelectedImage(selectedImageResult)

      if (onChange) {
        const result = multiple
          ? selectedImageResult.map((url, i) => ({
              uri: url,
              fileId: fileIds[i],
              fileIdDesc: fileIdDescs[i]
            }))
          : {
              uri: selectedImageResult[0],
              fileId: fileIds[0],
              fileIdDesc: fileIdDescs[0]
            }
        onChange(onlyFileId ? (multiple ? fileIds.join() : fileIds[0]) : result)
      }
    }
  }

  const _limitSize = (maxSize: number, base64Str: string) => {
    const size = getBase64ImgSize(base64Str)
    if (size > maxSize * 1024 * 1024) {
      Toast.show(`图片不能大于${maxSize}M`)
      return false
    }
    return true
  }

  const _limitTotalSize = (maxSize: number, size: number) => {
    if (size > maxSize * 1024 * 1024) {
      Toast.show(`图片总大小不能大于${maxSize}M`)
      return false
    }
    return true
  }

  const _handleSelectImage = async () => {
    try {
      if (disabled && disabledMsg) {
        return Toast.show(disabledMsg)
      }
      if (loading || downLoading) {
        return Toast.show('图片加载中，请稍后上传')
      }
      const result: ImagePickerResult =
        await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          quality: 0.8
        })
      if (result.cancelled) {
        return
      }
      // 图片压缩
      let base64Str = result.uri
      if (base64Str && isBase64Img(base64Str)) {
        let size = getBase64ImgSize(base64Str)

        if (isIdCard) {
          const quality =
            size > 3 * 1024 * 1024 ? (size > 5 * 1024 * 1024 ? 0.3 : 0.5) : 0.7
          base64Str = await compressImage(base64Str, quality)
          size = getBase64ImgSize(base64Str)
          // console.log(`压缩前：${size/1024/1024}, 压缩后：${getBase64ImgSize(base64Str)/1024/1024}`)
        }

        if (!_limitSize(maxImageSize, base64Str)) {
          return
        }
        const _totalSize = totalSize + size

        if (totalMaxSize && !_limitTotalSize(totalMaxSize, _totalSize)) {
          return
        }
        setTotalSize(totalSize + size)
        const _sizeArr = clone(sizeArr)
        _sizeArr.push(size)
        setSizeArr(_sizeArr)
      }
      if (beforeUpload) {
        const files =
          selectedImage && selectedImage.length > 0
            ? selectedImage.map((img, i) => ({
                uri: img,
                fileId: fileIds[i],
                fileIdDesc: fileIdDescs[i]
              }))
            : null
        const upload = beforeUpload(result, files, otherFiles)
        if (isPromise(upload)) {
          upload.then((data) => {
            data && uploadImage(base64Str)
          })
        } else if (isBoolean(upload)) {
          upload && uploadImage(base64Str)
        } else {
          throw new TypeError('`beforeUpload` return type is error !')
        }
      } else {
        uploadImage(base64Str)
      }
    } catch (E) {
      // eslint-disable-next-line no-console
      console.error(E)
    }
  }

  const deleteImage = (index: number) => {
    const selectedImageResult = selectedImage.filter((_, i) => index !== i)
    const fileIdsResult = fileIds.filter((_, i) => index !== i)
    const fileIdDescsResult = fileIdDescs.filter((_, i) => index !== i)

    if (otherFiles[index]) {
      const otherFilesResult = otherFiles.filter((_, i) => index !== i)
      setOtherFiles(otherFilesResult)
    }

    setSelectedImage(selectedImageResult)
    setFileIds(fileIdsResult)
    setFileIdDescs(fileIdDescsResult)

    setTotalSize(totalSize - sizeArr[index])
    sizeArr.splice(index, 1)
    setSizeArr([...sizeArr])

    if (selectedImage.length > 0) {
      const result = multiple
        ? selectedImageResult.map((url, i) => ({
            uri: url,
            fileId: fileIdsResult[i],
            fileIdDesc: fileIdDescsResult[i]
          }))
        : {
            uri: selectedImageResult[0],
            fileId: fileIdsResult[0],
            fileIdDesc: fileIdDescsResult[0]
          }
      onChange &&
        onChange(
          onlyFileId
            ? multiple
              ? fileIdsResult.join()
              : fileIdsResult[0]
            : result
        )
    } else {
      const result = multiple ? [] : { fileId: '', uri: '', fileIdDesc: '' }
      onChange && onChange(onlyFileId ? '' : result)
    }
  }

  const onShowImage = (uri: string) => setShowImg(uri)

  const _renderMaskLoading = () => {
    return loading || downLoading ? (
      <View
        style={tailwind('absolute w-full h-full justify-center items-center')}
      >
        <ActivityIndicator size='small' color='#0000ff' />
      </View>
    ) : null
  }

  const renderCloseIcon = (i: number) => {
    if (disabled) {
      return null
    }
    return (
      <View style={tailwind('absolute -right-1 -top-1 z-10')}>
        <TouchableOpacity
          style={tailwind('p-2px')}
          activeOpacity={0.6}
          onPress={() => deleteImage(i)}
        >
          <AntDesign name='closecircleo' size={16} color='gray' />
        </TouchableOpacity>
      </View>
    )
  }

  const renderUpImg = () => {
    return selectedImage.map((uri, i) => {
      return (
        <TouchableOpacity
          key={uniqueId()}
          style={[tailwind('h-16 w-16 bg-gray-50 mr-2 text-gray mb-2')]}
          onPress={() => onShowImage(uri)}
        >
          {otherFiles[i] ? (
            <View
              style={tailwind(
                'h-fullrounded justify-center items-center text-xs text-gray'
              )}
            >
              <AntDesign name='file1' size={20} color='gray' />
              <Text
                style={tailwind('mt-2 text-center text-xs text-gray')}
                numberOfLines={3}
              >
                {otherFiles[i].fileName}
              </Text>
            </View>
          ) : (
            <Image
              style={tailwind('rounded w-full h-full border border-gray-200')}
              resizeMode='contain'
              source={{ uri }}
              // onError={() => onError(i)}
            />
          )}
          {renderCloseIcon(i)}
        </TouchableOpacity>
      )
    })
  }

  const renderUpload = () => {
    if (disabled) {
      return null
    }
    if (!multiple && selectedImage.length > 0) {
      return null
    }
    if (multiple && selectedImage.length >= limit) {
      return null
    }
    return (
      <TouchableOpacity
        style={[
          tailwind(
            'h-16 w-16 bg-gray-50 rounded border border-gray-200 justify-center items-center relative mb-2'
          ),
          style
        ]}
        activeOpacity={disabled ? 1 : undefined}
        onPress={_handleSelectImage}
      >
        {children}
        <Text style={tailwind('text-center text-xs text-gray')}>{title}</Text>
        {_renderMaskLoading()}
      </TouchableOpacity>
    )
  }

  const renderImgShow = () => {
    return (
      <Modal
        closable
        title='查看图片'
        style={tailwind('pb-4')}
        visible={!!showImg}
        onCancel={() => setShowImg(undefined)}
        footer={null}
      >
        <Image
          style={[{ minWidth: 280, minHeight: 180 }]}
          resizeMode='cover'
          source={{ uri: showImg }}
        />
      </Modal>
    )
  }

  return (
    <View style={tailwind('items-center flex-row flex-wrap py-2 pl-2')}>
      {renderUpImg()}
      {renderUpload()}
      {renderImgShow()}
    </View>
  )
}
