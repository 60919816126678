import React, { useState, useMemo, useRef } from 'react'
import { TouchableOpacity, StyleSheet, View, Text } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { Modal, Form, Toast } from '@/components'
import { FormHandles } from '@bigtree/form'
import { useTailwind } from 'tailwind-rn'
import { rules } from '@/components/Form/rules'

export interface ReportDownloadProps {
  onConfirm?: (e: string) => void | Promise<any>
}

export default function ReportDownload(props: ReportDownloadProps) {
  const { onConfirm } = props
  const [show, setShow] = useState<boolean>(false)
  const form = useRef<FormHandles>(null)
  const tailwind = useTailwind()
  const configs = useMemo(
    () => [
      {
        type: 'input',
        name: 'email',
        required: true,
        props: {
          placeholder: '请输入邮箱',
          prefix: (
            <AntDesign name='mail' size={20} style={tailwind('text-primary')} />
          ),
          containStyle: tailwind('bg-fill-1')
        },
        rules: [rules.email]
      }
    ],
    []
  )
  const handleShow = () => {
    setShow(true)
  }
  const handleConfirm = async () => {
    const value = form.current?.getPathValue('email')
    if (!value) {
      Toast.show('请输入邮箱')
      return false
    }

    if (!form.current?.getPathValid('email')) {
      return false
    }

    await onConfirm?.(value)
    setShow(false)
    return true
  }
  const onClose = () => {
    setShow(false)
  }
  return (
    <>
      <TouchableOpacity style={styles.toTop} onPress={handleShow}>
        <View style={styles.icon}>
          <AntDesign name='download' size={24} color='rgba(255,255,255,1)' />
        </View>
      </TouchableOpacity>
      <Modal
        title='报告下载'
        visible={show}
        closable
        cancelText='取消'
        onConfirm={handleConfirm}
        onCancel={onClose}
      >
        <Form
          configs={configs}
          ref={form}
          formContainStyle={tailwind('bg-white')}
        />
        <Text>报告将通过邮件的方式给您发送,请查收邮件中的附件</Text>
      </Modal>
    </>
  )
}
const styles = StyleSheet.create({
  toTop: {
    bottom: '18%',
    right: 24,
    zIndex: 999,
    position: 'absolute'
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: 'rgba(138,167,255, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
