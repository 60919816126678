/* eslint-disable no-unused-vars */
import { FC, useEffect, useState } from 'react'
import { Text, TextProps } from '@/components'
import { useTailwind } from 'tailwind-rn'
import { TouchableOpacity } from 'react-native'

export interface TagProps extends TextProps {
  /**
   * @description tag类型
   * @default success
   */
  color?: 'success' | 'brand' | 'warning' | 'default'
  /**
   * @description 可选
   * @default false
   */
  checkable?: boolean
  /**
   * @description 设置标签的选中状态的回调，在 `checkable="true"` 时可用
   */
  onChecked?: (checked: boolean) => void
  /**
   * @description 标签的选中状态，在 `checkable="true"` 时可用
   * @default false
   */
  checked?: boolean
  controllable?: boolean
}

export const Tag: FC<TagProps> = (props) => {
  const {
    children,
    color = 'success',
    checkable,
    onChecked,
    checked = false,
    controllable = false,
    style
  } = props
  const tailwind = useTailwind()

  const [mergedChecked, setChecked] = useState(checked)

  const allColor = {
    success: tailwind(
      'text-success-3 text-xs border border-success-3 py-1 px-3 bg-success-3 bg-opacity-20 rounded-xl'
    ),
    warning: tailwind(
      'text-warning-3 text-xs border border-warning-3 py-1 px-3 bg-warning-3 bg-opacity-20 rounded-xl'
    ),
    brand: tailwind(
      'text-brand-3 text-xs border border-brand-3 py-1 px-3 bg-brand-3 bg-opacity-20 rounded-xl'
    ),
    default: tailwind(
      'text-gray-500 text-xs border border-fill-3 py-1 px-3 rounded-xl'
    )
  }

  useEffect(() => {
    setChecked(checked)
  }, [checked])

  const handlePress = () => {
    if (!checkable) return
    if (!controllable) {
      setChecked(!mergedChecked)
    }
    onChecked?.(!mergedChecked)
  }
  return (
    <TouchableOpacity
      onPress={handlePress}
      style={tailwind('mx-0.5 mb-1 py-2')}
    >
      <Text
        style={[
          allColor[checkable && !mergedChecked ? 'default' : color],
          style
        ]}
      >
        {children}
      </Text>
    </TouchableOpacity>
  )
}
