import request from '@bigtree/request'
import { pickBy } from 'lodash'
import {
  EXCLUDE_APIS,
  INCLUDE_METHODS,
  STORAGE_KEYS,
  XXTEA_KEY
} from '@/constants'
import { Storage } from '@/utils/storage'
import { Toast } from '@/components/Toast'
import { logoutUser } from '@/utils/utils'
import { OverlayService, Modal } from '@/components'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/extensions
import { XXTEA } from './xxtea.js'

export const initRequestConfig = () => {
  request.settingConfig({
    logout: async () => {
      const from = await Storage.getItem(STORAGE_KEYS.from)
      // 在小程序内 不做自动登出处理
      if (from !== 'miniprogram') {
        await OverlayService?.destroyAll()
        await logoutUser()
      }
    },
    message: (content) => {
      Toast.show(content)
    },
    alert: (content) => {
      const modal = Modal.show({
        title: '温馨提示',
        content,
        confirmText: '知道了',
        onConfirm: () => {
          modal?.destroy()
        }
      })
    },
    notification: (content) => {
      Toast.show(content)
    },
    getAccessToken: () => {
      return Storage.getItem(STORAGE_KEYS.accessToken)
    }
  })

  // request.interceptors.response.use((response: any) => {
  //   const { code, message } = response.data
  //   if (+code === 10001401) {
  //     Toast.show(message)
  //     // eslint-disable-next-line prefer-promise-reject-errors
  //     return Promise.reject('用户未登录或登录状态过期')
  //   }
  //   return response
  // })

  // 加密
  request.interceptors.request.use((cfg: any) => {
    const { data, method, url } = cfg
    if (
      data &&
      method &&
      INCLUDE_METHODS.includes(method) &&
      !EXCLUDE_APIS.find((api) => url.includes(api))
    ) {
      return {
        ...cfg,
        data: {
          data: XXTEA.encryptToBase64(
            JSON.stringify(
              pickBy(data, (value) => value !== null && value !== undefined)
            ),
            XXTEA_KEY
          )
        }
      }
    }
    return cfg
  })
}
