/* eslint-disable no-undef */
import { useMemo } from 'react'
import { useTailwind } from 'tailwind-rn'
import { Form, FormConfigs, Layout, Toast } from '@/components'
import { SMSFunctionTypes } from '@/services/apis'
import { rules } from '@/components/Form/rules'
import { useFormItem } from '@/hooks/useFormItem'
import { useGlobalData } from '@/hooks/useGlobalData'
import appApi from '@/services/request/app.api'

const wx = require('weixin-js-sdk')

export default function MobileModify() {
  const tailwind = useTailwind()
  const { userInfo } = useGlobalData()
  const { getValidCodeItem } = useFormItem()

  const onSubmit = async ({ code, mobile }: any) => {
    return appApi
      .updateMobileUsingGET({
        code,
        mobile
      })
      .then(() => {
        Toast.show('修改手机号成功，请重新登录')
        setTimeout(() => {
          try {
            wx.miniProgram.postMessage({
              data: { userInfo: '' }
            })
            wx.miniProgram.navigateBack({ delta: 2 })
          } catch (error) {
            Toast.show(JSON.stringify(error))
          }
        }, 1500)
        return Promise.resolve(true)
      })
  }
  const configs: FormConfigs = useMemo(
    () => [
      {
        type: 'input',
        name: 'oldMobile',
        label: '旧手机号',
        value: userInfo?.mobile,
        props: {
          type: 'noline',
          disabled: true
        }
      },
      {
        type: 'input',
        name: 'mobile',
        label: '新手机号',
        required: true,
        props: {
          placeholder: '请输入新手机号',
          type: 'noline',
          keyboardType: 'numeric',
          maxLength: 11,
          returnKeyType: 'next'
        },
        rules: [
          rules.phoneNumber,
          {
            message: '与原手机号一致',
            validator: (field: any, handler: any) =>
              field.value !== handler.getPathValue('oldMobile')
          }
        ]
      },
      getValidCodeItem({
        name: 'code',
        hasCaptcha: true,
        sendParams: {
          functionType: SMSFunctionTypes.MODIFY_MOBILE
        }
      })
    ],
    []
  )

  return (
    <Layout>
      <Form
        configs={configs}
        fieldStyle={tailwind('bg-white rounded-md')}
        formContainStyle={tailwind('flex-1 justify-between')}
        onSubmit={onSubmit}
        subtext='提交'
        labelStyle={{ minWidth: 90 }}
      />
    </Layout>
  )
}
