/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

export enum SMSFunctionTypes {
  LOGIN = '1', // 登录
  REGISTER = '2', // 注册
  RESET_PASSWORD = '3', // 重置密码
  MODIFY_MOBILE = '4', // 修改手机号
  WXBIND = '5'
}

//  登录方式类型
export enum LoginTypes {
  PASSWORD = '1', // 密码登录
  CODE = '2', // 验证码登录
  WX = '3' // 微信登录
}
