/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import {
  StyleProp,
  View,
  ViewStyle,
  Text,
  TouchableOpacity
} from 'react-native'
import { useTailwind } from 'tailwind-rn'
// import { DatePicker as BaseDatePicker } from '@bigtree/picker'
import { DayPicker } from 'react-day-picker'
import { format } from 'date-fns'
import zhCN from 'date-fns/locale/zh-CN'
import { Popup } from '../Popup'
import { Button } from '../Button'
import 'react-day-picker/dist/style.css'

interface RangePickerProps {
  value?: [string, string] | undefined
  onChange?: (
    value: [string | undefined, string | undefined] | undefined
  ) => void
  containStyle?: StyleProp<ViewStyle>
}

const today = new Date().toISOString().split('T')[0]
export const RangePicker: React.FC<RangePickerProps> = (props) => {
  const { value, onChange, containStyle } = props
  const tailwind = useTailwind()

  const [inputDates, setInputDates] = useState<
    [string | undefined, string | undefined]
  >([undefined, undefined])
  // picker 显示日期，为空隐藏
  const [pickerDate, setPickerDate] = useState<string | null>(null)
  const [disabledDays, setDisabledDays] = useState<any>([])
  const [open, setOpen] = useState(false)

  const currentIndex = useRef<number>(0)

  useEffect(() => {
    setInputDates((value as any) || [])
    onChange?.(value)
  }, [value])

  const toggle = (index: number) => {
    setPickerDate(inputDates[index] ?? today)
    setOpen(true)
    currentIndex.current = index

    const disabledValue =
      currentIndex.current === 0
        ? inputDates[1] && {
            after: new Date(inputDates[1])
          }
        : inputDates[0] && {
            before: new Date(inputDates[0])
          }
    disabledValue && setDisabledDays(disabledValue)
  }

  const handleSubmit = () => {
    inputDates[currentIndex.current] = pickerDate ?? today
    setInputDates([...inputDates])
    onChange?.([...inputDates])
    setOpen(false)
  }

  return (
    <>
      <View style={tailwind('flex-row items-center')}>
        <TouchableOpacity onPress={() => toggle(0)}>
          <View
            style={[
              tailwind('rounded border border-slate-400 p-2 w-28 h-9 mr-2'),
              containStyle
            ]}
          >
            {inputDates?.[0] ? (
              <Text>{inputDates?.[0]?.toString()}</Text>
            ) : (
              <Text style={tailwind('text-slate-400')}>开始日期</Text>
            )}
          </View>
        </TouchableOpacity>
        <Text>-</Text>
        <TouchableOpacity onPress={() => toggle(1)}>
          <View
            style={[
              tailwind('rounded border border-slate-400 p-2 w-28 h-9 ml-2'),
              containStyle
            ]}
          >
            {inputDates?.[1] ? (
              <Text>{inputDates?.[1]?.toString()}</Text>
            ) : (
              <Text style={tailwind('text-slate-400')}>结束日期</Text>
            )}
          </View>
        </TouchableOpacity>
      </View>
      <Popup visible={open} onBackdropPress={() => setOpen(false)}>
        <DayPicker
          locale={zhCN}
          mode='single'
          selected={new Date(pickerDate as string)}
          disabled={disabledDays}
          onSelect={
            ((day: Date, selectedDay: Date) => {
              setPickerDate(format(selectedDay as Date, 'yyyy-MM-dd'))
            }) as any
          }
          defaultMonth={new Date(pickerDate as string)}
        />
        {/* <BaseDatePicker
          format='yyyy-MM-dd'
          onSelected={setPickerDate}
          value={pickerDate!}
        /> */}
        <Button type='primary' onPress={handleSubmit}>
          确认
        </Button>
        <Button onPress={() => setOpen(false)}>取消</Button>
      </Popup>
    </>
  )
}
