import {
  NO_ENCRYPT_STORAGE_KEYS,
  StorageVariable,
  AES_KEY,
  STORAGE_KEYS
} from '@/constants'
import { decrypt, encrypt } from '@/utils/crypto'
import AsyncStorage from '@react-native-async-storage/async-storage'

/**
 * 加密存储
 *
 * - 账号名（手机号）做为key密钥加密所有存储的数据
 * - 账号名直接加密存储到 AES_KEY
 */

export class Storage {
  static initItem() {
    Object.values(STORAGE_KEYS).forEach((key) => {
      this.getItem(key as keyof StorageVariable)
    })
  }

  static getItem(key: string) {
    AsyncStorage.getItem(encrypt(key)).then((value) => {
      if (value) {
        global[key] = NO_ENCRYPT_STORAGE_KEYS.includes(key)
          ? value
          : JSON.parse(decrypt(value, AES_KEY))
      }
    })
    return global[key]
  }

  static setItem(key: string, data: any) {
    global[key] = data
    const encryptValue = NO_ENCRYPT_STORAGE_KEYS.includes(key)
      ? data
      : encrypt(JSON.stringify(data), AES_KEY)
    return AsyncStorage.setItem(encrypt(key), encryptValue)
  }

  static removeItem(key: string) {
    return AsyncStorage.removeItem(encrypt(key))
  }

  static clear() {
    return AsyncStorage.clear()
  }

  static setOpenid(openid: string) {
    return AsyncStorage.setItem(encrypt('openid'), openid)
  }

  static getOpenid() {
    return AsyncStorage.getItem(encrypt('openid')).then((value) => value)
  }
}
