import React from 'react'
import { ActivityIndicator } from 'react-native'
import { StatusBar } from 'expo-status-bar'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Provider } from '@/components/Provider'

import useCachedResources from '@/hooks/useCachedResources'
import Navigation from '@/navigation'
import utilities from '@/styles/tailwind.json'
import shadowUtilities from '@/styles/shadow.json'
import { GlobalDataProvider } from '@/hooks/useGlobalData'

export default function App() {
  const isLoadingComplete = useCachedResources()
  if (!isLoadingComplete)
    return <ActivityIndicator style={{ height: '100%' }} />

  return (
    <SafeAreaProvider>
      <Provider utilities={{ ...shadowUtilities, ...utilities }}>
        <GlobalDataProvider>
          <Navigation />
        </GlobalDataProvider>
        <StatusBar />
      </Provider>
    </SafeAreaProvider>
  )
}
