import { useTailwind } from 'tailwind-rn'
import { uniqueId } from 'lodash'
import { MenuItem, Layout, FileView } from '@/components'
import React, { useEffect, useState } from 'react'
import { agreementList } from '@/services/apis/setting.api'
import { downloadImageApi } from '@/services/file'
import { resolveBinaryFile } from '@/utils/utils'
import { getDict } from '@/services/apis'
import { SystemPdfTypes } from '@/constants'

const protocolList: string[] = [
  SystemPdfTypes.PDF_USER_SERVICE_AGREEMENT,
  SystemPdfTypes.PDF_PRIVACY_INFORMATION_PROTECTION
]

export default function MyAgree() {
  const tailwind = useTailwind()
  const [file, setFile] = useState<any>()
  const [contracts, setContracts] = useState<any>([])
  const [selectFile, setSelectFile] = useState<string>()
  const [userFileList, setUserFileList] = useState<any[]>([])

  useEffect(() => {
    getDict('SystemPdf').then((res) => {
      const listArr: any[] = []
      protocolList.forEach((item) => {
        const filterItem = res.find((el: any) => el.value === item)
        if (filterItem) {
          listArr.push({
            title: filterItem.origin.remark,
            onPress: () => {
              setFile({
                label: filterItem.origin.remark,
                link: `${location.origin}/api/rx-file/file/noauth/preview/${filterItem.label}`
              })
            }
          })
        }
      })
      setUserFileList(listArr)
    })
    if (!contracts?.length) {
      agreementList().then((res) => {
        const list = res?.map?.((item: any) => ({ ...item, key: uniqueId() }))
        setContracts(list)
      })
    }
  }, [])

  const onPress = async (c: any) => {
    if (selectFile) return
    setSelectFile(c.key)
    downloadImageApi({ fileId: c?.signConstractUrl }).then(async (res) => {
      const link = await resolveBinaryFile(res)
      setFile({
        label: c?.title,
        link
      })
      setSelectFile(undefined)
    })
  }

  // const menus = [
  //   {
  //     title: '用户协议',
  //     onPress: () => {
  //       setFile({
  //         label: '用户服务协议',
  //         link: '/static/pdf/用户服务协议.pdf'
  //       })
  //     }
  //   },
  //   {
  //     title: '隐私政策',
  //     onPress: () => {
  //       setFile({
  //         label: '隐私及信息保护政策',
  //         link: '/static/pdf/隐私及信息保护政策.pdf'
  //       })
  //     }
  //   }
  // ]

  const onCancel = () => {
    setFile(null)
  }

  const renderMenu = () =>
    userFileList?.map((menu) => (
      <MenuItem
        key={uniqueId()}
        title={menu.title}
        onPress={menu.onPress}
        showArrow
        containStyle={tailwind('rounded-lg mb-2 p-4')}
      />
    ))

  const renderFile = () => {
    if (!file) return null
    return (
      <FileView
        type='pdf'
        title={file?.label}
        show={!!file}
        contentData={{ url: file.link }}
        onCancel={onCancel}
      />
    )
  }

  return (
    <Layout>
      {renderMenu()}
      {contracts?.map((c: any) => (
        <MenuItem
          key={c.key}
          title={`${c.textTitle}${c.contractNo}`}
          onPress={() => onPress(c)}
          showArrow
          containStyle={tailwind('rounded-lg mb-2 p-4')}
          loading={c.key === selectFile}
        />
      ))}
      {renderFile()}
    </Layout>
  )
}
