import { View } from 'react-native'
import { Input } from '@/components/Input'
import { InputSMS } from '@/components/InputSMS'
import { Checkbox } from '@/components/Checkbox'
import { Agree } from '@/components/Agree'
import { ImageUpload } from '@/components/ImageUpload'
import { TagSelect } from '../TagSelect'
import { RangePicker } from '../RangePicker'
import { DatePicker } from '../DatePicker'
import { Select } from '../Select'

export const registry = {
  input: {
    render: View,
    formRender: Input,
    value: '',
    error: () => ({ status: 'error' }),
    success: () => ({ status: undefined })
  },
  sms: {
    render: View,
    formRender: InputSMS,
    value: '',
    error: () => ({ status: 'error' }),
    success: () => ({ status: undefined })
  },
  checkbox: {
    render: View,
    formRender: Checkbox,
    value: '',
    error: () => ({ status: 'error' }),
    success: () => ({ status: undefined })
  },
  tagSelect: {
    render: View,
    formRender: TagSelect,
    value: '',
    error: () => ({ status: 'error' }),
    success: () => ({ status: undefined })
  },
  agree: {
    render: View,
    formRender: Agree,
    value: '',
    error: () => ({ status: 'error' }),
    success: () => ({ status: undefined })
  },
  imageUpload: {
    render: View,
    formRender: ImageUpload,
    value: '',
    error: () => ({ status: 'error' }),
    success: () => ({ status: undefined })
  },
  datePicker: {
    render: View,
    formRender: DatePicker,
    error: () => ({ status: 'error' }),
    success: () => ({ status: undefined })
  },
  rangePicker: {
    render: View,
    formRender: RangePicker,
    error: () => ({ status: 'error' }),
    success: () => ({ status: undefined })
  },
  select: {
    render: View,
    formRender: ({ value, onChange, state: { props } }: any) => (
      <Select value={value} onSelectedValue={onChange} {...props} />
    ),
    error: () => ({ status: 'error' }),
    success: () => ({ status: undefined })
  }
}

export type FormTypes = keyof typeof registry
