import React, { FC, useEffect, useRef, useState } from 'react'
import {
  ActivityIndicator,
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle
} from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { Document, Page, pdfjs } from 'react-pdf'
import { debounce, max, min, range } from 'lodash'
import { Divider, Modal, Skeleton } from '@/components'
import { AntDesign } from '@expo/vector-icons'
import { Toast } from '../Toast'
import { Text } from '../Text'

const { width: pdfWidth, height } = Dimensions.get('window')
const pageSkeletonItemNum = 24
const skeletonItemSpace = 8

pdfjs.GlobalWorkerOptions.workerSrc = '/static/libs/pdf.worker.min.js'

interface FileViewProps {
  show: boolean
  type: 'pdf' | 'html'
  contentData: any
  title?: string
  containStyle?: StyleProp<ViewStyle>
  signAgree?: boolean
  onCancel?: () => void
  onConfirm?: () => Promise<any> | void
  isSignFile?: boolean
}

export const FileView: FC<FileViewProps> = (props) => {
  const {
    show,
    type = 'pdf',
    title,
    contentData,
    containStyle,
    onCancel,
    signAgree,
    onConfirm,
    isSignFile = false
  } = props
  const tailwind = useTailwind()
  const [totalPage, setTotalPage] = useState<number>(1)
  const [offsetHeight, setOffsetHeight] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [scale, setScale] = useState<number>(1)
  const [confirmText, setConfirmText] = useState<string>('关闭')
  const [isSign, setIsSign] = useState<boolean>(false)
  const _this = useRef<{ page: Page | null; scrollY: number }>({
    page: null,
    scrollY: 0
  }).current

  useEffect(() => {
    if (signAgree) {
      setConfirmText('我已阅读并同意')
    }
  }, [signAgree])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const page = _this.page?.state.page
  const pdfHeight = Math.floor(page?.height ?? 0)
  const pageWidth = Math.floor(page?.width ?? 0)
  const skeletonItemHeight =
    (pdfHeight - 16) / pageSkeletonItemNum - skeletonItemSpace

  useEffect(() => {
    if (isSignFile) {
      setIsSign(true)
      setConfirmText('已签署')
    }
  }, [isSignFile])

  const onPageZoomIn = () => {
    setScale((prevScale) => prevScale + 0.4)
    // updatePageNum(true)
  }

  const onPageZoomOut = () => {
    setScale((prevScale) => prevScale - 0.4)
    // updatePageNum(true)
  }

  const onNextPage = () => {
    setCurrentPage(min([currentPage + 1, totalPage]) as number)
    // updatePageNum(true)
  }

  const onPrevPage = () => {
    setCurrentPage(max([currentPage - 1, 1]) as number)
    // updatePageNum(true)
  }

  const updatePageNum = (delay = false) => {
    const setData = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const pageHeight = Math.floor(_this.page?.state.page?.height ?? 0)
      const num = Math.floor((_this.scrollY + offsetHeight) / pageHeight + 1)
      setCurrentPage(num > totalPage ? totalPage : num)
    }
    if (delay) {
      setTimeout(() => {
        setData()
      }, 1000)
    } else {
      setData()
    }
  }

  const onLoadSuccess = ({ numPages }: any) => setTotalPage(numPages)

  const onPageRender = () => {
    if (pageNumber < totalPage) {
      setPageNumber(pageNumber + 1)
    }
  }

  const handleConfirm = () => {
    if (!onConfirm) onClose()
    if (isSign) {
      Toast.show('协议已签署')
      return Promise.resolve(true)
    }
    if (signAgree) {
      onConfirm?.()?.then(() => {
        setConfirmText('已签署')
        setIsSign(true)
      })
    } else {
      onClose()
    }
  }

  const onClose = () => {
    onCancel && onCancel()
  }

  const onScroll = ({
    nativeEvent: { contentOffset }
  }: NativeSyntheticEvent<NativeScrollEvent>) => {
    _this.scrollY = contentOffset.y
    updatePageNum()
  }

  const renderContent = () => {
    if (type === 'html') {
      return (
        <ScrollView style={[tailwind('flex-1'), containStyle]}>
          <div
            className='px-4 text-left'
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: contentData ?? '' }}
          />
        </ScrollView>
      )
    }
    return (
      <Document
        file={contentData}
        loading={
          <View
            style={{
              height: height * 0.6,
              justifyContent: 'center',
              alignItems: 'center',
              width: pdfWidth - 32
            }}
          >
            <ActivityIndicator size='large' />
            <Text style={{ marginTop: 24 }}>内容加载中…</Text>
          </View>
        }
        renderMode='canvas'
        onLoadSuccess={onLoadSuccess}
      >
        <Page
          // ref={index === 0 ? (ref) => (_this.page = ref) : undefined}
          width={pdfWidth - 32}
          scale={scale}
          pageNumber={currentPage}
          loading={
            <View
              style={{
                height: pdfHeight,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Text>文件加载中…</Text>
            </View>
          }
          // onRenderSuccess={debounce(onPageRender, 100)}
        />
      </Document>
    )
  }

  return (
    <Modal
      title={title}
      visible={show}
      closable
      fullscreen
      confirmText={confirmText}
      cancelText={null}
      onConfirm={handleConfirm}
      onCancel={onClose}
      contentType='normal'
    >
      <View
        style={tailwind('flex-1 overflow-scroll')}
        onLayout={({
          nativeEvent: {
            layout: { height: layoutHeight }
          }
        }) => {
          setOffsetHeight(layoutHeight / 2)
        }}
      >
        {renderContent()}
        {type === 'pdf' && (
          <View style={[tailwind('fixed bottom-0'), { left: 20, bottom: 70 }]}>
            <Text
              style={tailwind(
                'rounded bg-fill-2 px-3 py-1 border border-line-3 z-20'
              )}
              color='text-5'
            >
              {currentPage} / {totalPage}
            </Text>
          </View>
        )}
        <View
          style={[
            tailwind('flex-row justify-center fixed z-20 items-center w-full'),
            { bottom: 70 }
          ]}
        >
          <TouchableOpacity
            style={tailwind(
              'bg-fill-2 border border-line-3 rounded-lg p-2 mr-2'
            )}
            onPress={onPrevPage}
          >
            <AntDesign name='left' size={20} />
          </TouchableOpacity>
          <TouchableOpacity
            style={tailwind('bg-fill-2 border border-line-3 p-2 rounded')}
            onPress={onNextPage}
          >
            <AntDesign name='right' size={20} />
          </TouchableOpacity>
        </View>
        <View style={[tailwind('fixed z-20'), { bottom: 70, right: 20 }]}>
          <TouchableOpacity
            style={tailwind(
              'bg-fill-2 border border-line-3 rounded-lg p-2 mb-2'
            )}
            onPress={onPageZoomIn}
          >
            <AntDesign name='plus' size={20} />
          </TouchableOpacity>
          <TouchableOpacity
            style={tailwind('bg-fill-2 border border-line-3 p-2 rounded')}
            onPress={onPageZoomOut}
          >
            <AntDesign name='minus' size={20} />
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  )
}
