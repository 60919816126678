/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import {
  StyleProp,
  View,
  ViewStyle,
  Text,
  TouchableOpacity
} from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { DatePicker as BaseDatePicker } from '@bigtree/picker'
import { Popup } from '../Popup'
import { Button } from '../Button'

interface DatePickerProps {
  value?: string | undefined
  onChange?: (value: string | undefined) => void
  containStyle?: StyleProp<ViewStyle>
  placeholder?: string
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { value, onChange, containStyle, placeholder = '请选择' } = props
  const tailwind = useTailwind()
  const [date, setDate] = useState<string | undefined>()
  const [open, setOpen] = useState(false)
  const dateValue = useRef<string | undefined>(
    new Date().toISOString().split('T')[0]
  )

  useEffect(() => {
    if (!value) {
      setDate(value)
    }
    onChange?.(value)
  }, [value])

  const toggle = () => {
    setOpen(!open)
  }

  const handleSelect = (_date: string) => {
    dateValue.current = _date
  }

  const handleSubmit = () => {
    setDate(dateValue.current || date)
    onChange?.(dateValue.current || date)
    toggle()
  }

  return (
    <>
      <TouchableOpacity onPress={toggle}>
        <View
          style={[
            tailwind('rounded border border-slate-400 p-2 w-28 h-9'),
            containStyle
          ]}
        >
          {date ? (
            <Text>{date?.toString()}</Text>
          ) : (
            <Text style={tailwind('text-slate-400')}>{placeholder}</Text>
          )}
        </View>
      </TouchableOpacity>
      <Popup visible={open} onBackdropPress={toggle}>
        <BaseDatePicker
          format='yyyy-MM-dd'
          onSelected={handleSelect}
          value={date}
        />
        <Button type='primary' onPress={handleSubmit}>
          确认
        </Button>
        <Button onPress={toggle}>取消</Button>
      </Popup>
    </>
  )
}
