import { ReactNode } from 'react'
import {
  View,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  ActivityIndicator
} from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { useTailwind } from 'tailwind-rn'
import { isString, isFunction } from 'lodash'
import { Text } from '@/components/Text'

export interface MenuItemProps {
  /**
   * @description 左侧标题文案
   */
  title?: string | (() => ReactNode) | ReactNode
  /**
   * @description 右侧标题文案
   */
  subTitle?: string | (() => ReactNode) | ReactNode
  /**
   * @description 右侧箭头显示
   * @default false
   */
  showArrow?: boolean
  /**
   * @description 禁止点击
   * @default false
   */
  disabled?: boolean
  /**
   * @description 右侧标题按压事件
   */
  onSubTitlePress?: () => void
  /**
   * @description 右侧标题按压事件
   */
  onPress?: () => void
  /**
   * @description 样式
   */
  containStyle?: StyleProp<ViewStyle>
  /**
   * @description 样式
   */
  titleStyle?: StyleProp<ViewStyle>
  titleTextStyle?: StyleProp<ViewStyle>
  /**
   * @description 样式
   */
  subTitleStyle?: StyleProp<ViewStyle>
  /**
   * @description 样式
   */
  subTitleContainStyle?: StyleProp<ViewStyle>
  /**
   * @description 加载中
   */
  loading?: boolean
}

export const MenuItem = (props: MenuItemProps) => {
  const {
    title,
    subTitle,
    showArrow,
    onSubTitlePress,
    onPress,
    containStyle,
    titleStyle,
    titleTextStyle,
    subTitleStyle,
    subTitleContainStyle,
    disabled = false,
    loading = false
  } = props
  const tailwind = useTailwind()
  const renderLeft = () => {
    if (!title) {
      return null
    }
    return isString(title) ? (
      <View
        style={[{ flex: 3 }, tailwind('flex-row items-center'), titleStyle]}
      >
        <Text style={titleTextStyle}>{title}</Text>
      </View>
    ) : isFunction(title) ? (
      title()
    ) : (
      title
    )
  }

  const renderRight = () => {
    if (!subTitle && !showArrow && !loading) {
      return null
    }
    const arrow = (
      <AntDesign
        name='right'
        size={12}
        color='gray'
        style={showArrow ? tailwind('ml-1') : tailwind('ml-1 opacity-0')}
      />
    )
    const sub = isString(subTitle) ? (
      <Text style={subTitleStyle}>{subTitle}</Text>
    ) : isFunction(subTitle) ? (
      subTitle()
    ) : (
      subTitle
    )
    return onSubTitlePress ? (
      <TouchableOpacity
        style={[
          { flex: 1 },
          tailwind('flex-row justify-end items-center'),
          subTitleContainStyle
        ]}
        onPress={onSubTitlePress}
        disabled={!onSubTitlePress || disabled || !loading}
      >
        {loading ? <ActivityIndicator /> : sub}
        {arrow}
      </TouchableOpacity>
    ) : (
      <View
        style={[
          { flex: 3 },
          tailwind('flex-row justify-end items-center'),
          subTitleContainStyle
        ]}
      >
        {loading ? <ActivityIndicator /> : sub}
        {arrow}
      </View>
    )
  }

  return (
    <TouchableOpacity
      style={[
        tailwind('flex-row items-center justify-between h-fit p-2 bg-white'),
        { minHeight: 56 },
        containStyle
      ]}
      onPress={onPress}
      disabled={!onPress || disabled}
    >
      {renderLeft()}
      {renderRight()}
    </TouchableOpacity>
  )
}
