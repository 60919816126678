/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import {
  Animated,
  Easing,
  GestureResponderEvent,
  StyleProp,
  ViewStyle,
  TouchableOpacity,
  View
} from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { AntDesign } from '@expo/vector-icons'
import { Text } from '@/components'
import { Overlay } from '../Overlay'

export interface PopupProps {
  /**
   * 是否展示
   * @default false
   */
  visible?: boolean
  /**
   * 包裹内容样式
   */
  contentStyle?: StyleProp<ViewStyle>
  /**
   * 阴影层样式
   */
  backdropStyle?: StyleProp<ViewStyle>
  /**
   * 点击阴影层后回调
   * @param event
   */
  onBackdropPress?: (event: GestureResponderEvent) => void
  /**
   * 关闭icon
   */
  closable?: boolean
  /**
   * 头部标题
   */
  title?: string
}

export const Popup: React.FC<PopupProps> = ({
  visible,
  contentStyle,
  backdropStyle,
  onBackdropPress,
  children,
  closable = false,
  title
}) => {
  const tailwind = useTailwind()
  const animValue = useRef(new Animated.Value(0)).current

  useEffect(() => {
    if (visible) {
      Animated.timing(animValue, {
        toValue: 1,
        duration: 300,
        easing: Easing.bezier(0, 0, 0.58, 1),
        useNativeDriver: false
      }).start()
    } else {
      Animated.timing(animValue, {
        toValue: 0,
        duration: 200,
        easing: Easing.bezier(0.42, 0, 1, 1),
        useNativeDriver: false
      }).start()
    }
  }, [visible])

  const translateY = animValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['100%', '0%']
  })

  const renderHeader = () => {
    if (!title && !closable) return null
    const close = closable ? (
      <TouchableOpacity
        style={tailwind('absolute right-0')}
        onPress={onBackdropPress}
      >
        <AntDesign name='close' size={18} />
      </TouchableOpacity>
    ) : null
    const head = title ? (
      <Text align='center' weight='bold'>
        {title}
      </Text>
    ) : null
    return (
      <View style={[{ minHeight: 20 }, tailwind('relative py-1')]}>
        {head}
        {close}
      </View>
    )
  }
  return (
    <Overlay
      visible={visible}
      onBackdropPress={onBackdropPress}
      backdropStyle={backdropStyle}
      contentStyle={[tailwind('absolute bottom-0 w-full'), contentStyle]}
    >
      <Animated.View
        style={[
          tailwind('bg-white w-full'),
          { transform: [{ translateY }] },
          contentStyle
        ]}
      >
        {renderHeader()}
        {children}
      </Animated.View>
    </Overlay>
  )
}
