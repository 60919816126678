import React, { useMemo } from 'react'
import ReactECharts from 'echarts-for-react'
import { ScrollView, useWindowDimensions } from 'react-native'

export interface IReportCurveChartProps {
  data: any
  title?: string
}

export default function ReportCurveChart(props: IReportCurveChartProps) {
  const { data = {}, title } = props

  const { width: windowWidth } = useWindowDimensions()

  const computedWidth = useMemo(() => {
    const len = data.categories?.length || 0
    if (len > 0 && len <= 5) {
      return windowWidth - 50
    }

    if (len > 5 && len <= 10) {
      return 500
    }

    if (len > 5 && len <= 10) {
      return 750
    }

    return 1000
  }, [data])

  const option: any = useMemo(() => {
    return {
      grid: { top: 40, right: 8, bottom: 60, left: 0, containLabel: true },
      xAxis: {
        type: 'category',
        data: data.categories,
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        }
      },
      yAxis: {
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        }
      },
      series: data.series?.map((item: any) => ({
        data: item.data.map((dataItem: any) => ({
          ...dataItem,
          label: { show: true, rotate: 10 }
        })),
        type: 'line',
        smooth: true,
        name: item.name
      })),
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      legend: {
        type: 'plain',
        bottom: 10
      },
      title: {
        text: title,
        textStyle: {
          color: 'rgba(0, 0, 0, 0.4)',
          fontSize: 12
        },
        left: 10
      },
      color: [
        '#1890FF',
        '#91CB74',
        '#FAC858',
        '#EE6666',
        '#73C0DE',
        '#3CA272',
        '#FC8452',
        '#9A60B4',
        '#ea7ccc'
      ]
    }
  }, [data])

  return (
    <ScrollView horizontal style={{ flex: 1 }}>
      <ReactECharts option={option} style={{ width: computedWidth }} />
    </ScrollView>
  )
}
