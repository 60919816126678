import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { Layout, BaseGrid, Text, Button } from '@/components'
import { TRADEHISTORYSHOW } from '@/constants'
import env from '@/envs'
import appApi from '@/services/request/app.api'

const tableHead = [
  {
    sort: 0,
    sortColumn: 0,
    paramValue: '时间段',
    paramName: '0'
  },
  {
    sort: 0,
    sortColumn: 1,
    paramValue: '交易次数',
    paramName: '1'
  },
  {
    sort: 0,
    sortColumn: 2,
    paramValue: '交易总金额（元）',
    paramName: '2'
  }
]

export default function HistoryTradeDetail(props: any) {
  const { navigation, route } = props
  const { buyCode, sellCode, bugName, sellName, page } = route?.params || {}
  const tailwind = useTailwind()
  const [loading, setLoading] = useState<boolean>(false)
  const [table, setTable] = useState<any>([])
  const mode = env.mode
  const info = TRADEHISTORYSHOW[mode]
  const isDemon = page === 'demon'

  useEffect(() => {
    setLoading(true)
    if (isDemon) {
      appApi.getDemonHistoryTradeDetail().then((res) => {
        setTable([
          tableHead,
          [
            {
              sort: 1,
              sortColumn: 0,
              paramValue: '近12个月',
              paramName: '0'
            },
            {
              sort: 1,
              sortColumn: 1,
              paramValue: res?.transCnt12m,
              paramName: '1'
            },
            {
              sort: 1,
              sortColumn: 2,
              paramValue: res?.transAmt12m,
              paramName: '2'
            }
          ],
          [
            {
              sort: 2,
              sortColumn: 0,
              paramValue: '历史期间',
              paramName: '0'
            },
            {
              sort: 2,
              sortColumn: 1,
              paramValue: res?.transCntAll,
              paramName: '1'
            },
            {
              sort: 2,
              sortColumn: 2,
              paramValue: res?.transAmtAll,
              paramName: '2'
            }
          ]
        ])
        setLoading(false)
      })
    } else if (buyCode && sellCode) {
      appApi
        .getHistoryTradeDetail({
          buyerOrgCode: buyCode,
          sellerOrgCode: sellCode
        })
        .then((res) => {
          setTable([
            tableHead,
            [
              {
                sort: 1,
                sortColumn: 0,
                paramValue: '近12个月',
                paramName: '0'
              },
              {
                sort: 1,
                sortColumn: 1,
                paramValue: res?.transCnt12m,
                paramName: '1'
              },
              {
                sort: 1,
                sortColumn: 2,
                paramValue: res?.transAmt12m,
                paramName: '2'
              }
            ],
            [
              {
                sort: 2,
                sortColumn: 0,
                paramValue: '历史',
                paramName: '0'
              },
              {
                sort: 2,
                sortColumn: 1,
                paramValue: res?.transCntAll,
                paramName: '1'
              },
              {
                sort: 2,
                sortColumn: 2,
                paramValue: res?.transAmtAll,
                paramName: '2'
              }
            ]
          ])
          setLoading(false)
        })
    }
  }, [])

  const onSubmit = () => {
    navigation.replace('HistoryTradeQuery')
  }

  return (
    <Layout hasFooterText loading={loading} loadText='明细生成中...'>
      <View style={tailwind('mb-2')}>
        <Text style={tailwind('mb-2')}>买方企业名称</Text>
        <View style={tailwind('bg-white p-2 rounded')}>
          <Text>{isDemon ? info.buyCompanyShow : bugName}</Text>
        </View>
      </View>
      <View>
        <Text style={tailwind('mb-2')}>卖方企业名称</Text>
        <View style={tailwind('bg-white p-2 rounded')}>
          <Text>{isDemon ? info.buyCompanyShow : sellName}</Text>
        </View>
      </View>
      <BaseGrid gridData={table} containStyle={tailwind('mt-4 mb-4')} />
      <Button type='primary' onPress={onSubmit}>
        返回上一步
      </Button>
    </Layout>
  )
}
