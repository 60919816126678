/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  GestureResponderEvent,
  Pressable,
  Animated,
  Easing,
  StyleProp,
  ViewStyle
} from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import { useTailwind } from 'tailwind-rn'

export interface OverlayProps {
  /**
   * 阴影背景透明
   * @default false
   */
  transparent?: boolean
  /**
   * 是否展示
   * @default false
   */
  visible?: boolean
  /**
   * 关闭后回调
   */
  afterClose?: () => void
  /**
   * 包裹内容样式
   */
  contentStyle?: StyleProp<ViewStyle>
  /**
   * 阴影层样式
   */
  backdropStyle?: StyleProp<ViewStyle>
  /**
   * 点击阴影层后回调
   * @param event
   */
  onBackdropPress?: (event: GestureResponderEvent) => void
}

export const Overlay: React.FC<OverlayProps> = ({
  transparent,
  visible = false,
  afterClose,
  contentStyle,
  backdropStyle,
  onBackdropPress,
  children
}) => {
  const tailwind = useTailwind()
  const [show, setShow] = useState(false)
  const animValue = useRef(new Animated.Value(0)).current
  useEffect(() => {
    if (visible) {
      setShow(true)
      Animated.timing(animValue, {
        toValue: 1,
        duration: 300,
        easing: Easing.bezier(0, 0, 0.58, 1),
        useNativeDriver: false
      }).start()
    } else {
      Animated.timing(animValue, {
        toValue: 0,
        duration: 200,
        easing: Easing.bezier(0.42, 0, 1, 1),
        useNativeDriver: false
      }).start(() => {
        afterClose?.()
        setShow(false)
      })
    }
  }, [visible])
  return show ? (
    <Animated.View
      style={[
        tailwind('w-full h-full fixed z-20 inset-x-0 bottom-0'),
        !transparent ? tailwind('bg-gray-500 bg-opacity-40') : null,
        { opacity: animValue }
      ]}
    >
      <Pressable
        style={[
          tailwind('w-full h-full justify-center items-center'),
          backdropStyle
        ]}
        onPress={onBackdropPress}
      >
        <Pressable
          style={[
            tailwind('overflow-hidden max-w-full max-h-full'),
            contentStyle
          ]}
        >
          {children}
        </Pressable>
      </Pressable>
    </Animated.View>
  ) : null
}
