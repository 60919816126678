import React, { useEffect, useState, useCallback } from 'react'
import { DotLoading, Toast } from 'antd-mobile'
import { StyleSheet, View } from 'react-native'
import appApi from '@/services/request/app.api'
import { FileView } from '@/components'

export interface IAuthProps {
  route: any
}

export default function Auth(props: IAuthProps) {
  const { route } = props
  const [showFile, setShowFile] = useState<boolean>(false)
  const [fileUrl, setFileUrl] = useState<string>()
  const [wfqUrl, setWfqUrl] = useState<string>()

  useEffect(() => {
    appApi.getAuthUrlUsingGET(route?.params).then((res) => {
      const { authStatus, callBackUrl, protocolFileUrl, wfqAuthUrl } = res
      if (protocolFileUrl) {
        setShowFile(true)
        setFileUrl(protocolFileUrl)
        setWfqUrl(wfqAuthUrl)
      } else if (authStatus === '1') {
        Toast.show({
          duration: 3000,
          content: '该笔订单已授权',
          afterClose() {
            location.href = callBackUrl
          }
        })
      } else {
        location.href = wfqAuthUrl
      }
    })
  }, [])

  const onFileConfirm = useCallback(() => {
    setShowFile(false)
    Toast.show({
      duration: 1000,
      content: '您已签署成功授权协议',
      afterClose() {
        location.href = wfqUrl as string
      }
    })
  }, [wfqUrl])

  return (
    <View style={styles.loadingWrap}>
      <DotLoading
        color='primary'
        style={{
          fontSize: 18,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1
        }}
      />
      <FileView
        type='pdf'
        title='授权协议'
        show={showFile}
        contentData={fileUrl}
        onConfirm={onFileConfirm}
        signAgree
      />
    </View>
  )
}
const styles = StyleSheet.create({
  loadingWrap: {
    backgroundColor: '#fff',
    zIndex: 999,
    ...StyleSheet.absoluteFillObject
  }
})
