import { Card, Layout } from '@/components'
import { View } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { useEffect, useState } from 'react'
import appApi from '@/services/request/app.api'

export default function MyEnterpriseView() {
  const tailwind = useTailwind()
  const [data, setData] = useState<any>({})

  useEffect(() => {
    appApi.orginfoApplyDetailGET().then((res) => {
      setData(res)
    })
  }, [])

  return (
    <Layout>
      <Card>
        <View style={tailwind('text-lg font-bold')}>
          {data?.inquireOrgName}
        </View>
        <View style={tailwind('mt-2')}>{`社会信用代码：${
          data?.inquireOrgNo || ''
        }`}</View>
      </Card>
    </Layout>
  )
}
