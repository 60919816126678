/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { Storage } from '@/utils/storage'
import { STORAGE_KEYS } from '@/constants'

export class PubSub<T> {
  private _subscriber: (type: T, payload?: any) => void = () => {}

  subscribe(subscriber: (type: T, payload?: any) => void) {
    this._subscriber = subscriber
  }

  publish(type: T, payload?: any) {
    this._subscriber(type, payload)
  }
}

type DataValue = {
  userInfo?: any
  accessToken?: string | null
  openid?: string
}

type GlobalContentType = DataValue & {
  setGlobalData(values: DataValue): Promise<void>
  clearAll(): void
}

const GlobalDataContent = createContext<GlobalContentType>({
  setGlobalData: () => Promise.resolve(),
  clearAll: () => {}
})

const pubsub = new PubSub<
  keyof Pick<GlobalContentType, 'setGlobalData' | 'clearAll'>
>()

/**
 * 全局变量hooks
 */
export const useGlobalData = () => useContext(GlobalDataContent)

/**
 * 静态设置
 */
export const store = {
  clearAll() {
    pubsub.publish('clearAll')
  },
  setGlobalData(values: DataValue) {
    pubsub.publish('setGlobalData', values)
  }
}

/**
 * 全局变量上下文
 * @param children
 * @constructor
 */
export const GlobalDataProvider: React.FC<React.PropsWithChildren<any>> = ({
  children
}) => {
  const { accessToken, userInfo } = global
  const [dataValue, setDataValue] = useState<DataValue>({
    accessToken,
    userInfo
  })
  const ref = useRef<(values: DataValue) => void>()
  const setGlobalData = useCallback(
    async (values: DataValue) => {
      // 保存token 算登录
      if (values.accessToken) {
        await Storage.setItem(STORAGE_KEYS.accessToken, values.accessToken)
      }
      if (values.openid) {
        global.openid = values.openid
        await Storage.setOpenid(values.openid)
      }
      if (values.userInfo) {
        global.userInfo = values.userInfo
      }
      setDataValue({ ...dataValue, ...values })
    },
    [setDataValue, dataValue]
  )
  ref.current = setGlobalData

  const clearAll = useCallback(() => {
    setDataValue({})
  }, [])

  useEffect(() => {
    pubsub.subscribe((type, payload) => {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'clearAll': {
          clearAll()
          return
        }
        case 'setGlobalData': {
          ref.current?.(payload)
        }
      }
    })
  }, [])

  return (
    <GlobalDataContent.Provider
      value={{ ...dataValue, setGlobalData, clearAll }}
    >
      {children}
    </GlobalDataContent.Provider>
  )
}
