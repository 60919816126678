/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { Input, InputProps, Button } from '@/components'

export interface InputSMSProps extends Omit<InputProps, 'onChange'> {
  value?: string
  /**
   * @description 禁用发送按钮
   * @default false
   */
  disabled?: boolean
  /**
   * @description change事件
   */
  onChange?: (value: string) => void
  /**
   * @description 倒计时描述
   * @default 60
   */
  seconds?: number
  /**
   * 禁用
   * @description 按钮文案
   * @default 获取验证码
   */
  btnText: string
  /**
   * 禁用
   * @description 按钮类型
   * @default primary
   */
  btnType: 'primary' | 'secondary' | 'default'
  /**
   * @description 点击发送验证码
   */
  onSend?: () => Promise<unknown>
  /**
   * @description container样式
   */
  containStyle?: StyleProp<ViewStyle>
  /**
   * @description 输入框样式
   */
  InputStyle?: StyleProp<ViewStyle>
  /**
   * @description 按钮样式
   */
  BtnStyle?: StyleProp<ViewStyle>
}

export const InputSMS: React.FC<InputSMSProps> = ({
  onChange,
  onSend,
  disabled = false,
  seconds = 60,
  btnType = 'primary',
  containStyle,
  InputStyle,
  BtnStyle,
  btnText = '获取验证码',
  ...props
}) => {
  const tailwind = useTailwind()
  const timer = useRef<any>()
  const [countDown, setCountDown] = useState<number>(0)
  const [disabledValue, setDisabledValue] = useState<boolean>()

  const mergedDisabled = disabled || disabledValue

  useEffect(() => {
    return () => {
      clearInterval(timer.current)
    }
  }, [])
  const handleSend = () => {
    onSend?.().then(
      () => {
        setCountDown(seconds)
        setDisabledValue(true)
        timer.current = setInterval(() => {
          setCountDown((value) => {
            let val = value - 1
            if (val <= 0) {
              val = 0
              setDisabledValue(false)
              clearInterval(timer.current)
            }
            return val
          })
        }, 1000)
      },
      () => {
        clearInterval(timer.current)
        setDisabledValue(false)
      }
    )
  }
  return (
    <View style={[tailwind('flex-row'), containStyle]}>
      <Input
        {...props}
        onChange={onChange}
        editable={!disabled}
        containStyle={[tailwind('flex-1 mr-2 rounded-md'), InputStyle]}
      />
      <Button
        onPress={handleSend}
        disabled={mergedDisabled}
        style={BtnStyle}
        type={btnType}
      >
        {countDown > 0 ? `${countDown}s` : btnText}
      </Button>
    </View>
  )
}
