import React from 'react'
import { TailwindProvider } from 'tailwind-rn'
import type { Utilities } from 'tailwind-rn'
import { GlobalContext } from './context'
import { useListenOverlay } from '../Overlay/useListenOverlay'
import { Overlay } from '../Overlay'

interface ProviderProps {
  config?: any
  utilities?: Utilities
}

export const Provider: React.FC<ProviderProps> = ({
  utilities = {},
  config,
  children
}) => {
  const overlays = useListenOverlay()
  return (
    <GlobalContext.Provider value={config}>
      <TailwindProvider utilities={utilities}>
        {children}
        {overlays.map(({ overlayId, content, ...props }) => (
          <Overlay {...props} key={overlayId}>
            {content}
          </Overlay>
        ))}
      </TailwindProvider>
    </GlobalContext.Provider>
  )
}
