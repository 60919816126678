import { Image } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { Layout, Text } from '@/components'

const contacts = [
  {
    label: '联系电话',
    value: '029-85328357'
  },
  {
    label: '官网地址',
    value: 'https://www.13315.org'
  },
  {
    label: '微信公众号',
    value: '融信征信'
  }
]

export default function AboutWe() {
  const tailwind = useTailwind()

  return (
    <Layout style={tailwind('items-center justify-center')}>
      <Image
        source={{ uri: require('@img/rx-logo.png'), width: 120, height: 120 }}
      />
      <Text style={tailwind('my-4')} weight='semibold' size='base'>
        陕西融信信用管理有限公司
      </Text>
      {contacts.map(({ label, value }) => (
        <Text key={label} style={tailwind('mb-1')}>
          {label}：{value}
        </Text>
      ))}
    </Layout>
  )
}
