/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
  ReactNode
} from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import {
  FormConfigs as BaseFormConfigs,
  FormConfigProvider,
  Form as BaseForm,
  FormHandles,
  FormProps,
  RepoFormContainer,
  RepoArrayContainer,
  RepoGroupContainer,
  RepoFieldContainer
} from '@bigtree/form'
import { Text, Button } from '@/components'
import { registry, FormTypes } from '@/components/Form/registry'
import { FormRules, rules } from '@/components/Form/rules'
import { uniqueId, isArray } from 'lodash'

interface formContainerProps {
  formContainStyle: StyleProp<ViewStyle>
}

interface formContentProps {
  children?: ReactNode
}

// 自定义form容器
export const formContainer: (
  props: formContainerProps
) => RepoFormContainer = ({ formContainStyle }) =>
  function ({ children }: formContentProps) {
    const tailwind = useTailwind()
    const config = isArray(children) && children.length > 0 ? children[0] : null
    const fileds =
      config.length > 0
        ? config.filter((item: any) => !item?.props?.props?.end)
        : []
    const button = isArray(children) && children.length > 1 ? children[1] : null
    const ends =
      isArray(config) && config.filter((item) => item?.props?.props?.end)
    return (
      <View
        style={[tailwind('flex-1 justify-between bg-fill-2'), formContainStyle]}
      >
        <View>{fileds}</View>
        <View>
          {ends}
          {button}
        </View>
      </View>
    )
  }

// 自定义array容器
export const arrayContainer: RepoArrayContainer = ({ children }) => (
  <View>{children}</View>
)

// 自定义group容器
export const groupContainer: RepoGroupContainer = ({ children }) => (
  <View>{children}</View>
)

interface fieldContainProps {
  layout: 'horizontal' | 'vertical'
  labelStyle?: StyleProp<ViewStyle>
  fieldStyle?: StyleProp<ViewStyle>
  fieldContainStyle?: StyleProp<ViewStyle>
}

// 自定义单个表单域field容器
export const fieldContainer: (
  props: fieldContainProps
) => RepoFieldContainer = ({
  labelStyle,
  fieldStyle,
  layout,
  fieldContainStyle
}) => {
  return function ({ dirty, errors, label, valid, required, children }) {
    const errItem = errors?.find((err) => err.status === 'error')
    const tailwind = useTailwind()
    if (layout === 'horizontal') {
      return (
        <View style={[tailwind('relative'), fieldContainStyle]}>
          <View
            style={[
              tailwind(
                'bg-white rounded-md flex-row items-center justify-start relative mb-4'
              ),
              fieldStyle
            ]}
          >
            {required && (
              <View style={tailwind('absolute left-2')}>
                <Text style={{ color: 'red' }}>*</Text>
              </View>
            )}
            {label && (
              <View
                style={[
                  { paddingRight: 4, paddingLeft: 18, minWidth: 60 },
                  labelStyle
                ]}
              >
                <Text>{label}: </Text>
              </View>
            )}
            <View style={{ flex: 1 }}>{children}</View>
          </View>
          {dirty && !valid && errItem && (
            <View
              style={[
                tailwind(
                  'items-center flex-row justify-center absolute right-0'
                ),
                { bottom: 3 }
              ]}
            >
              {/* {errors
                ?.filter((err) => err.status === 'error')
                .map((err) => ( */}
              <Text
                style={{ marginRight: 4, color: 'red', lineHeight: 12 }}
                key={uniqueId()}
                size='xs'
              >
                {errItem.message}
              </Text>
              {/* ))} */}
            </View>
          )}
        </View>
      )
    }
    return (
      <View
        style={[
          tailwind('bg-white rounded-md mb-2 px-4 py-2'),
          fieldContainStyle
        ]}
      >
        <View
          style={[
            tailwind('flex-row items-center justify-between'),
            fieldStyle
          ]}
        >
          <View style={tailwind('relative')}>
            {required && (
              <Text style={tailwind('absolute left-0 text-danger')}>*</Text>
            )}
            {label && (
              <Text style={[tailwind('ml-2'), labelStyle]}>{label}</Text>
            )}
          </View>
          {dirty && !valid && errItem && (
            <View style={tailwind('flex-1 flex-row  justify-end items-center')}>
              {/* {errors
                ?.filter((err) => err.status === 'error')
                .map((err) => ( */}
              <Text style={tailwind('text-danger')} key={uniqueId()} size='xs'>
                {errItem.message}
              </Text>
              {/* ))} */}
            </View>
          )}
        </View>
        <View>{children}</View>
      </View>
    )
  }
}

export type FormConfigs = BaseFormConfigs<FormTypes, FormRules>

export const Form = forwardRef<
  FormHandles,
  FormProps<FormTypes, FormRules> & {
    labelStyle?: StyleProp<ViewStyle>
    fieldStyle?: StyleProp<ViewStyle>
    fieldContainStyle?: StyleProp<ViewStyle>
    formContainStyle?: StyleProp<ViewStyle>
    subtext?: string
    layout?: 'horizontal' | 'vertical'
  }
>(
  (
    {
      labelStyle,
      fieldStyle,
      fieldContainStyle,
      formContainStyle,
      children,
      onSubmit,
      subtext,
      layout = 'horizontal',
      ...props
    },
    ref
  ) => {
    const form = useRef<FormHandles>(null)
    const [loading, setLoading] = useState(false)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useImperativeHandle(ref, () => form.current, [form.current])

    const handleSubmit = async () => {
      form.current!.setPathDirty(true)
      if (form.current!.getPathValid() && onSubmit) {
        const result = onSubmit(form.current!.getPathValue())
        if (result instanceof Promise) {
          setLoading(true)
          await result.finally(() => {
            setLoading(false)
          })
        }
      }
    }

    const renderBtn = () => {
      if (!onSubmit) return null
      return (
        <Button
          style={{ marginTop: 8, marginBottom: 16 }}
          loading={loading}
          onPress={handleSubmit}
          type='primary'
        >
          {subtext ? subtext : '提交'}
        </Button>
      )
    }

    const fieldContain = useMemo(
      () =>
        fieldContainer({ labelStyle, fieldStyle, layout, fieldContainStyle }),
      []
    )

    const formContain = useMemo(() => formContainer({ formContainStyle }), [])

    return (
      <FormConfigProvider
        formContainer={formContain}
        groupContainer={groupContainer}
        arrayContainer={arrayContainer}
        fieldContainer={fieldContain}
        formItems={registry}
        formRules={rules}
      >
        <BaseForm<FormTypes, FormRules> ref={form} {...props}>
          <View>
            {children}
            {renderBtn()}
          </View>
        </BaseForm>
      </FormConfigProvider>
    )
  }
)
