import { Captcha } from '@/components/Captcha'
import appApi from '@/services/request/app.api'

const loadImg = () =>
  appApi.getImageVerificationInfoUsingGET().then((data) => ({
    ...data,
    background: data.shadeImage,
    slider: data.cutoutImage,
    y: data.y
  }))

const verify = (data: Record<string, any>) =>
  appApi
    .checkImageSlideResultUsingGET({
      x: data.x,
      y: data.y,
      sessionId: data.sessionId
    })
    .then((resp) => {
      return {
        ...resp,
        result: resp.result ?? false
      }
    })

export const showCaptcha = () =>
  Captcha.show({
    loadImg,
    verify
  })
