/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React from 'react'
import { uniqueId } from 'lodash'
import { OverlayProps } from './overlay'

export interface OverlayOptions extends OverlayProps {
  content: React.ReactNode
  overlayId: string
  group: string
}

type DispatchParams =
  | { type: 'destroy'; options: { overlayId?: string; group?: string } }
  | {
      type: 'update'
      options: OverlayOptions
    }
  | {
      type: 'create'
      options: Omit<OverlayOptions, 'overlayId'>
    }

export interface OverlayInstance {
  destroy: () => void
  render: (dom: React.ReactNode, options?: any) => void
}

export class OverlaySubject {
  _overlays: Record<string, OverlayOptions[]> = {}

  private _observer?: (comp: Record<string, OverlayOptions[]>) => void

  dispatch(params: DispatchParams): string | undefined {
    const {
      options: { group = 'bum_overlay' }
    } = params
    const overlaysMap = this._overlays
    const overlays = overlaysMap[group]
    let overlayId: string | undefined
    switch (params.type) {
      case 'create': {
        overlayId = `overlay_${group}_${uniqueId()}_${
          this._overlays[group]?.length ?? 0
        }`
        if (overlays == null) overlaysMap[group] = []
        const close = params.options.afterClose
        const index = overlaysMap[group].length
        params.options.afterClose = () => {
          this._overlays[group].splice(index, 1)
          this._observer?.(this._overlays)
          close?.()
        }
        overlaysMap[group] = overlaysMap[group].concat([
          { transparent: false, visible: true, ...params.options, overlayId }
        ])
        break
      }
      case 'update': {
        overlayId = params.options.overlayId
        const index = overlaysMap[group].findIndex(
          (overlay) => overlay.overlayId === overlayId
        )
        if (index === -1) return
        Object.assign(overlays[index], params.options)
        break
      }
      case 'destroy': {
        overlayId = params.options.overlayId
        if (overlayId != null) {
          const index = overlaysMap[group].findIndex(
            (overlay) => overlay.overlayId === overlayId
          )
          if (index === -1) return
          overlays[index].visible = false
        } else {
          Object.values(overlaysMap)
            .flat()
            .forEach((item) => {
              item.visible = false
            })
        }
        break
      }
    }
    this._observer?.(this._overlays)
    return overlayId
  }

  subscribe(listener: (comp: Record<string, OverlayOptions[]>) => void): void {
    this._observer = listener
  }
}

export class OverlayFactory {
  private readonly subject: OverlaySubject

  constructor(subject: OverlaySubject) {
    this.subject = subject
  }

  create(options: Omit<OverlayOptions, 'overlayId'>): OverlayInstance {
    const subject = this.subject

    const overlayId = subject.dispatch({ type: 'create', options }) as string

    return {
      destroy: () => {
        if (!options.group) return
        subject.dispatch({
          type: 'destroy',
          options: { group: options.group, overlayId }
        })
        ;(options.group as any) = null
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render(dom: React.ReactNode, op?: any) {
        subject.dispatch({
          type: 'update',
          options: {
            ...options,
            overlayId,
            content: dom
          }
        })
      }
    }
  }

  /**
   * 销毁
   * @param group 不传销毁全部
   */
  destroyAll(group?: string): void {
    this.subject.dispatch({ type: 'destroy', options: { group } })
  }
}
