import Constants from 'expo-constants'
import developmment from './development'
import production from './production'

const env =
  Constants.manifest?.extra?.APP_ENV === 'production'
    ? production
    : developmment

export default env
