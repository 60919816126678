/* eslint-disable react-hooks/exhaustive-deps */
import { StyleSheet, View, ScrollView, Pressable } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import React, { useEffect, useRef } from 'react'
import { AntDesign } from '@expo/vector-icons'
import { Button } from '../Button'
import { Text } from '../Text'
import { OverlayInstance } from '../Overlay/service'
import { OverlayService } from '../Overlay'
import { ModalProps } from './index'

export interface ModalOptions extends Omit<ModalProps, 'visible'> {
  content?: React.ReactElement | React.ReactNode
}

export const MODAL_GROUP = 'bmu_modal'

function ModalBody({
  style,
  icon,
  title,
  content,
  contentType = 'scroll',
  onConfirm,
  onCancel,
  fullscreen,
  cancelText,
  confirmText,
  closable,
  extraActions,
  containStyle,
  footer
}: ModalOptions) {
  const tailwind = useTailwind()

  const renderContent = () => {
    if (!content) return null
    if (typeof content === 'string') {
      return (
        <Text align='center' size='sm' color='gray-500'>
          {content}
        </Text>
      )
    }
    if (contentType === 'scroll') {
      return <ScrollView style={tailwind('flex-1 mt-2')}>{content}</ScrollView>
    }
    return content
  }
  return (
    <View
      style={[
        tailwind(
          `${fullscreen ? 'h-screen w-screen' : 'p-4'} max-h-full relative`
        ),
        { minWidth: 280 },
        containStyle
      ]}
    >
      {Boolean(closable) && (
        <Pressable
          style={tailwind('absolute top-5 right-5 z-10')}
          onPress={onCancel}
        >
          <AntDesign name='close' size={18} />
        </Pressable>
      )}
      <View
        style={[
          styles.container,
          tailwind(
            `bg-white rounded-lg max-h-full ${
              fullscreen ? 'h-screen w-screen' : ''
            }`
          ),
          style
        ]}
      >
        <View style={tailwind('px-4 pt-5 flex-1')}>
          {Boolean(icon) && (
            <View style={tailwind('mb-2 justify-center items-center')}>
              {icon}
            </View>
          )}
          <View>
            {typeof title === 'string' ? (
              <Text
                size='base'
                weight='medium'
                color='gray-900'
                align='center'
                style={tailwind('mb-2')}
              >
                {title}
              </Text>
            ) : (
              title
            )}
          </View>
          {renderContent()}
        </View>
        {footer === undefined ? (
          <View style={tailwind('px-4 pt-4 pb-3 flex-row justify-between')}>
            {extraActions?.map((action) => (
              <Button
                key={action.name}
                style={tailwind('flex-1 mr-3')}
                onPress={action?.onPress}
              >
                {action.name}
              </Button>
            ))}
            {Boolean(cancelText) && (
              <Button style={tailwind('flex-1 mr-3')} onPress={onCancel}>
                {cancelText}
              </Button>
            )}
            {Boolean(confirmText) && (
              <Button
                type='primary'
                style={tailwind('flex-1')}
                onPress={onConfirm}
              >
                {confirmText}
              </Button>
            )}
          </View>
        ) : (
          footer
        )}
      </View>
    </View>
  )
}

export const createModalOverlay = ({
  overlayClosable = true,
  ...options
}: ModalOptions) => {
  // eslint-disable-next-line prefer-const
  let instance: any
  // eslint-disable-next-line prefer-object-spread
  const opts = Object.assign(
    {
      onCancel: () => instance?.destroy(),
      onConfirm: () => instance?.destroy(),
      confirmText: '确定',
      cancelText: '取消',
      closable: true
    },
    options
  )
  instance = OverlayService.create({
    content: <ModalBody {...opts} />,
    onBackdropPress: () => {
      options?.onCancel?.()
      if (overlayClosable) instance.destroy()
    },
    group: MODAL_GROUP
  })
  return {
    destroy: instance.destroy,
    render: (
      dom: React.ReactNode,
      newOptions?: Omit<ModalOptions, 'content'>
    ) => {
      instance.render(<ModalBody {...opts} {...newOptions} content={dom} />)
    }
  }
}

export const PModal: React.FC<ModalProps> = ({
  visible,
  children,
  ...options
}: React.PropsWithChildren<ModalProps>) => {
  const instance = useRef<OverlayInstance>()
  useEffect(() => {
    return () => {
      instance.current?.destroy()
    }
  }, [])

  useEffect(() => {
    if (visible && instance.current) {
      instance.current.render(children, options)
    }
  }, [children, options.cancelText, options.confirmText, options.title])

  useEffect(() => {
    if (visible) {
      instance.current = createModalOverlay({
        ...options,
        content: children
      })
    } else if (instance.current) {
      instance.current.destroy()
      instance.current = undefined
    }
  }, [visible])
  return null
}

const styles = StyleSheet.create({
  container: {
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 3,
    shadowColor: '#000'
  }
})
