/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unused-vars */
import { Platform } from 'react-native'
import Constants from 'expo-constants'
import { isNil, omitBy } from 'lodash'
import moment, { isMoment, Moment, unitOfTime } from 'moment'
import to from 'await-to-js'
import { Storage } from '@/utils/storage'
import environment from '@/envs'
import { store } from '@/hooks/useGlobalData'
import appApi from '@/services/request/app.api'

const { baseURL } = environment

export const addBaseUrl = (url: string) => `${baseURL}${url}`

export const isWeb = Platform.OS === 'web'

export const isDev = Constants.manifest?.extra?.APP_ENV === 'development'

export const isDebugg = Constants.manifest?.extra?.DEBUG === 'true'

export const isIosNative = Platform.OS === 'ios'

export const isIphone = navigator.userAgent.includes('iPhone')

export const isIpad = navigator.userAgent.includes('iPad')

export const isIos = isIosNative || isIphone || isIpad

export function isBase64Img(str: string): boolean {
  return /^data:image\/[a-zA-Z]+;base64,/.test(str)
}

/**
 *  判断是否为微信浏览器
 */
export function isWeiXinBrowser(): boolean {
  if (!isWeb) {
    return false
  }
  // @ts-ignore
  if (isWeiXinBrowser._result != null) {
    // @ts-ignore
    return isWeiXinBrowser._result
  }

  // @ts-ignore
  isWeiXinBrowser._result = /MicroMessenger/i.test(navigator.userAgent)
  // @ts-ignore
  return isWeiXinBrowser._result
}

export const isWeixin = isWeiXinBrowser()

export function convertBase64UrlToBlob(urlData: string) {
  // 去掉url的头，并转换为byte
  const bytes = window.atob(urlData.split(',')[1])
  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < bytes.length; i += 1) {
    ia[i] = bytes.charCodeAt(i)
  }
  return new Blob([ab], { type: 'image/png' })
}

//  获取base64图片大小，返回MB数字
export function getBase64ImgSize(base64url: string) {
  const str = base64url.replace(/^data:image\/[a-zA-Z]+;base64,/, '')
  const strLength = str.length
  const fileLength: any = strLength - (strLength / 8) * 2
  return parseInt(fileLength, 10)
}

export function canvasDataURL(path: string, obj: any, callback: any) {
  const img = new Image()
  img.src = path
  // eslint-disable-next-line func-names
  img.onload = function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that: any = this
    // 默认按比例压缩
    let w = that.width
    let h = that.height
    const scale = w / h
    w = obj.width || w
    h = obj.height || w / scale
    let quality = 0.7 // 默认图片质量为0.7
    // 生成canvas
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    // 创建属性节点
    const anw = document.createAttribute('width')
    anw.nodeValue = w
    const anh = document.createAttribute('height')
    anh.nodeValue = h
    canvas.setAttributeNode(anw)
    canvas.setAttributeNode(anh)

    ctx?.drawImage(that, 0, 0, w, h)
    // 图像质量
    if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
      quality = obj.quality
    }
    // quality值越小，所绘制出的图像越模糊
    const base64 = canvas.toDataURL('image/jpeg', quality)
    // 回调函数返回base64的值
    callback(base64)
  }
}

export function compressImage(src: string, quality: number) {
  return new Promise<any>((resolve) => {
    canvasDataURL(src, { quality }, resolve)
  })
}

export function isPromise(obj: any): obj is Promise<any> {
  return (
    !!obj && // 有实际含义的变量才执行方法，变量null，undefined和''空串都为false
    (typeof obj === 'object' || typeof obj === 'function') && // 初始promise 或 promise.then返回的
    typeof obj.then === 'function'
  )
}

export function isBase64(str: string) {
  if (str === '' || str.trim() === '') {
    return false
  }
  try {
    return btoa(atob(str)) === str
  } catch (err) {
    return false
  }
}

/** 过滤 null、undefined 和 空字符串 */
export function getValidParams(value: any) {
  return omitBy(value, (v) => v === '' || isNil(v))
}

//  取时间间隔
export const dateDiff = (
  a: Moment | string = moment(),
  b: Moment | string = moment(),
  formate: unitOfTime.Diff = 'days',
  isExact = true
) => {
  const dateA = isMoment(a) ? a : moment(a)
  const dateB = isMoment(b) ? b : moment(b)
  return dateA.diff(dateB, formate, isExact)
}

/**
 *  缓存函数
 *  @param fn 需要缓存结果的函数
 *  @param options.keyResolver 用于生成缓存 key 的函数, 函数返回值将作为缓存的 key. 默认取 fn 第一个入参作为 key.
 *  @param options.sizeLimit 缓存大小
 */
export function cached(
  fn: (params?: any) => void,
  options?: { keyResolver?: (params?: any) => void; sizeLimit?: number }
) {
  const { keyResolver = (v: any) => v, sizeLimit = 100 } = options || {}
  const wrapper: any = function (...args: any[]) {
    const key = keyResolver(...args)
    wrapper._cache = wrapper._cache || new Map()
    const cache = wrapper._cache

    // when cache full
    if (cache.size >= sizeLimit) {
      return cache.has(key) ? cache.get(key) : fn(...args)
    }

    if (!cache.has(key)) {
      cache.set(key, fn(...args))
    }

    return cache.get(key)
  }

  return wrapper
}

export const hiddenMobile = (val: string) =>
  val?.replace(/^(\d{3})\d+(\d{4})$/, '$1****$2')

//  处理二进制流文件
export function resolveBinaryFile(data: any) {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader()
    reader.onload = (evt: any) => {
      if (evt.target.readyState === window.FileReader.DONE) {
        try {
          const result = JSON.parse(evt.target.result)
          reject(result.message)
        } catch (err) {
          const objectUrl = window.URL.createObjectURL(data.data)
          resolve(objectUrl)
        }
      }
    }
    reader.readAsText(data.data, 'utf-8')
  })
}

export async function logoutUser() {
  global.userInfo = undefined
  global.accessToken = undefined as any
  store.setGlobalData({ userInfo: undefined })
  await Storage.removeItem('accessToken')
  const openid = await Storage.getOpenid()
  if (isWeiXinBrowser() && !openid) {
    location.assign(location.origin)
  }
}

export async function initeUser() {
  store.setGlobalData({ userInfo: undefined })
  const [err, res] = await to(appApi.selfUsingGET())
  store.setGlobalData({ userInfo: err ? undefined : res })
}

/**
 * 金额格式化
 */
export function formatMoney(value: any) {
  if (value === undefined || value === null) {
    return '-'
  }
  return new Intl.NumberFormat(undefined, { minimumFractionDigits: 2 }).format(
    +value
  )
}

export const isInvaildValue = (value: any) => isNil(value) || value === ''

export const formatTableValue = (value: any) =>
  isInvaildValue(value) ? '-' : value

/**
 * @description url参数格式化
 */
export const formatUrlParams = (search: string, key: string) => {
  const params = new URLSearchParams(search)
  return params.get(key) || undefined
}
