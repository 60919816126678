/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef } from 'react'
import {
  Animated,
  Easing,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle
} from 'react-native'
import { useTailwind } from 'tailwind-rn'

interface SkeletonProps {
  data: Array<StyleProp<ViewStyle>>
  loading: boolean
  delay?: number
  style?: StyleProp<ViewStyle>
}

export const Skeleton: React.FC<SkeletonProps> = ({
  loading,
  data = [],
  delay = 0,
  style,
  children
}) => {
  const tailwind = useTailwind()
  const animateValue = useRef(new Animated.Value(1)).current
  const animation = useRef<any>()
  useEffect(() => {
    if (loading) {
      animation.current = Animated.sequence([
        Animated.loop(
          Animated.sequence([
            Animated.timing(animateValue, {
              toValue: 0.5,
              duration: 600,
              easing: Easing.linear,
              useNativeDriver: false
            }),
            Animated.timing(animateValue, {
              toValue: 1,
              duration: 600,
              easing: Easing.quad,
              useNativeDriver: false
            })
          ])
        )
      ]).start(() => {
        animateValue.setValue(1)
      })
    } else if (animation.current) {
      setTimeout(() => {
        animation.current.stop()
        animation.current = null
      }, delay)
    }
  }, [animateValue, loading])

  const renderSkeleton = useMemo(
    () =>
      data.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <View key={i} style={[styles.skeleton, item]} />
      )),
    [data, tailwind]
  )

  return (
    <View style={[tailwind('relative'), style]}>
      {loading && (
        <Animated.View
          style={[
            tailwind('absolute top-0 left-0 w-full h-full'),
            { opacity: animateValue }
          ]}
        >
          {renderSkeleton}
        </Animated.View>
      )}
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  skeleton: { backgroundColor: 'rgba(0,0,0,0.11)' }
})
