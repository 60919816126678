import {
  createContext,
  useState,
  useMemo,
  useContext,
  Children,
  ReactElement,
  ReactNode
} from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleProp,
  ViewStyle
} from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { isString } from 'lodash'

const TabsContext = createContext<any>(null)

export interface TabsProps {
  defaultTabKey?: string
  onChange?: () => void
  tabStyle?: StyleProp<ViewStyle>
  containStyle?: StyleProp<ViewStyle>
  children: ReactElement[]
}

export const Tabs = (props: TabsProps) => {
  const { defaultTabKey, children, onChange, tabStyle, containStyle } = props
  const firstTabKey = children[0]?.props.tabKey ?? '0'
  const tailwind = useTailwind()
  const [currentTabKey, setCurrentTabKey] = useState<string>(
    defaultTabKey ?? firstTabKey
  )
  const value = useMemo(() => {
    return {
      currentTabKey
    }
  }, [currentTabKey])

  const onChangeTab = (tabKey: string) => {
    setCurrentTabKey(tabKey)
    onChange && onChange()
  }

  const renderTabs = () => {
    return (
      <View style={tailwind('flex-row mb-4')}>
        {Children.map(children, (child: ReactElement, index: number) => {
          const { props: tabProps } = child
          const { tab, tabKey } = tabProps

          return (
            <TouchableOpacity
              style={[
                tailwind('flex-1 items-center justify-center'),
                tabStyle,
                currentTabKey === tabKey
                  ? tailwind('border-b border-primary')
                  : null
              ]}
              onPress={() => onChangeTab(tabKey ?? `${index}`)}
            >
              <Text
                style={[
                  currentTabKey === tabKey ? tailwind('text-primary') : null
                ]}
              >
                {tab}
              </Text>
            </TouchableOpacity>
          )
        })}
      </View>
    )
  }
  return (
    <TabsContext.Provider value={value}>
      <View style={containStyle}>
        {renderTabs()}
        {children}
      </View>
    </TabsContext.Provider>
  )
}

export interface TabPaneProps {
  // eslint-disable-next-line react/no-unused-prop-types
  tab: string
  tabKey?: string
  children: ReactElement | ReactNode
}

export const TabPane = (props: TabPaneProps) => {
  const { children, tabKey } = props
  const { currentTabKey } = useContext(TabsContext)
  const tailwind = useTailwind()

  return (
    <View style={[tabKey === currentTabKey ? null : tailwind('hidden')]}>
      {isString(children) ? <Text>{children}</Text> : children}
    </View>
  )
}

Tabs.TabPane = TabPane
