import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { keys } from 'lodash'
import { NavigationConfigs, ScreenWithChildrenConfig } from './NavigatorConfigs'

export class ScreensNavigator extends React.PureComponent {
  routes?: ScreenWithChildrenConfig

  render() {
    if (!this.routes) return null
    const { component: RouteScreen, children } = this.routes
    if (children && keys(children).length > 0) {
      const Stack = createStackNavigator<any>()
      return (
        <RouteScreen {...this.props}>
          <Stack.Navigator screenOptions={{ headerShown: false }}>
            {Object.keys(children).map((routeName) => {
              return <Stack.Screen name={routeName} {...children[routeName]} />
            })}
          </Stack.Navigator>
        </RouteScreen>
      )
    }
    return <RouteScreen {...this.props} />
  }
}

const renderTitle = ({ params }: any) =>
  params && params.documentTitle && params.documentTitle

export function handleNavigatorConfigs(configs: NavigationConfigs) {
  return keys(configs).map((routeName) => {
    const { title, component, children, auth } = configs[routeName] ?? {}
    return {
      auth,
      name: routeName,
      getComponent() {
        if (children && Object.keys(children).length > 0) {
          component.prototype.routes = configs[routeName]
          return ScreensNavigator
        }
        return component
      },
      options({ route }: any) {
        const documentTitle = renderTitle(route)
        if (route.state && children) {
          const { routes } = route.state
          return {
            title: title || children[routes[routes.length - 1].name].title,
            headerShown: false
          }
        }
        return { title: documentTitle ?? title, headerShown: false }
      }
    }
  })
}
