/* eslint-disable no-undef */
import * as React from 'react'
import { useEffect } from 'react'
import { View } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import * as Linking from 'expo-linking'
import { Storage } from '@/utils/storage'
import { STORAGE_KEYS } from '@/constants'
import { StackNavigationProp } from '@react-navigation/stack'
import { ScreenParamsList } from '@/navigation/NavigatorConfigs'
import appApi from '@/services/request/app.api'
import { useGlobalData } from '@/hooks/useGlobalData'

const wx = require('weixin-js-sdk')

type Props = {
  navigation: StackNavigationProp<
    RNav.RootTabParamList & ScreenParamsList,
    'Redirection'
  >
}
export default function Redirection({ navigation }: Props) {
  const tailwind = useTailwind()
  const { setGlobalData } = useGlobalData()
  const noAuthRoute = ['AboutWe']

  useEffect(() => {
    Linking.getInitialURL().then(async (url) => {
      if (url) {
        const {
          queryParams: { accessToken, routeName }
        } = Linking.parse(url)
        if (noAuthRoute.includes(routeName)) {
          navigation.replace(routeName)
          return
        }
        if (accessToken) {
          // 来自小程序端的token
          await Storage.setItem(STORAGE_KEYS.accessToken, accessToken)
          setTimeout(() => {
            try {
              appApi.selfUsingGET().then(
                async (res) => {
                  if (res) {
                    await setGlobalData({ userInfo: res })
                    navigation.replace(routeName)
                  } else {
                    wx.miniProgram.postMessage({
                      data: { toast: '获取用户信息出错' }
                    })
                    wx.miniProgram.navigateBack()
                  }
                },
                () => {
                  wx.miniProgram.postMessage({
                    data: { toast: '获取用户信息出错' }
                  })
                  wx.miniProgram.navigateBack()
                }
              )
            } catch (error) {
              wx.miniProgram.postMessage({
                data: { toast: '获取用户信息出错' }
              })
              wx.miniProgram.navigateBack()
            }
          }, 0)
        }
      }
    })
  }, [])
  return <View style={tailwind('flex-1 bg-white')} />
}
