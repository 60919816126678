/* eslint-disable no-unused-vars */
import * as React from 'react'
import {
  Dimensions,
  View,
  StyleProp,
  ViewStyle,
  TouchableOpacity
} from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { formatTableValue } from '@/utils/utils'
import { findLastIndex, max } from 'lodash'
import { AntDesign } from '@expo/vector-icons'
import { Text } from '../Text'

type BaseGridData = {
  paramCode?: string
  parentCode?: string
  paramName: string
  paramValue: unknown
  sort: number
  sortColumn: number
}

export type GridProps = {
  gridData: BaseGridData[][]
  width?: number
  containStyle?: StyleProp<ViewStyle>
  fixLast?: boolean
  columnTitle?: boolean
}

const windowWidth = Dimensions.get('window').width

export const BaseGrid: React.FC<GridProps> = ({
  gridData = [],
  width,
  containStyle,
  fixLast = false,
  columnTitle = false
}) => {
  const tailwind = useTailwind()

  const [firstLoadRows] = React.useState<number>(5)
  const [isExtend, setIsExtend] = React.useState<boolean>(false)

  const handleExtend = () => {
    setIsExtend((preState) => !preState)
  }

  const renderTr = (trData: any[]) => {
    let curIndex = 0
    const items: any[] = []

    const findLastIndexOfSameColumn = (el: any) => el.sortColumn === curIndex
    while (curIndex < trData.length) {
      const i = findLastIndex(trData, findLastIndexOfSameColumn)
      let content = ''
      if (i !== curIndex) {
        content = trData
          .slice(curIndex, i + 1)
          .reduce((acc, cur) => acc + cur.paramValue, '')
      } else {
        content = trData[curIndex].paramValue || '-'
      }
      items.push(
        <View
          // eslint-disable-next-line react/no-array-index-key
          key={curIndex}
          style={[
            tailwind(
              `border bg-white font-bold ${
                curIndex === 0 ? 'text-center' : 'text-right'
              } `
            ),
            {
              backgroundColor:
                columnTitle && curIndex === 0 ? '#E9E9E9' : '#fff',
              borderColor: '#F0F0F0',
              flex: i - curIndex + 1,
              paddingVertical: 8,
              paddingHorizontal: i - curIndex // 计算横向padding以对齐表格（后面的是加了边框）
            }
          ]}
        >
          <Text
            style={[
              tailwind(
                `text-xs ${columnTitle && curIndex === 0 ? 'font-bold' : ''}`
              ),
              {
                color: 'rgba(0,0,0,0.65)',
                paddingHorizontal: 8
              }
            ]}
          >
            {formatTableValue(content)}
          </Text>
        </View>
      )
      curIndex = i + 1
    }

    return items
  }

  const processShownData = (data: any) => {
    const isNeedExtend = data.length > 4
    if (!Array.isArray(data)) {
      return []
    }

    if (isExtend || !isNeedExtend) {
      return data.slice(1)
    }

    if (fixLast) {
      return data.slice(1, 4).concat([data[data.length - 1]])
    }

    return data.slice(1, 5)
  }

  return (
    <View>
      <View style={[tailwind('rounded z-10 overflow-scroll'), containStyle]}>
        <View
          style={[tailwind('flex'), { width: max([width, windowWidth - 32]) }]}
        >
          {/* 表头 */}
          <View style={tailwind('flex-row justify-around text-center')}>
            {gridData?.[0]?.map((h: any, hIndex: number) => (
              <View
                // eslint-disable-next-line react/no-array-index-key
                key={h.paramName + hIndex}
                style={[
                  tailwind('flex-1 border p-2 '),
                  {
                    borderColor: '#F0F0F0',
                    backgroundColor:
                      !columnTitle || hIndex === 0 ? '#E9E9E9' : '#fff'
                  }
                ]}
              >
                <Text
                  style={[
                    tailwind(
                      `${
                        !columnTitle || hIndex === 0 ? 'font-bold' : ''
                      } text-xs`
                    ),
                    { color: 'rgba(0,0,0,0.65)' }
                  ]}
                >
                  {h?.paramValue}
                </Text>
              </View>
            ))}
          </View>
          {/* 表数据 */}
          {processShownData(gridData)?.map((tr: any, trIndex: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <View key={trIndex} style={tailwind(`flex-row justify-around`)}>
              {renderTr(tr)}
            </View>
          ))}
        </View>
      </View>
      {width && width > windowWidth - 32 && (
        <View style={{ alignItems: 'flex-end', marginTop: 4 }}>
          <Text size='xs' color='text-3'>
            向右滑动查看更多
          </Text>
        </View>
      )}
      {gridData?.length > firstLoadRows && (
        <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={{ alignItems: 'center', paddingVertical: 8 }}
            onPress={handleExtend}
          >
            <Text size='xs' color='primary'>
              {isExtend ? '收起' : '展开全部'}
              <AntDesign
                name={isExtend ? 'up' : 'down'}
                size={12}
                color='#547FFF'
              />
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}
