import React from 'react'
import { View, Text } from 'react-native'
import { List } from 'antd-mobile'

export interface IReportFormProps {
  data: Array<{ label: string; value: string }>
}

export default function ReportForm(props: IReportFormProps) {
  const { data } = props
  return (
    <View>
      <List mode='card' style={{ margin: 0, '--font-size': '12px' }}>
        {data.map((item: any, index: number) => (
          <List.Item
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.label}_${index}`}
            extra={
              <Text
                style={{
                  fontSize: 12,
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontWeight: index === 0 ? '600' : '400'
                }}
              >
                {item.value}
              </Text>
            }
            style={{ fontWeight: index === 0 ? '600' : '400' }}
          >
            {item.label}
          </List.Item>
        ))}
      </List>
    </View>
  )
}
