/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Image,
  View,
  PanResponder,
  Animated,
  TouchableOpacity
} from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import { Skeleton } from '@/components/Skeleton'
import { OverlayInstance } from '@/components/Overlay/service'
import { isBase64 } from '@/utils/utils'
import { Modal } from '../Modal'
import { Text } from '../Text'

type VerifyParams = {
  result: boolean
  [key: string]: any
}

interface CaptchaContentProps {
  /**
   * 标题
   */
  title?: string
  /**
   * 加载网络图片
   */
  loadImg?: () => Promise<{
    background: string
    slider: string
    y: number
  }>
  /**
   * 校验结果
   * @param data
   */
  verify?: (data: Record<string, any> & { x: number }) => Promise<VerifyParams>
  /**
   * 关闭
   */
  onClose?: (result: boolean) => void
}

let verifyResponse: any

/**
 * 图片验证码
 * @constructor
 */
const CaptchaContent: React.VFC<CaptchaProps> = ({
  onClose,
  title = '滑块验证码',
  loadImg,
  verify = () => Promise.resolve({ result: true })
}) => {
  const tailwind = useTailwind()
  const [imgLoad, setImageLoad] = useState(false)
  const [result, setResult] = useState(false)
  const [image, setImage] =
    useState<{ background: string; slider: string; y: number }>()
  const params = useRef({}).current
  const pan = useRef(new Animated.Value(0)).current
  // 校验结果 动画值
  const status = useRef(new Animated.Value(0)).current
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => true,
      onPanResponderMove: (e, { dx }) => {
        let value = dx
        if (dx >= 238) value = 238
        if (dx <= 0) value = 0
        pan.setValue(Math.round((value / 238) * 100) / 100)
      },
      onPanResponderRelease: (e, { dx }) => {
        const animates = [
          Animated.timing(status, {
            duration: 300,
            toValue: 1,
            useNativeDriver: false
          }),
          Animated.timing(status, {
            duration: 1000,
            toValue: 1,
            useNativeDriver: false
          }),
          Animated.timing(status, {
            duration: 300,
            toValue: 0,
            useNativeDriver: false
          })
        ]
        verify({
          ...params,
          x: (Math.round((dx / 238) * 100) / 100) * 254
        }).then((res) => {
          verifyResponse = res
          setResult(!!res.result)
          if (res.result) {
            Animated.sequence(animates).start(() => {
              onClose?.(true)
            })
          } else {
            Animated.sequence([
              ...animates,
              Animated.timing(pan, {
                duration: 80,
                toValue: 0,
                useNativeDriver: false
              })
            ]).start()
          }
        })
      }
    })
  ).current
  const refresh = useCallback(() => {
    setImageLoad(true)
    loadImg?.()
      .then((data) => {
        Object.assign(params, data)
        let bgImg = data.background
        let sdImg = data.slider
        if (
          isBase64(data.background) &&
          !data.background.startsWith('data:image/')
        ) {
          bgImg = `data:image/png;base64,${data.background}`
        }
        if (isBase64(data.slider) && !data.slider.startsWith('data:image/')) {
          sdImg = `data:image/png;base64,${data.slider}`
        }
        setImage({ background: bgImg, slider: sdImg, y: data.y })
      })
      .finally(() => {
        setImageLoad(false)
      })
    // if ((pan as any)._value === 0) return
  }, [loadImg, pan, params])

  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const statusValue = status.interpolate({
    inputRange: [0, 1],
    outputRange: [-32, 0]
  })
  const imgLeft = pan.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 254]
  })
  const slideLeft = pan.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 238]
  })
  return (
    <View>
      <Skeleton
        style={tailwind('rounded-md overflow-hidden')}
        data={[{ width: 310, height: 155 }]}
        delay={1000}
        loading={imgLoad}
      >
        <Image
          onLoad={() => setImageLoad(false)}
          style={[{ width: 310, height: 155 }, tailwind('rounded-md')]}
          source={{ uri: image?.background }}
        />
        <Animated.Image
          style={[
            { width: 55, height: 55 },
            tailwind('rounded-md absolute'),
            { top: image?.y, left: imgLeft }
          ]}
          source={{ uri: image?.slider }}
        />
        <Animated.View
          style={[
            tailwind(
              `w-full h-8 absolute justify-center items-center ${
                result ? 'bg-success-3' : 'bg-error-3'
              }`
            ),
            { bottom: statusValue }
          ]}
        >
          <Text color='white' size='sm'>
            {result ? '验证通过' : '验证失败，请重新尝试'}
          </Text>
        </Animated.View>
      </Skeleton>
      <View
        style={tailwind(
          'bg-fill-2 h-8 mt-4 rounded-full items-center justify-center border border-line-1'
        )}
      >
        <Text color='text-1' size='base'>
          拖动滑块完成拼图
        </Text>
      </View>
      <Animated.View
        {...panResponder.panHandlers}
        style={[
          tailwind(
            'bg-white h-12 rounded-full shadow-md border border-line-1 -mt-10 justify-center items-center'
          ),
          {
            width: 72,
            transform: [{ translateX: slideLeft }],
            touchAction: 'none'
          }
        ]}
      >
        <MaterialCommunityIcons
          name='chevron-triple-right'
          size={26}
          color={tailwind('bg-primary').backgroundColor as string}
        />
      </Animated.View>
      <View
        style={tailwind(
          '-mx-2 flex-row px-4 items-center justify-between py-2'
        )}
      >
        <Text>{title}</Text>
        <View style={tailwind('flex-row')}>
          <TouchableOpacity activeOpacity={0.6} onPress={refresh}>
            <Ionicons name='refresh' size={30} color='#888' />
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={0.6}
            onPress={() => onClose?.(false)}
          >
            <Ionicons
              style={tailwind('ml-4')}
              name='close-circle-outline'
              size={30}
              color='#888'
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

interface CaptchaProps extends CaptchaContentProps {
  /**
   * 显示隐藏
   */
  visible?: boolean
}

export type CaptchaPropsType = React.VFC<CaptchaProps> & {
  show: (options: Omit<CaptchaContentProps, 'onClose'>) => {
    afterClose: (fn: (result: VerifyParams) => void) => void
  }
}

const PCaptcha: React.VFC<CaptchaProps> = ({ visible, ...props }) => {
  return (
    <Modal visible={visible} footer={null}>
      <CaptchaContent {...props} />
    </Modal>
  )
}

export const Captcha = PCaptcha as CaptchaPropsType

Captcha.show = ({
  title,
  loadImg,
  verify
}: Omit<CaptchaContentProps, 'onClose'>) => {
  let closeCallback: (result: VerifyParams) => void
  let instance: OverlayInstance | undefined
  let success = false
  const onClose = () => {
    closeCallback?.(success ? verifyResponse : undefined)
    instance?.destroy()
  }
  instance = Modal.show({
    content: (
      <CaptchaContent
        loadImg={loadImg}
        title={title}
        verify={verify}
        onClose={(value) => {
          success = value
          onClose()
        }}
      />
    ),
    onCancel: onClose,
    footer: null
  })
  return {
    afterClose: (fn) => {
      closeCallback = fn
    }
  }
}
