/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { View, FlatList, ActivityIndicator, Text } from 'react-native'
import { get, isEmpty } from 'lodash'
import { useTailwind } from 'tailwind-rn'
import request from '@bigtree/request'
import { getValidParams } from '@/utils/utils'

export type BaseListProps = {
  style?: any
  url?: string
  renderItem: any
  keyExtractor?: (item: any) => string
  immediate?: boolean
  limit?: number
  queryParams?: any
  fetchMethod?: 'get' | 'post'
  emptyText?: string
}

export const BaseList = (props: BaseListProps) => {
  const {
    keyExtractor = (item) => String(item.id),
    immediate = true,
    limit = 10,
    url,
    renderItem,
    style,
    queryParams,
    fetchMethod = 'get',
    emptyText = '暂无数据'
  } = props
  const [list, setList] = useState([])
  const [showSpin, setShowSpin] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [curPage, setCurPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const tailwind = useTailwind()

  const reload = ({ page = 1 }, allPageReload = true) => {
    if (!url) return
    allPageReload && setShowSpin(true)
    setRefreshing(true)
    setCurPage(page)
    const params = getValidParams({
      page,
      limit,
      ...queryParams
    })
    request[fetchMethod](url, { params })
      .then((response) => {
        const _list: any = isEmpty(response) ? [] : response.data
        const _totalPages = get(response, 'meta.pagination.pages', 0)
        setList(page <= 1 ? _list : list.concat(_list))
        setTotalPages(_totalPages)
      })
      .finally(() => {
        allPageReload && setShowSpin(false)
        setRefreshing(false)
      })
  }

  useEffect(() => {
    if (immediate) {
      reload({ page: 1 })
    }
  }, [immediate])

  useEffect(() => {
    if (queryParams !== undefined) {
      reload({ page: 1 })
    }
  }, [queryParams])

  const handleEndReached = () => {
    if (curPage < totalPages) {
      reload({ page: curPage + 1 }, false)
    }
  }

  return (
    <>
      {showSpin ? (
        <View style={tailwind('my-4')}>
          <ActivityIndicator size='large' />
        </View>
      ) : (
        <FlatList
          refreshing={refreshing}
          style={[tailwind('pr-2'), style]}
          data={list}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          onEndReached={handleEndReached}
          onEndReachedThreshold={0.1}
          ListEmptyComponent={<Text>{emptyText}</Text>}
        />
      )}
    </>
  )
}
