/* eslint-disable import/no-mutable-exports */
import React, { useEffect, useState } from 'react'
import { TouchableOpacity } from 'react-native'
import {
  createNavigationContainerRef,
  NavigationContainer
} from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import type { NavigationContainerRefWithCurrent } from '@react-navigation/core'
import VConsole from 'vconsole'
import { useGlobalData } from '@/hooks/useGlobalData'
import { isIos, isWeixin } from '@/utils/utils'
import NotFoundScreen from '@/screens/NotFoundScreen'
import { handleNavigatorConfigs } from '@/navigation/WithChildrenScreen'
import LinkingConfiguration from '@/navigation/LinkingConfiguration'
// import BottomTabNavigator from '@/navigation/BottomTabNavigator'
import navigatorConfigs from '@/navigation/NavigatorConfigs'
import { wxConfig } from '@/utils/wx'

const Stack = createNativeStackNavigator<RNav.RootStackParamList>()

function RootNavigator() {
  const { userInfo } = useGlobalData()
  const [vConsole, setVConsole] = useState<any>(null)
  const [pressCount, setPressCount] = useState<number>(0)

  useEffect(() => {
    return () => vConsole?.destroy()
  }, [])

  // useEffect(() => {
  //   setOpenid()
  // }, [location.search])

  const handleVConsoleShow = () => {
    if (vConsole) {
      return
    }
    if (pressCount >= 30) {
      setVConsole(new VConsole())
      return
    }
    setPressCount((prePressCount) => prePressCount + 1)
  }

  return (
    <>
      <TouchableOpacity
        style={{
          width: 20,
          height: 20,
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 100,
          backgroundColor: 'transparent'
        }}
        onPress={handleVConsoleShow}
      />
      <Stack.Navigator initialRouteName={userInfo ? 'Root' : 'Login'}>
        {/* {userInfo && (
          <Stack.Screen
            name='Root'
            component={BottomTabNavigator}
            options={{ headerShown: false, header: () => 22 }}
          />
        )} */}
        {handleNavigatorConfigs(navigatorConfigs).map((config: any) => {
          const {
            // auth = true,
            ...item
          } = config
          // 不再根据auth的值来插入路由 默认全部插入
          return <Stack.Screen key={item.name} {...item} />
        })}
        <Stack.Screen
          name='NotFound'
          component={NotFoundScreen}
          options={{ title: '页面不存在!' }}
        />
      </Stack.Navigator>
    </>
  )
}

export const navigationRef: NavigationContainerRefWithCurrent<RNav.RootStackParamList> =
  createNavigationContainerRef()

export default function Navigation() {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      ref={navigationRef}
      onReady={() => {
        const url = location.href.split('#')[0]
        if (!global.wxurl && isWeixin && isIos && url) {
          const jsApiList = [
            'updateAppMessageShareData',
            'onMenuShareAppMessage',
            'showMenuItems'
          ]
          wxConfig(url, jsApiList)
          global.wxurl = url
        }
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  )
}
