import React from 'react'
import { Text as RNText, TextProps as RNTextProps } from 'react-native'
import { useTailwind } from 'tailwind-rn'

export interface TextProps extends RNTextProps {
  /**
   * 字体大小 xs(12) / sm(14) / base(16) / lg(18) / xl(20) / 2xl(24) / 3xl(30) / 4xl(36)
   * @default 14
   */
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'
  /**
   * 字体颜色
   */
  color?: string
  /**
   * 是否加粗
   * @default normal
   * * 加粗 thin(100) / extralight(200) / light(300) / normal(400) / medium(500) / semibold(600) / bold(700) / extrabold(800) / black(900)
   */
  weight?:
    | 'thin'
    | 'extralight'
    | 'light'
    | 'normal'
    | 'medium'
    | 'semibold'
    | 'bold'
    | 'extrabold'
    | 'black'
  /**
   * 对齐方式
   * @default left
   */
  align?: 'left' | 'center' | 'right'
}

export const Text: React.FC<TextProps> = ({
  color = 'gray-800',
  size = 'sm',
  weight = 'normal',
  children,
  style = {},
  align = 'left',
  ...props
}) => {
  const tailwind = useTailwind()

  return (
    <RNText
      {...props}
      style={[
        tailwind(
          `text-${color} text-${size} font-${weight} ${
            align === 'left' ? '' : `text-${align}`
          }`
        ),
        style
      ]}
    >
      {children}
    </RNText>
  )
}
