/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import {
  ActivityIndicator,
  GestureResponderEvent,
  Pressable,
  StyleProp,
  Text,
  View,
  ViewStyle
} from 'react-native'
import { useTailwind } from 'tailwind-rn'

export interface ButtonProps {
  /**
   * @description 类型
   * @default primary
   */
  type?: 'primary' | 'secondary' | 'default'
  /**
   * @description 加载loading
   * @default false
   */
  loading?: boolean
  /**
   * 禁用
   * @default false
   */
  disabled?: boolean
  /**
   * 按压点击事件
   */
  onPress?: (event: GestureResponderEvent) => void | Promise<any>

  style?: StyleProp<ViewStyle>
}

export const Button: React.FC<ButtonProps> = ({
  type = 'default',
  disabled,
  onPress,
  style,
  loading: osLoading,
  children
}) => {
  const tailwind = useTailwind()
  const [press, setPress] = useState(false)
  const [pressLoading, setLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const loading = osLoading || pressLoading
  const defaultShadow =
    press || (!disabled && loading)
      ? {}
      : {
          shadowOpacity: 0.5,
          shadowOffset: {
            width: 0,
            height: 0
          },
          shadowRadius: 3,
          shadowColor: 'hsla(0, 0%, 0%, 0.1)'
        }
  const primaryShadow =
    press || (!disabled && loading)
      ? {}
      : {
          ...shadow,
          shadowRadius: 2,
          shadowColor: 'hsla(0, 0%, 0%, 0.2)'
        }
  const secondaryShadow =
    press || (!disabled && loading)
      ? {}
      : {
          ...shadow,
          shadowRadius: 2,
          shadowColor: 'hsla(0, 0%, 0%, 0.1)'
        }
  const styles = {
    button: tailwind(
      'h-10 py-2 px-4 text-sm rounded-md border flex-row justify-center'
    ),
    primary: {
      ...tailwind('bg-primary border-primary'),
      ...primaryShadow
    },
    default: {
      ...tailwind('bg-white border-primary'),
      ...defaultShadow
    },
    secondary: {
      ...tailwind('bg-secondary border-secondary'),
      ...secondaryShadow
    },
    defaultText: tailwind('text-primary'),
    primaryText: tailwind('text-white'),
    secondaryText: tailwind('text-primary')
  }
  const handlePressIn = () => setPress(true)
  const handlePressOut = () => setPress(false)
  const handlePress = (e: GestureResponderEvent) => {
    const fn = onPress?.(e)
    if (fn instanceof Promise) {
      setLoading(true)
      fn.finally(() => {
        setLoading(false)
      })
    }
  }

  return (
    <Pressable
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      onPress={handlePress}
      style={style}
      disabled={disabled}
    >
      <View
        style={[
          styles.button,
          disabled
            ? tailwind('bg-fill-2 border-line-4 border-opacity-30')
            : styles[type],
          {
            opacity: !disabled && loading ? 0.5 : 1
          }
        ]}
      >
        {!disabled && loading && (
          <ActivityIndicator
            color={type === 'primary' ? '#fff' : undefined}
            style={tailwind('mr-3')}
          />
        )}
        <Text
          selectable={false}
          style={[
            tailwind('text-sm text-center'),
            disabled
              ? tailwind('text-gray-400')
              : (styles as any)[`${type}Text`]
          ]}
        >
          {children}
        </Text>
      </View>
      <View
        style={[
          tailwind('absolute w-full h-full rounded'),
          {
            backgroundColor:
              press && !disabled && !loading
                ? `rgba(0,0,0,0.${type === 'primary' ? 1 : '04'})`
                : 'rgba(0,0,0,0)'
          }
        ]}
      />
    </Pressable>
  )
}

const shadow = {
  shadowOpacity: 0.5,
  shadowOffset: {
    width: 0,
    height: 1
  }
}
