import request from '@bigtree/request'
/**
 * 我的协议
 */
export const agreementList = () => {
  return request
    .request<any>({
      url: '/rx-app/agreement-list',
      method: 'GET'
    })
    .then((data) => data.data)
}
