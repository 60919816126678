import React from 'react'
import {
  Image,
  ImageSourcePropType,
  View,
  StyleProp,
  ViewStyle
} from 'react-native'
import { useTailwind } from 'tailwind-rn'

interface AvatarProps {
  /**
   * 图片路径
   */
  source: ImageSourcePropType
  /**
   * 图片大小
   * @default 44
   */
  size?: number
  /**
   * @description 头像container样式
   */
  style?: StyleProp<ViewStyle>
}

export const Avatar: React.FC<AvatarProps> = ({ source, size = 44, style }) => {
  const tailwind = useTailwind()
  return (
    <View
      style={[
        tailwind('rounded-full overflow-hidden border border-line-4'),
        { width: size, height: size },
        style
      ]}
    >
      <Image style={{ width: size, height: size }} source={source} />
    </View>
  )
}
