/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  FlatList,
  Dimensions,
  SafeAreaView
} from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { debounce } from 'lodash'
import { useTailwind } from 'tailwind-rn/dist'
import { Divider } from '../Divider'
import { Text } from '../Text'
import { Input } from '../Input'
import { MenuItem } from '../MenuItem'
// import { Popup } from '../Popup'
import { Modal } from '../Modal'

const { height } = Dimensions.get('window')

export interface SelectProps {
  data?:
    | any[]
    | ((params: {
        offset: number
        limit: number
        keyword?: string
      }) => Promise<any[]>)

  title?: string

  description?: string

  valueKey?: string

  titleKey?: string

  disabled?: boolean

  disabledAsLabel?: boolean

  descriptionKey?: string

  value?: any | any[]

  placeholder?: string

  search?: boolean

  supportInput?: boolean // 若列表无选项，则支持用户使用输入的内容作为选项
  multiple?: boolean

  throttle?: boolean // 节流实时搜索
  delay?: number // 延迟时间
  searchPlaceholder?: string

  // height?: number | string

  pagination?: boolean

  onSelected?: (index: number | number[], selected: any | any[]) => void

  onSelectedValue?: (value: any | any[]) => void

  searchKeyword?: string

  extraData?: { [key: string]: any } | ((list: any) => any | any[]) // 回显需要的额外参数
  onPressSelect?: (data: any) => boolean | void // 返回值用于是否不弹窗
  seachOnlyInput?: boolean
  containerStyle?: any
}

export const Select = (props: SelectProps) => {
  const listRef: any = useRef(null)
  const {
    data = [],
    multiple = false,
    placeholder,
    valueKey = 'value',
    titleKey = 'label',
    descriptionKey,
    onSelected: onPropSelect,
    onSelectedValue,
    search = false,
    // height,
    value,
    pagination = false, // ? before: false
    searchPlaceholder = '请输入关键词',
    searchKeyword,
    extraData,
    supportInput,
    disabled = false,
    disabledAsLabel = false,
    onPressSelect,
    seachOnlyInput,
    delay = 250,
    throttle,
    containerStyle
  } = props
  const isFetch = !Array.isArray(data) && typeof data === 'function'
  const [label, setLabel] = useState('')
  const [visible, setVisible] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState<string>('')
  const [list, setList] = useState<any[]>(isFetch ? [] : (data as any[]))
  const [cache, setCache] = useState<any[]>(isFetch ? [] : (data as any[]))
  const [selectedIndex, setSelectedIndex] = useState<number | number[]>(
    multiple ? [] : -1
  )
  const [cacheIndex, setCacheIndex] = useState<number | number[]>(
    multiple ? [] : -1
  )
  const [isLoadMore, setIsLoadMore] = useState<boolean>(true)
  const [hasNextData, setHasNextData] = useState(pagination && isFetch)
  const [offset, setOffset] = useState(0)
  const tailwind = useTailwind()

  const supportInputList = [{ label: `未查询到相关选项，点击使用"${keyword}"` }]
  const listData = supportInput && list.length <= 0 ? supportInputList : list

  const echo = useCallback(
    (selectList: any[] = [], _value = value || null) => {
      if (Array.isArray(_value)) {
        const findIndex = _value
          .map((val) =>
            selectList.findIndex(
              (item) => (valueKey ? item[valueKey] : item) === val
            )
          )
          .filter((val) => val > -1)
        setSelectedIndex(findIndex)
        onSelectValue(selectList, findIndex)
      } else {
        const findIndex = selectList.findIndex(
          (item) => (valueKey ? item[valueKey] : item) === _value
        )

        if (typeof findIndex === 'number' && findIndex > -1) {
          setSelectedIndex(findIndex)
          // TODO: @jianxian.liu onSelectValue(selectList, findIndex, false);
          onSelectValue(selectList, findIndex)
        } else {
          onPropSelect && onPropSelect(findIndex, null)
          onSelectedValue && onSelectedValue(null)
          setLabel('')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  const extraDataCallback = useCallback((_list, _extraData) => {
    if (_list && _list.length > 0 && _extraData) {
      if (typeof _extraData === 'function') {
        return _extraData(_list) || null
      }
      const keys = Object.keys(_extraData as any)
      const values = Object.keys(_extraData as any)
      const items = _list.filter(
        (item: any) =>
          keys.filter((key, i) => item[key] === values[i]).length ===
          keys.length
      )
      return items.length <= 1
        ? items[0] || null
        : items.map((item: any) => (valueKey ? item[valueKey] : item))
    }
  }, [])

  useEffect(() => {
    if (isFetch === false && data.length) {
      setList(data as any)
      setSelectedIndex(-1)
      setCacheIndex(-1)
      setLabel('')
      onSelectedValue?.(multiple ? [] : null)
    }
  }, [data, isFetch])

  useEffect(() => {
    if (value && searchKeyword) {
      setKeyword(searchKeyword)
      onSearch(searchKeyword).then((_data: any) => {
        echo(_data, value)
      })
    }
  }, [])

  useEffect(() => {
    if (extraData) {
      if (pagination && isFetch) {
        onRefreshData(null, extraData).then((items: any) => {
          echo(
            list,
            items.map((item: any) => (valueKey ? item[valueKey] : item))
          )
        })
      } else if (list.length > 0 || !isFetch) {
        echo(list, extraDataCallback(list, extraData))
      } else {
        onRefreshData().then((item: any) => {
          echo(item, extraDataCallback(item, extraData))
        })
      }
    }
  }, [extraData, echo])

  useEffect(() => {
    const selected = selectedIndex.toString()

    if (value && (!selected || selected === '-1')) {
      if (list.length > 0 || !isFetch) {
        echo(list)
      } else {
        const supportInputStatus = supportInput && keyword
        !searchKeyword &&
          !supportInputStatus &&
          onRefreshData().then((item: any) => {
            echo(item, value)
          })
      }
    } else if (value && list.length > 0) {
      echo(list)
    }
  }, [value, echo])

  const onSelectValue = (
    items = list,
    val: any = selectedIndex,
    change = true
  ) => {
    if (val < 0 || (Array.isArray(val) && val.find((item) => item < 0))) return
    let selectedList: any = []
    if (multiple) {
      selectedList = items?.filter((_, index) => val.find(index))
      setCacheIndex(val)
    } else {
      selectedList = items[val] ? [items[val]] : []
    }
    setLabel(
      selectedList
        .map((item: any) => (titleKey ? item[titleKey] : item))
        .join(', ')
    )
    const selectedValues = selectedList.map((item: any) =>
      valueKey ? item[valueKey] : item
    )
    if (change) {
      onPropSelect &&
        onPropSelect(val, multiple ? selectedList : selectedList[0])
      onSelectedValue &&
        onSelectedValue(multiple ? selectedValues : selectedValues[0])
    }
    setVisible(false)
  }

  const fetchData = (
    searchValue: string | null = keyword || null,
    _extraData: any = {}
  ) => {
    const limit = 20
    if (isFetch && hasNextData) {
      const nextOffset = offset + 1
      const params = pagination
        ? {
            offset: nextOffset,
            limit,
            keyword: searchValue,
            ..._extraData
          }
        : undefined
      return (data as any)(params).then((item: any) => {
        if (pagination) {
          if (item.length > limit || item.length < limit) {
            setHasNextData(false)
          }
          if (item.length <= limit) {
            setOffset(item.length + offset)
          }
        }
        return item
      })
    }
    return Promise.resolve([])
  }
  const onRefreshData = (searchValue?: string | null, _extraData?: any) => {
    if (seachOnlyInput && !searchValue) return
    if (!isFetch) return
    resetData()
    setRefreshing(true)
    return fetchData(searchValue, _extraData)
      .then((item: any) => {
        setList(item)
        setCache(item)
        setRefreshing(false)
        return item
      })
      .catch(() => {
        setRefreshing(false)
      })
  }
  const loadMore = () => {
    if (seachOnlyInput && !keyword) return
    if (!isFetch) return
    setLoading(true)
    fetchData()
      .then((item: any) => {
        setList(list.concat(item))
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  const onSelected = (index: number) => {
    if (multiple) {
      setSelectedIndex([...(selectedIndex as number[]), index])
    } else {
      if (supportInput && list.length <= 0) {
        setSelectedIndex(-1)
        setLabel(keyword)
        onPropSelect && onPropSelect(-1, { label: keyword })
        onSelectedValue && onSelectedValue(keyword)
        setVisible(false)
        return
      }
      setSelectedIndex(index)
      onSelectValue(list, index)
    }
  }
  const onSearch = (text: string) => {
    if (seachOnlyInput && !text) return
    listRef?.current?._listRef &&
      listRef.current._listRef.scrollToOffset({ offset: 0, animated: false })
    setSelectedIndex(multiple ? [] : -1)
    setCacheIndex(multiple ? [] : -1)
    setIsLoadMore(false)
    if (pagination && isFetch) {
      return onRefreshData(text)?.then((_data: any) => {
        setIsLoadMore(true)
        return _data
      })
    }
    setList(
      cache?.filter((item) =>
        (titleKey ? item[titleKey] : item).includes(text)
      ) || []
    )
  }
  const resetData = () => {
    setOffset(0)
    setHasNextData(true)
  }
  const onShowSelectList = () => {
    if (disabled || disabledAsLabel) return
    const supportInputStatus = supportInput && keyword
    if (!supportInputStatus && isFetch && (!list || list.length <= 0)) {
      onRefreshData()
    }
    const noShow = onPressSelect?.(data)
    if (!noShow) setVisible(true)
  }
  // const renderIcon = (index: any) => {
  //   if (selectedIndex !== index) return <></>
  //   return <Ionicons size={22} name='checkmark-outline' />
  // }
  const onClose = () => {
    if (multiple) {
      setSelectedIndex(cacheIndex)
    }
    setVisible(false)
  }
  const renderListItem = ({ item, index }: any) => {
    return (
      <React.Fragment key={index}>
        <MenuItem
          containStyle={tailwind('h-7')}
          onPress={() => onSelected(index)}
          title={titleKey ? item[titleKey] : item}
          subTitle={descriptionKey && item[descriptionKey]}
          // accessoryRight={() => renderIcon(index)}
        />
        <Divider />
      </React.Fragment>
    )
  }

  const onChangeSearchInput = (val: string) => {
    setKeyword(val)
    if (throttle) {
      onSearch(val)
    }
  }

  return (
    <>
      <TouchableWithoutFeedback
        onPress={onShowSelectList}
        disabled={disabled || disabledAsLabel}
      >
        <View
          style={[
            tailwind(
              'flex-row items-center justify-between w-full rounded border border-slate-400 p-2'
            ),
            containerStyle
          ]}
        >
          <Text
            style={tailwind(
              `mx-2 ${
                !label || (disabled && !disabledAsLabel) ? 'text-slate-400' : ''
              }`
            )}
          >
            {label ? label : placeholder}
          </Text>
          {!disabledAsLabel && (
            <Ionicons size={24} name='chevron-down-outline' />
          )}
        </View>
      </TouchableWithoutFeedback>
      <Modal
        visible={visible}
        containStyle={tailwind('w-screen')}
        onCancel={onClose}
        onConfirm={onClose}
      >
        <View style={tailwind('flex')}>
          <View
            style={tailwind('flex-row items-center justify-center h-10 px-4')}
          >
            {/* <TouchableOpacity onPress={() => onClose()}>
              <Ionicons size={22} name='close-outline' />
            </TouchableOpacity> */}
            <Text>请选择</Text>
            <TouchableOpacity
              onPress={() => onSelectValue()}
              style={{ minWidth: 26 }}
            >
              {multiple && <Text style={tailwind('text-primary')}>确定</Text>}
            </TouchableOpacity>
          </View>
          <Divider style={{ marginBottom: 8 }} />
          <View style={tailwind('flex')}>
            {search && (
              <View style={tailwind('mx-4 mb-1.5')}>
                <Input
                  returnKeyType='search'
                  onChange={debounce(onChangeSearchInput, delay)}
                  placeholder={searchPlaceholder}
                  // value={keyword}
                  onSubmitEditing={({ nativeEvent: { text } }) =>
                    onSearch(text)
                  }
                  prefix={<Ionicons size={22} name='search-outline' />}
                />
              </View>
            )}
            <SafeAreaView style={[{ minHeight: 260, maxHeight: height * 0.6 }]}>
              <FlatList
                data={listData}
                ListEmptyComponent={<Text>暂无数据</Text>}
                renderItem={renderListItem}
                refreshing={refreshing}
                onEndReached={
                  isLoadMore && pagination && isFetch && hasNextData && !loading
                    ? loadMore
                    : null
                }
                onEndReachedThreshold={0.1}
                // noMore={isFetch && !hasNextData}
                // noMoreTip='已无更多'
                // loadingMore={loading}
                onRefresh={isFetch ? onRefreshData : null}
                ref={(el) => (listRef.current = el)}
              />
            </SafeAreaView>
          </View>
        </View>
      </Modal>
    </>
  )
}
