/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Pressable, StyleProp, ViewStyle } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { Tag } from '../Tag'

interface TagSelectProps {
  value?: string | undefined
  onChange?: (value: string | undefined) => void
  containStyle?: StyleProp<ViewStyle>
  tagStyle?: StyleProp<ViewStyle>
  options?: { label: string; value: string; checked: boolean }[]
  asyncData?: any
  checkType?: 'radio' | 'checkbox'
}

export const TagSelect: React.FC<TagSelectProps> = (props) => {
  const {
    value,
    onChange,
    containStyle,
    tagStyle,
    options = [],
    asyncData,
    checkType = 'checkbox'
  } = props
  const tailwind = useTailwind()
  const [mergedOptions, setOptions] = useState<any[]>(options)

  const clearOptions = () =>
    setOptions(
      mergedOptions.map((opt) => {
        opt.checked = false
        return opt
      })
    )

  const resetOptions = (res: any) => {
    const _res = res.map((i: any) => {
      if (isNil(value) || value === '') {
        i.checked = false
      } else if (
        (checkType === 'radio' && value === i.value) ||
        (checkType === 'checkbox' && value?.split(',').includes(i.value))
      ) {
        i.checked = true
      }
      return i
    })
    setOptions(_res)
  }

  useEffect(() => {
    if (isNil(value) || value === '') clearOptions()
    if (value) onChange?.(value)
  }, [value])

  useEffect(() => {
    if (options && options.length > 0) {
      resetOptions(options)
    }
  }, [options])

  useEffect(() => {
    if (asyncData) {
      asyncData().then((res: any) => {
        resetOptions(res)
      })
    }
  }, [asyncData])

  const handleChecked = (_checked: boolean, index: number) => {
    let _options: any[] = mergedOptions
    if (checkType === 'radio') {
      _options = mergedOptions.map((opt) => {
        opt.checked = false
        return opt
      })
    }

    _options[index].checked = true

    setOptions(_options)
    const selected = _options
      .filter((o) => o.checked)
      .map((o) => o.value)
      .join(',')
    onChange?.(selected)
  }

  return (
    <Pressable
      style={[tailwind('flex-row items-center flex-wrap'), containStyle]}
    >
      {mergedOptions.map((opt, index) => (
        <Tag
          key={opt.value}
          checkable
          checked={opt.checked}
          color='brand'
          onChecked={($event) => handleChecked($event, index)}
          style={tagStyle}
          controllable
        >
          {opt.label}
        </Tag>
      ))}
    </Pressable>
  )
}
