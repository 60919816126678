import React, { useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { CapsuleTabs } from 'antd-mobile'
import ReportForm from './ReportForm'
import ReportTable from './ReportTable'
import ReportCurveChart from './ReportCurveChart'
import ReportBarChart from './ReportBarChart'

export interface IReportAdapterItemProps {
  data: any
  dataKey: string
}

export default function ReportAdapterItem(props: IReportAdapterItemProps) {
  const { data = {}, dataKey } = props

  const [activeIndex, setActiveIndex] = useState<number>(0)

  const processedData = useMemo(() => {
    return data[dataKey].find((item: any) => item.optionValue === activeIndex)
  }, [activeIndex, data, dataKey])

  const renderItem = () => {
    if (!processedData) {
      return null
    }
    switch (data.type) {
      case 'form':
        return <ReportForm data={processedData.data} />
      case 'table':
        return (
          <ReportTable
            data={processedData.data}
            unfold={processedData.unfold}
            tableDirection={processedData.tableDirection}
          />
        )
      case 'chart':
        if (data.chartType === 'line') {
          return (
            <ReportCurveChart
              data={processedData.data}
              title={processedData.unit}
            />
          )
        }
        if (data.chartType === 'bar') {
          return <ReportBarChart data={processedData.data} />
        }
        return null
      default:
        return null
    }
  }

  return (
    <View style={styles.container}>
      {data.showOption && data.options?.length && (
        <CapsuleTabs
          onChange={(key: string) => {
            setActiveIndex(Number(key))
          }}
          activeKey={String(activeIndex)}
        >
          {data.options.map((item: any) => (
            <CapsuleTabs.Tab
              title={item.text}
              key={item.value}
              style={{
                // @ts-ignore
                '--adm-font-size-7': '12px'
              }}
            />
          ))}
        </CapsuleTabs>
      )}
      {renderItem()}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 16,
    backgroundColor: '#fff',
    padding: 8,
    borderRadius: 8
  },
  wrap: {}
})
