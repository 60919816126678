import to from 'await-to-js'
import {
  isIos,
  isDebugg,
  isWeiXinBrowser,
  formatUrlParams
} from '@/utils/utils'
import { Storage } from '@/utils/storage'
import { store } from '@/hooks/useGlobalData'
import appApi from '@/services/request/app.api'

const wx = require('weixin-js-sdk')

//  微信sdk配置注入
export const wxConfig = (url: string, jsApiList: string[]) => {
  appApi
    .getJssdkConfigUsingGET({ url: encodeURIComponent(url) })
    .then((config) => {
      wx.config({
        debug: isDebugg,
        appId: config.appId, // 必填，公众号的唯一标识
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature, // 必填，签名
        jsApiList
      })
    })
}

//  微信分享
export async function wxShare(
  params: any,
  success?: any,
  fail?: any,
  cancel?: any,
  readySuccess?: any
) {
  const { name, inquireOrgLogo, link } = params
  if (!isIos) {
    const url = location.href.split('#')[0]
    const jsApiList = [
      'updateAppMessageShareData',
      'onMenuShareAppMessage',
      'showMenuItems'
    ]
    await wxConfig(url, jsApiList)
  }
  const imgUrl = inquireOrgLogo
    ? `${location.origin}/api/rx-file/file/noauth/preview/${inquireOrgLogo}?online=true`
    : undefined

  wx.ready(function () {
    if (isIos) {
      wx.showMenuItems({
        menuList: ['menuItem:share:appMessage']
      })
    }
    const title = `您好，${name}邀请您完成授权。以便为您提供更优质的企业服务。`
    const config = {
      title,
      link,
      desc: '请您完成授权，已便为您提供更优质的企业服务。', // 分享描述
      imgUrl,
      success,
      cancel,
      fail
    }

    if (wx.onMenuShareAppMessage) {
      wx.onMenuShareAppMessage(config)
    } else {
      wx.updateAppMessageShareData(config)
    }
    readySuccess()
  })
}

//  微信支付
export async function wxPay(
  params: any,
  success?: any,
  cancel?: any,
  readyFaied?: any
) {
  if (!isIos) {
    const url = location.href.split('#')[0]
    const jsApiList = ['chooseWXPay']
    await wxConfig(url, jsApiList)
  }
  const [err, res] = await to(appApi.getWxPayConfig(params))
  if (err) {
    return readyFaied()
  }
  const config = res.wechatPayAppVo
  wx.ready(function () {
    wx.chooseWXPay({
      timestamp: config.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
      nonceStr: config.nonceStr, // 支付签名随机串，不长于 32 位
      package: config.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
      signType: config.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
      paySign: config.paySign, // 支付签名
      success,
      cancel
    })
  })
}

//  微信静默授权
export const wxSilenceAuth = async (url?: string) => {
  const search = url ? `?appointUrl=${url}` : ''
  await location.assign(
    `${location.origin}/api/rx-app/wechat/noauth/wechat/redirect${search}`
  )
}

//  设置openid
export const setOpenid = async () => {
  if (!isWeiXinBrowser()) return
  const openid = formatUrlParams(location.search, 'openid')
  const globalOpenid = global.openid
  const StorageOpenid = await Storage.getOpenid()
  if (openid) {
    store.setGlobalData({ openid })
  } else if (StorageOpenid || globalOpenid) {
    store.setGlobalData({ openid: StorageOpenid || globalOpenid })
  }
}
