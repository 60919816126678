/* eslint-disable no-unused-vars */
import React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { OverlayService } from '../Overlay'
import { OverlayInstance } from '../Overlay/service'
import { createModalOverlay, MODAL_GROUP, ModalOptions, PModal } from './modal'

export interface ModalProps {
  /**
   * 字体图标
   */
  icon?: React.ReactNode
  /**
   * 标题
   */
  title?: React.ReactNode
  /**
   * 是否可见
   * @default false
   */
  visible?: boolean
  /**
   * 点击蒙层是否关闭
   * @default true
   */
  overlayClosable?: boolean
  /**
   * 是否显示右上角的关闭按钮
   * @default true
   */
  closable?: boolean
  /**
   * 底部额外的按钮
   */
  extraActions?: { name: string; onPress: () => void }[]
  /**
   * 样式
   */
  style?: StyleProp<ViewStyle>
  /**
   * 自定义模态窗底部
   */
  footer?: React.ReactElement | null
  /**
   * 全屏 - 水平方向
   * @default false
   */
  fullscreen?: boolean
  /**
   * 确认按钮文案
   */
  confirmText?: string | null
  /**
   * 取消按钮文案
   */
  cancelText?: string | null
  /**
   * 点击确认按钮回调
   */
  onConfirm?: () => void | Promise<boolean>
  /**
   * 点击遮罩层或右上角叉或取消按钮的回调
   */
  onCancel?: () => void
  /**
   * 样式
   */
  containStyle?: StyleProp<ViewStyle>
  /**
   * content容器类型
   */
  contentType?: 'scroll' | 'normal'
}

type ModalFunc = (options: ModalOptions) => OverlayInstance | undefined

export type ModalTypeProps = React.FC<ModalProps> & {
  show: ModalFunc
  confirm: ModalFunc
  info: ModalFunc
  warning: ModalFunc
  success: ModalFunc
  error: ModalFunc
  alert: ModalFunc
  destroyAll: () => void
}

const styles = StyleSheet.create({
  icon: {
    width: 48,
    height: 48,
    borderRadius: 48,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
})

export const Modal = PModal as ModalTypeProps

Modal.confirm = function (options) {
  return createModalOverlay({
    ...options
  })
}

Modal.info = function (options) {
  return createModalOverlay({
    cancelText: null,
    icon: <View style={styles.icon}>i</View>,
    ...options
  })
}

Modal.success = function (options) {
  return createModalOverlay({
    cancelText: null,
    icon: <View style={styles.icon}>✔️</View>,
    ...options
  })
}

Modal.error = function (options) {
  return createModalOverlay({
    cancelText: null,
    icon: <View style={styles.icon}>×</View>,
    ...options
  })
}

Modal.warning = function (options) {
  return createModalOverlay({
    cancelText: null,
    icon: <View style={styles.icon}>!</View>,
    ...options
  })
}

Modal.alert = function (options) {
  return createModalOverlay({
    cancelText: null,
    ...options
  })
}

Modal.show = function (options) {
  return createModalOverlay({
    cancelText: null,
    ...options
  })
}

Modal.destroyAll = function () {
  OverlayService.destroyAll(MODAL_GROUP)
}
