import { ComponentType } from 'react'
import { toPairs } from 'lodash'
// import Forget from '@/screens/Login/Forget'
import Setting from '@/screens/Myself/Setting'
import MyEnterprise from '@/screens/Myself/MyEnterprise'
import MyEnterpriseView from '@/screens/Myself/MyEnterpriseView'
import MobileModify from '@/screens/Myself/MobileModify'
import PasswordModify from '@/screens/Myself/PasswordModify'
import MyAgree from '@/screens/Myself/MyAgree'
import PersonalAuth from '@/screens/Myself/PersonalAuth'
import PersonalSign from '@/screens/Myself/PersonalSign'
import QuestionFeedback from '@/screens/Myself/QuestionFeedback'
import AboutWe from '@/screens/Myself/AboutWe'
import HistoryTradeQuery from '@/screens/Myself/HistoryTradeQuery'
import HistoryTradeDetail from '@/screens/Myself/HistoryTradeDetail'
// import AuthEntry from '@/screens/Investigated/AuthEntry'
// import ReportQuery from '@/screens/Home/ReportQuery'
import Result from '@/screens/Result/Result'
import CityModify from '@/screens/Myself/CityModify'
// import Register from '@/screens/Login/Register'
// import Login from '@/screens/Login/Login'
// import WxLogin from '@/screens/Login/WxLogin'
// import ReportDetail from '@/screens/Home/ReportDetail'
// import RpaLogin from '@/screens/Rpa/RpaLogin'
// import { RpaResult } from '@/screens/Rpa/RpaResult'
// import RpajsLogin from '@/screens/Rpa/RpajsLogin'
import Redirection from '@/screens/Redirection'
import WxTransition from '@/screens/WxTransition'
import Report from '@/screens/Report'
import Auth from '@/screens/Auth'

export interface ScreenConfig {
  /**
   * 指定path,web端使用，不指定默认按照规则转换routeName 为path
   */
  path?: string
  /**
   * 页面文档标题
   */
  title?: string
  component: ComponentType<any>
  auth?: boolean
}

export type ScreenWithChildrenConfig = ScreenConfig & {
  children?: {
    [routeName: string]: ScreenConfig
  }
}

export interface NavigationConfigs {
  [routeName: string]: ScreenWithChildrenConfig
}
// 页面参数类型
export type ScreenParamsList = Record<keyof typeof configs, any>

/**
 * ****************************************
 * 添加路由 按照类型 ScreenWithChildrenConfig
 * ****************************************
 */
const configs = {
  // Login: { component: Login, title: '登录', auth: false },
  // WxLogin: { component: WxLogin, title: '微信登录', auth: false },
  // Register: { component: Register, title: '注册', auth: false },
  // Forget: { component: Forget, title: '忘记密码', auth: false },
  // RpaLogin: { component: RpaLogin, title: '登录', auth: false },
  // RpajsLogin: { component: RpajsLogin, title: '登录', auth: false },
  // RpaResult: { component: RpaResult, title: '结果', auth: false },
  Result: { component: Result, title: '结果' },
  Report: { component: Report, title: '报告详情' },
  Auth: { component: Auth, title: '税务授权' },
  MyEnterprise: { component: MyEnterprise, title: '我的企业' },
  MyEnterpriseView: { component: MyEnterpriseView, title: '我的企业' },
  Setting: { component: Setting, title: '设置' },
  CityModify: { component: CityModify, title: '修改城市' },
  MobileModify: { component: MobileModify, title: '修改手机号' },
  PasswordModify: { component: PasswordModify, title: '修改登录密码' },
  MyAgree: { component: MyAgree, title: '我的协议' },
  PersonalAuth: { component: PersonalAuth, title: '个人认证' },
  PersonalSign: { component: PersonalSign, title: '个人电子签章' },
  QuestionFeedback: { component: QuestionFeedback, title: '问题反馈' },
  AboutWe: { component: AboutWe, title: '关于我们' },

  // Result: { component: Result, title: '结果' },
  // ReportQuery: { component: ReportQuery, title: '报告查询' },
  // ReportDetail: { component: ReportDetail, title: '报告详情' },
  HistoryTradeQuery: { component: HistoryTradeQuery, title: '交易查询' },
  HistoryTradeDetail: { component: HistoryTradeDetail, title: '交易明细' },
  // 被查询人流程
  // AuthEntry: { component: AuthEntry, title: '税务授权', auth: false },
  InvestResult: {
    component: Result,
    title: '税务授权',
    auth: false
  },
  Redirection: { component: Redirection, title: '融信易查', auth: false },
  Tr: { component: WxTransition, title: '融信易查', auth: false }
}

const resolveNoAuth = () =>
  toPairs(configs)
    .map(([key, value]: any) => {
      if (value?.auth === false) {
        return key.replace(/([A-Z]+)/g, '/$1').toLowerCase()
      }
      return null
    })
    ?.filter((item) => !!item)

export const noAuthConfig = resolveNoAuth()

export default configs
