/* eslint-disable no-unsafe-optional-chaining */
import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  useMemo
} from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { Text } from '@/components/Text'
import { Tabs, Swiper, DotLoading } from 'antd-mobile'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import {
  getNoauthReportDetail,
  getNoauthReportTemplate,
  downloadReport
} from '@/services/apis/report.api'
import ReportIndex, { IReportIndexProps } from './ReportIndex'
import ReportAdapter from './ReportAdapter'

export const ReportContext = createContext({ onDownload: null } as any)

export interface IReportProps {
  route: any
}

export default function Report(props: IReportProps) {
  const { route } = props

  const swiperRef = useRef<SwiperRef>(null)

  const [data, setData] = useState<any>({})
  const [activeIndex, setActiveIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [reportErr, setReportErr] = useState('')

  useEffect(() => {
    if (route?.params?.reportId) {
      getNoauthReportDetail(route.params.reportId)
        .then((res: any) => {
          setData(res || {})
          setLoading(false)
        })
        .catch((err) => {
          setReportErr(err)
          setLoading(false)
        })
    } else if (route?.params?.templateId) {
      getNoauthReportTemplate(route.params.templateId)
        .then((res: any) => {
          setData(res || {})
          setLoading(false)
        })
        .catch((err) => {
          setReportErr(err)
          setLoading(false)
        })
    }
    const timer = setTimeout(() => {
      setLoading(false)
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  const handleIndexImgLoad = () => {
    setLoading(false)
  }

  const handleDownload = async (e: string) => {
    if (route?.params?.reportId) {
      await downloadReport(e, route?.params?.reportId)
      return true
    }
    return false
  }

  const reportContextValue = useMemo(() => {
    return {
      onDownload: handleDownload
    }
  }, [])

  if (reportErr) {
    return (
      <View style={StyleSheet.flatten([styles.container, styles.errContainer])}>
        <Image
          source={{
            uri: require('@img/reort_err.png'),
            width: 236,
            height: 200
          }}
        />
        <Text
          size='base'
          weight='medium'
          color='rgba(0,0,0,.85)'
          style={{ marginTop: 36 }}
        >
          {reportErr}
        </Text>
      </View>
    )
  }

  return (
    <ReportContext.Provider value={reportContextValue}>
      <View style={styles.container}>
        {+data?.tabData?.[0]?.data?.source === 3 && (
          <iframe
            title=' '
            src={data?.tabData?.[0]?.data?.reportH5Url}
            style={{ border: 'medium none', height: '100%' }}
          />
        )}
        {+data?.tabData?.[0]?.data?.source !== 3 && loading && (
          <View style={styles.loadingWrap}>
            <DotLoading
              color='primary'
              style={{
                fontSize: 18,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1
              }}
            />
          </View>
        )}
        {+data?.tabData?.[0]?.data?.source !== 3 && (
          <Tabs
            activeKey={data?.tabs?.[activeIndex].value.toString()}
            onChange={(key) => {
              const index = data?.tabs?.findIndex(
                (item: any) => item.value.toString() === key
              )
              setActiveIndex(index)
              swiperRef.current?.swipeTo(index)
            }}
            style={{
              '--title-font-size': '14px'
            }}
          >
            {data?.tabs?.map((item: any) => (
              <Tabs.Tab title={item.name} key={item.value} />
            ))}
          </Tabs>
        )}
        {+data?.tabData?.[0]?.data?.source !== 3 && (
          <Swiper
            direction='horizontal'
            indicator={() => null}
            ref={swiperRef}
            defaultIndex={activeIndex}
            onIndexChange={(index) => {
              setActiveIndex(index)
            }}
            allowTouchMove={false}
          >
            {data?.tabs?.map((item: any) => (
              <Swiper.Item style={{ height: '100%' }} key={item.value}>
                <View style={{ height: '100%' }}>
                  {item.value === 0 ? (
                    <ReportIndex
                      {...(data?.tabData?.find((el: any) => el.key === 0)
                        ?.data as IReportIndexProps)}
                      onImgLoad={handleIndexImgLoad}
                    />
                  ) : (
                    <ReportAdapter
                      data={
                        data?.tabData?.find((el: any) => el.key === item.value)
                          ?.data as any[]
                      }
                    />
                  )}
                </View>
              </Swiper.Item>
            ))}
          </Swiper>
        )}
      </View>
    </ReportContext.Provider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  errContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingWrap: {
    backgroundColor: '#fff',
    zIndex: 999,
    ...StyleSheet.absoluteFillObject
  }
})
