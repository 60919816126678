/* eslint-disable no-unused-vars */
import * as React from 'react'
import { useMemo, useState } from 'react'
import {
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  View,
  ViewStyle,
  StyleProp
} from 'react-native'
import { useTailwind } from 'tailwind-rn'

export interface InputProps extends Omit<TextInputProps, 'onChange'> {
  /**
   * @description 类型
   * @default default
   */
  type?: 'underline' | 'default' | 'noline'
  /**
   * @description 大小
   * @default default
   */
  size?: 'small' | 'default' | 'large'
  /**
   * 前缀内容
   */
  prefix?: React.ReactNode | ((props: { color: string }) => React.ReactNode)
  /**
   * 后缀内容
   */
  suffix?: React.ReactNode | (({ color }: { color: string }) => React.ReactNode)
  /**
   * 校验状态
   */
  status?: 'error' | 'warning'
  onChange?: (value: string) => void
  containStyle?: StyleProp<ViewStyle>
}

const statusStyle = {
  error: 'border-danger',
  warning: 'border-warning'
}

export const Input: React.FC<InputProps> = ({
  type = 'default',
  onBlur,
  onFocus,
  prefix,
  suffix,
  style,
  status,
  onChange,
  containStyle,
  ...props
}) => {
  const [focus, setFocus] = useState(false)
  const tailwind = useTailwind()
  const handleOnBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    onBlur?.(e)
    setFocus(false)
  }

  const handleOnFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    onFocus?.(e)
    setFocus(true)
  }

  // eslint-disable-next-line no-nested-ternary
  const borderColor = status
    ? statusStyle[status]
    : focus
    ? 'border-primary'
    : 'border-line-2'
  const color = tailwind(status ? statusStyle[status] : 'border-text-4')
    .borderLeftColor as string
  const renderContent = useMemo(
    () => (
      <>
        {Boolean(prefix) && (
          <View style={tailwind('mr-1')}>
            {typeof prefix === 'function' ? prefix({ color }) : prefix}
          </View>
        )}
        <TextInput
          {...props}
          style={[styles.input, tailwind('bmu-input'), style]}
          onFocus={handleOnFocus}
          onChangeText={onChange}
          onBlur={handleOnBlur}
        />
        {Boolean(suffix) && (
          <View style={tailwind('ml-1')}>
            {typeof suffix === 'function' ? suffix({ color }) : suffix}
          </View>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, prefix, suffix]
  )

  if (type === 'underline') {
    return (
      <View style={containStyle}>
        <View style={tailwind('flex-row items-center px-1')}>
          {renderContent}
        </View>
        <View
          style={[
            tailwind(`border-b ${borderColor}`),
            focus ? styles.lineShadow : null
          ]}
        />
      </View>
    )
  }

  if (type === 'noline') {
    return (
      <View
        style={[tailwind(`px-2 rounded flex-row items-center`), containStyle]}
      >
        {renderContent}
      </View>
    )
  }

  return (
    <View
      style={[
        tailwind(`px-2 border rounded flex-row items-center ${borderColor}`),
        focus ? styles.shadow : null,
        containStyle
      ]}
    >
      {renderContent}
    </View>
  )
}

const shadow = {
  shadowOpacity: 0.5,
  shadowOffset: {
    width: 0,
    height: 0
  },
  shadowColor: 'hsla(0, 0%, 0%, 0.4)'
}

const styles = StyleSheet.create({
  shadow: {
    ...shadow,
    shadowRadius: 2
  },
  lineShadow: {
    ...shadow,
    shadowRadius: 1
  },
  input: Platform.select({
    native: {
      borderWidth: 0,
      lineHeight: 18,
      flex: 1
    },
    web: {
      borderWidth: 0,
      lineHeight: 16,
      outlineStyle: 'none',
      outlineWidth: 0,
      flex: 1
    }
  }) as ViewStyle
})
