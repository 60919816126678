import { useMemo } from 'react'
import { useTailwind } from 'tailwind-rn'
import { Form, FormConfigs, Layout } from '@/components'
import { rules } from '@/components/Form/rules'
import { useGlobalData } from '@/hooks/useGlobalData'
import { hiddenMobile } from '@/utils/utils'
import appApi from '@/services/request/app.api'

const { name, idCard } = rules

export default function PersonalAuth(props: any) {
  const { navigation, route } = props
  const tailwind = useTailwind()
  const { userInfo, setGlobalData } = useGlobalData()

  const configs: FormConfigs = useMemo(
    () => [
      {
        type: 'input',
        name: 'mobile',
        label: '注册手机号',
        value: hiddenMobile(userInfo?.mobile),
        props: {
          type: 'noline',
          disabled: true
        }
      },
      {
        type: 'input',
        name: 'inquireUserName',
        label: '姓名',
        required: true,
        props: {
          placeholder: '请输入姓名',
          type: 'noline',
          returnKeyType: 'next',
          maxLength: 20
        },
        rules: [name]
      },
      {
        type: 'input',
        name: 'inquireIdNum',
        label: '身份证号',
        required: true,
        props: {
          placeholder: '请输入身份证号',
          type: 'noline',
          returnKeyType: 'next',
          maxLength: 18
        },
        rules: [idCard]
      }
    ],
    []
  )

  const onSubmit = async ({ inquireUserName, inquireIdNum }: any) =>
    appApi
      .checkPersonThreeElementUsingPOST({
        inquireUserName,
        inquireIdNum
      })
      .then(() =>
        appApi.selfUsingGET().then(async (res) => {
          await setGlobalData({ userInfo: res })
          navigation.replace('PersonalSign', route?.params || {})
          return true
        })
      )

  return (
    <Layout>
      <Form
        configs={configs}
        fieldStyle={tailwind('bg-white rounded-md')}
        formContainStyle={tailwind('flex-1 justify-between')}
        onSubmit={onSubmit}
        subtext='下一步'
        labelStyle={{ minWidth: 100 }}
      />
    </Layout>
  )
}
