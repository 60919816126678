import React, { useContext, useRef, useState } from 'react'
import {
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions
} from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import ReportTitle from './components/ReportTitle'
import ReportAdapterItem from './components/ReportAdapterItem'
import ReportDownload from './components/ReportDownload'
import { ReportContext } from './index'

export interface IReportAdapterProps {
  data: any[]
}

export default function ReportAdapter(props: IReportAdapterProps) {
  const { data } = props

  const scrollRef = useRef<any>(null)

  const { height: windowHeight } = useWindowDimensions()

  const [showToTop, setShowToTop] = useState<boolean>(false)

  const reportContext = useContext(ReportContext)

  const render = (item: any, index: number) => {
    switch (item.type) {
      case 'title':
        return (
          <ReportTitle
            level={item.level}
            title={item.value}
            tip={item.tip}
            key={`title_${index}`}
          />
        )
      case 'form':
      case 'table':
      case 'chart':
        return (
          <ReportAdapterItem
            data={item}
            dataKey={`${item.type}Data`}
            key={`${item.type}_${index}`}
          />
        )
      default:
        break
    }
  }

  const handleToTop = () => {
    scrollRef.current?.scrollTo({ y: 0, animated: true })
  }

  const handleScroll = (e: any) => {
    const y = e.nativeEvent.contentOffset.y
    if (y >= windowHeight - 200) {
      setShowToTop(true)
    } else {
      setShowToTop(false)
    }
  }
  return (
    <>
      <ScrollView
        ref={scrollRef}
        style={[styles.container, { height: windowHeight - 8 }]}
        onScroll={handleScroll}
      >
        {data.map(render)}
      </ScrollView>
      {showToTop && (
        <TouchableOpacity style={styles.toTop} onPress={handleToTop}>
          <AntDesign name='upcircle' size={40} color='rgba(138,167,255, 0.6)' />
        </TouchableOpacity>
      )}
      <ReportDownload onConfirm={reportContext.onDownload} />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: '#f7f7f7',
    flex: 1
  },
  toTop: {
    bottom: '12%',
    right: 24,
    zIndex: 999,
    position: 'absolute'
  }
})
