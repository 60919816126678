import request from '@bigtree/request'

interface PageUsingGETParams {
  // 字典标签
  dictLabel?: string
  // 字典排序
  dictSort?: number
  // 字典类型
  dictType?: string
  // 字典键值
  dictValue?: string
  // 分页limit
  limit?: number
  // 分页page
  page?: number
  // 父字典键值
  parentValue?: string
  // 备注
  remark?: string
  // 状态（0正常 1停用）
  status?: string
}
interface SelectOneUsingGET1Params {
  // 字典标签
  dictLabel?: string
  // 字典排序
  dictSort?: number
  // 字典类型
  dictType?: string
  // 字典键值
  dictValue?: string
  // 分页limit
  limit?: number
  // 分页page
  page?: number
  // 父字典键值
  parentValue?: string
  // 备注
  remark?: string
  // 状态（0正常 1停用）
  status?: string
}
interface PageUsingGET1Params {
  // 字典名称
  dictName: string
  // 字典类型
  dictType: string
  // id
  id?: number
  // 分页limit
  limit?: number
  // 分页page
  page?: number
  // 备注
  remark?: string
  // 状态（0正常 1停用）
  status?: string
}

const RxSystemApi = {
  /**
   * 字典数据新增
   */
  addUsingPOST: (dictDataVo: RxSystem.SysDictDataVo) => {
    return request
      .request<RxSystem.Boolean>({
        url: '/rx-system/dict-data',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: dictDataVo
      })
      .then((data) => data.data)
  },
  /**
   * 字典数据修改
   */
  editUsingPUT: (dictDataVo: RxSystem.SysDictDataVo) => {
    return request
      .request<RxSystem.Boolean>({
        url: '/rx-system/dict-data',
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: dictDataVo
      })
      .then((data) => data.data)
  },
  /**
   * 字典数据列表
   */
  pageUsingGET: (query: PageUsingGETParams) => {
    return request
      .request<RxSystem.PageInfoSysDictDataDto>({
        url: '/rx-system/dict-data/page',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 字典数据列表noAuth
   */
  pageNoAuthUsingGET: (query: PageUsingGETParams) => {
    return request
      .request<RxSystem.PageInfoSysDictDataDto>({
        url: '/rx-system/dict-data/noauth/page',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 查询单个字典
   */
  selectOneUsingGET1: (query: SelectOneUsingGET1Params) => {
    return request
      .request<RxSystem.SysDictDataDto>({
        url: '/rx-system/dict-data/select-one',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 字典数据删除
   */
  removeUsingDELETE: (dictIds: string) => {
    return request
      .request<any>({
        url: `/rx-system/dict-data/${dictIds}`,
        method: 'DELETE'
      })
      .then((data) => data.data)
  },
  /**
   * 字典数据详情
   */
  getInfoUsingGET: (id: number) => {
    return request
      .request<RxSystem.SysDictDataDto>({
        url: `/rx-system/dict-data/${id}`,
        method: 'GET'
      })
      .then((data) => data.data)
  },
  /**
   * 字典类型新增
   */
  addUsingPOST1: (dictTypeVo: RxSystem.SysDictTypeVo) => {
    return request
      .request<RxSystem.Boolean>({
        url: '/rx-system/dict-type',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: dictTypeVo
      })
      .then((data) => data.data)
  },
  /**
   * 字典类型修改
   */
  editUsingPUT1: (dictTypeVo: RxSystem.SysDictTypeVo) => {
    return request
      .request<RxSystem.Boolean>({
        url: '/rx-system/dict-type',
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: dictTypeVo
      })
      .then((data) => data.data)
  },
  /**
   * 字典类型列表
   */
  pageUsingGET1: (query: PageUsingGET1Params) => {
    return request
      .request<RxSystem.PageInfoSysDictTypeDto>({
        url: '/rx-system/dict-type/page',
        method: 'GET',
        params: query
      })
      .then((data) => data.data)
  },
  /**
   * 字典类型删除
   */
  removeUsingDELETE1: (dictIds: string) => {
    return request
      .request<any>({
        url: `/rx-system/dict-type/${dictIds}`,
        method: 'DELETE'
      })
      .then((data) => data.data)
  },
  /**
   * 字典类型详情
   */
  getInfoUsingGET1: (dictId: number) => {
    return request
      .request<RxSystem.SysDictTypeDto>({
        url: `/rx-system/dict-type/${dictId}`,
        method: 'GET'
      })
      .then((data) => data.data)
  }
}

export default RxSystemApi
