import request from '@bigtree/request'

export const listUrl = `/rx-app/report/list`

/**
 * 查询指定报告模版模块的展示内容
 */
export const getReportDetail = (dataVO: any) => {
  return request
    .request<any>({
      url: '/rx-app/report-body/result-show',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: { templateId: 't0001', ...dataVO }
    })
    .then((data) => data.data)
}

/**
 * 查询指定报告模版模块的展示内容
 */
export const getNoauthReportDetail = (reportId: string) => {
  return request
    .request<any>({
      url: `/rx-app/noauth/rpa/result-show/${reportId}`,
      // url: '/rx-app/noauth/report-body/result-show',
      method: 'GET',
      // method: 'POST',
      headers: { 'Content-Type': 'application/json' }
      // params: { reportId }
    })
    .then((data) => data.data)
}

/**
 * 查询指定报告模版模块的展示内容
 */
export const getNoauthReportTemplate = (templateId: string) => {
  return request
    .request<any>({
      url: `/rx-app/noauth/report-body/result-show`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: { templateId }
    })
    .then((data) => data.data)
}

/**
 * 下载报告PDF接口
 */
export const downloadReport = (email: string, reportId: string) => {
  return request
    .request<any>({
      url: `/rx-app/noauth/rpa/download-report`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: { email, reportId }
    })
    .then((data) => data.data)
}
