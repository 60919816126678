import { useEffect } from 'react'
import { Image, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { uniqueId, isString, toNumber } from 'lodash'
import { StackNavigationProp } from '@react-navigation/stack'
import { ScreenParamsList } from '@/navigation/NavigatorConfigs'
import { Layout, Button, Text } from '@/components'
import { ReportCreateStatus } from '@/constants'
import appApi from '@/services/request/app.api'

interface JumpParams {
  title?: string
  btnText?: string
  description?: string[] | string
  width?: number
  height?: number
  page?: 'invitDone' | 'invitAbort' | 'feedBack' | 'wfq'
  reportId?: string
}

export interface ResultProps {
  route: {
    params: JumpParams
  }
  navigation: StackNavigationProp<
    RNav.RootStackParamList & ScreenParamsList,
    'Result'
  >
}

const imageMap = {
  feedBack: require('@img/result-deal.png'),
  invitDone: require('@img/result-finish.png'),
  invitAbort: require('@img/result-abort.png'),
  wfq: require('@img/result-deal.png')
}

export const Result = (props: ResultProps) => {
  const { route, navigation } = props
  const {
    title = '意见反馈',
    btnText = '完成',
    description = ['非常感谢您的宝贵建议，我们将尽快处理'],
    page = 'feedBack',
    reportId,
    width = 100,
    height = 120
  } = route?.params || {}
  const tailwind = useTailwind()

  useEffect(() => {
    navigation.setOptions({ title })
  }, [title])

  useEffect(() => {
    if (page === 'wfq' && reportId) {
      appApi.queryNoauthReportResultPOST({ reportId }).then((res) => {
        const grantAuthStatus = toNumber(res?.grantAuthStatus)
        if (grantAuthStatus === ReportCreateStatus.AUTH_ABORT) {
          return navigation.setParams({
            page: 'invitAbort',
            description: '该报告已取消申请，如需继续申请，可以联系查询企业。',
            btnText: '我知道了'
          })
        }
        if (
          [
            ReportCreateStatus.AUTH_PAY,
            ReportCreateStatus.AUTH_CREATING,
            ReportCreateStatus.AUTH_DONE
          ].includes(grantAuthStatus)
        ) {
          return navigation.setParams({
            page: 'invitDone',
            description: '您已授权完成',
            btnText: '我知道了'
          })
        }
      })
    }
  }, [])

  const renderDesc = () => {
    return isString(description) ? (
      <Text key={uniqueId()} style={tailwind('text-opacity-80')}>
        {description}
      </Text>
    ) : (
      description.map((desc: string) => (
        <Text key={uniqueId()} style={tailwind('text-opacity-80')}>
          {desc}
        </Text>
      ))
    )
  }

  const handleSubmit = () => {
    if (page === 'feedBack') {
      navigation.replace('Root', { screen: 'Myself' })
    }
  }

  return (
    <Layout style={tailwind('bg-white justify-between')}>
      <View style={tailwind('flex-1 justify-center items-center')}>
        <Image
          source={{
            uri: imageMap[page],
            width,
            height
          }}
          style={tailwind('mb-6')}
          resizeMode='contain'
        />
        {renderDesc()}
      </View>
      <Button type='primary' onPress={handleSubmit}>
        {btnText}
      </Button>
    </Layout>
  )
}
