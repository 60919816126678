/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-mutable-exports */
import { useEffect, useRef, useState } from 'react'
import { OverlayFactory, OverlayOptions, OverlaySubject } from './service'

export let OverlayService: OverlayFactory

export const useListenOverlay = (): OverlayOptions[] => {
  const [overlays, setOverlays] = useState<Record<string, OverlayOptions[]>>({})
  const subject = useRef(new OverlaySubject()).current

  useEffect(() => {
    OverlayService = new OverlayFactory(subject)
    subject.subscribe((lays) => {
      setOverlays({ ...lays })
    })
  }, [])

  return Object.values(overlays).flat()
}
