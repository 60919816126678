/* eslint-disable no-undef */
import * as React from 'react'
import { useTailwind } from 'tailwind-rn'
import { Layout, BaseList, Text, Input, Toast } from '@/components'
import { View, TouchableOpacity } from 'react-native'
import { useState } from 'react'
import { dictUrl } from '@/services/apis'
import { AntDesign } from '@expo/vector-icons'
import { StackNavigationProp } from '@react-navigation/stack'
import { ScreenParamsList } from '@/navigation/NavigatorConfigs'
import { debounce } from 'lodash'
// import { useGlobalData } from '@/hooks/useGlobalData'
import appApi from '@/services/request/app.api'

const wx = require('weixin-js-sdk')

type Props = {
  navigation: StackNavigationProp<
    RNav.RootTabParamList & ScreenParamsList,
    'CityModify'
  >
}

export default function CityModify() {
  // { navigation }: Props
  const tailwind = useTailwind()
  const [queryParams, setQueryParams] = useState<any>()
  // const { setGlobalData } = useGlobalData()

  const handleSearch = (input: any) => {
    setQueryParams({ dictLabel: input })
  }

  const handlePress = async (item: any) => {
    await appApi
      .updateCityUsingGET({
        inquireCity: item.dictValue
      })
      .then(() => {
        appApi.selfUsingGET().then(async (res) => {
          try {
            wx.miniProgram.postMessage({
              data: { userInfo: res || '' }
            })
            wx.miniProgram.navigateBack()
          } catch (error) {
            Toast.show(JSON.stringify(error))
          }
        })
      })
  }

  return (
    <Layout>
      <View style={tailwind('bg-fill-3 rounded')}>
        <Input
          placeholder='点击搜索城市'
          containStyle={tailwind('border-none')}
          prefix={<AntDesign name='search1' size={20} />}
          onChange={debounce(handleSearch, 500)}
        />
      </View>
      <BaseList
        style={tailwind('m-1 mt-4')}
        renderItem={({ item }: any) => (
          <TouchableOpacity
            style={tailwind('bg-white mb-3 p-4 rounded-lg')}
            onPress={() => handlePress(item)}
          >
            <View>
              <Text>{item.dictLabel}</Text>
            </View>
          </TouchableOpacity>
        )}
        url={`${dictUrl}?dictType=area_city&limit=999`}
        queryParams={queryParams}
      />
    </Layout>
  )
}
