import React, { useState, useEffect, useRef, useMemo } from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  findNodeHandle,
  UIManager,
  TouchableOpacity,
  useWindowDimensions
} from 'react-native'
import { AntDesign } from '@expo/vector-icons'

export interface IReportTableProps {
  data: Array<Item[]>
  unfold?: boolean
  tableDirection?: 'row' | 'column'
}

type Item = {
  columnNo: number
  rowNo: number
  value: string
  bgColor?: string
  color?: string
}

const firstLoadRows = 5

export default function ReportTable(props: IReportTableProps) {
  const { data, unfold, tableDirection = 'row' } = props
  const headerRef = useRef<any>(null)

  const { width: windowWidth } = useWindowDimensions()
  const [hWidth, setHWidth] = useState<number>(0)
  const [hWidthArr, setHWidthArr] = useState<number[]>([])
  const [isExtend, setIsExtend] = React.useState<boolean>(false)

  const minWidth = useMemo(() => {
    return (windowWidth - 50) / data[0].length
  }, [data])

  useEffect(() => {
    Promise.all(layoutRefs(headerRef.current?.childNodes)).then((res) => {
      setHWidthArr(res)
    })

    layout(headerRef.current).then((res: any) => {
      setHWidth(res)
    })
  }, [headerRef])

  const layoutRefs = (refs: any[]) => {
    const layoutPromises: any[] = []
    // eslint-disable-next-line no-restricted-syntax
    for (const ref of refs) {
      layoutPromises.push(layout(ref))
    }

    return layoutPromises
  }

  const layout = (ref: any) => {
    const handle: any = findNodeHandle(ref)

    return new Promise((resolve) => {
      UIManager.measure(handle, (x, y, width) => {
        resolve(width)
      })
    })
  }

  const processShownData = useMemo(() => {
    if (!Array.isArray(data)) {
      return []
    }

    if (isExtend || unfold) {
      return data
    }

    // if (fixLast) {
    //   return data.slice(0, 4).concat([data[data.length - 1]])
    // }

    return data.slice(0, 5)
  }, [isExtend, data])

  const renderHeader = (row: Item[], index: number) => {
    return (
      <View key={`header_${index}`} style={styles.row} ref={headerRef}>
        {row.map(renderItem)}
      </View>
    )
  }

  const renderRow = (row: Item[], index: number) => {
    return (
      <View key={`row_${index}`} style={styles.row}>
        {row.map(renderItem)}
      </View>
    )
  }

  const renderItem = (item: Item, index: number) => {
    return (
      <View
        key={`item_${index}`}
        style={[
          styles.item,
          {
            minWidth: minWidth > 100 ? minWidth : 100,
            width: item.rowNo !== 0 ? hWidthArr[index] : undefined
          }
        ]}
      >
        <Text
          style={[
            styles.itemText,
            {
              fontWeight:
                (tableDirection === 'row' && item.rowNo === 0) ||
                (tableDirection === 'column' && item.columnNo === 0)
                  ? '600'
                  : '400',
              backgroundColor: item.bgColor || '#fff',
              color: item.color || 'rgba(0, 0, 0, 0.85)',
              paddingVertical: item.bgColor ? 4 : 0,
              paddingHorizontal: item.bgColor ? 8 : 0,
              borderRadius: 8
            }
          ]}
        >
          {item.value}
        </Text>
      </View>
    )
  }

  return (
    <View>
      <ScrollView
        horizontal
        // onScrollBeginDrag={(e) => {
        //   console.log(e)
        // }}
      >
        <View style={styles.container}>
          {processShownData.map((item: Item[], index: number) =>
            index === 0 ? renderHeader(item, index) : renderRow(item, index)
          )}
        </View>
      </ScrollView>
      {hWidth && hWidth > windowWidth - 50 && (
        <View style={{ alignItems: 'flex-end', marginTop: 4 }}>
          <Text style={styles.tip}>左右滑动查看更多</Text>
        </View>
      )}
      {!unfold && data?.length > firstLoadRows && (
        <View style={{ marginTop: 8 }}>
          <TouchableOpacity
            style={{ alignItems: 'center' }}
            onPress={() => {
              setIsExtend((preState) => !preState)
            }}
          >
            <Text style={styles.extensText}>
              {isExtend ? '收起' : '展开全部'}
              <AntDesign
                name={isExtend ? 'up' : 'down'}
                size={12}
                color='#547FFF'
              />
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    borderColor: '#ebeef5',
    borderRightWidth: 1,
    borderBottomWidth: 1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  item: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderColor: '#ebeef5',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemText: {
    fontSize: 12,
    textAlign: 'center',
    wordWrap: 'anywhere'
  },
  tip: {
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: 12
  },
  extensText: {
    color: '#547FFF',
    fontSize: 12
  }
})
