import React, { useMemo, useState } from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import { Popup } from 'antd-mobile'
import { AntDesign } from '@expo/vector-icons'

export interface IReportTitleProps {
  level?: number
  title?: string
  tip?: string
}

export default function ReportTitle(props: IReportTitleProps) {
  const { level, title, tip } = props

  const [visible, setVisible] = useState<boolean>(false)

  const titleFontSize = useMemo(() => {
    switch (Number(level)) {
      case 1:
        return 16
      case 2:
        return 14
      case 3:
        return 12
      case 4:
        return 10
      default:
        return 14
    }
  }, [level])

  return (
    <View style={styles.container}>
      <View style={styles.wrap}>
        {(level === 1 || level === 2) && <View style={styles.decorationLine} />}
        <Text style={[styles.text, { fontSize: titleFontSize }]}>{title}</Text>
        {!!tip && (
          <TouchableOpacity
            onPress={() => {
              setVisible(true)
            }}
          >
            <AntDesign name='questioncircle' size={14} color='#4e7aff' />
          </TouchableOpacity>
        )}
      </View>
      <Popup
        visible={visible}
        closeOnMaskClick
        position='bottom'
        showCloseButton
        bodyStyle={{
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px'
          // height: '40vh'
        }}
        onClose={() => {
          setVisible(false)
        }}
      >
        <View style={styles.popContainer}>
          <Text style={styles.popTitle}>内容提示</Text>
          <Text style={styles.tip}>{tip}</Text>
        </View>
      </Popup>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 16
  },
  wrap: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  decorationLine: {
    width: 4,
    height: 12,
    borderRadius: 10,
    marginRight: 6,
    backgroundColor: '#4e7aff'
  },
  text: {
    marginRight: 6,
    color: 'rgba(0, 0, 0, 0.85)'
  },
  popContainer: {
    padding: 16
  },
  popTitle: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: 21
  },
  tip: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.6)',
    marginVertical: 16
  }
})
