import { ReactNode } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { isString } from 'lodash'
import { Text } from '@/components'

export interface CardProps {
  /**
   * @description 顶部内容
   */
  header?: ReactNode | string
  /**
   * @description 底部内容
   */
  footer?: ReactNode | string
  /**
   * @description 内容
   */
  children?: ReactNode | (() => ReactNode) | string
  /**
   * @description 样式
   */
  style?: StyleProp<ViewStyle>
  /**
   * @description header样式
   */
  headerStyle?: StyleProp<ViewStyle>
  /**
   * @description footer样式
   */
  footerStyle?: StyleProp<ViewStyle>
}

export const Card = (props: CardProps) => {
  const { header, footer, children, style, headerStyle, footerStyle } = props
  const tailwind = useTailwind()
  const renderHeader = () => {
    return header ? (
      isString(header) ? (
        <Text style={headerStyle}>{header}</Text>
      ) : (
        header
      )
    ) : null
  }
  const renderFooter = () => {
    return footer ? (
      isString(footer) ? (
        <Text style={footerStyle}>{footer}</Text>
      ) : (
        footer
      )
    ) : null
  }
  const renderContent = () => {
    return isString(children) ? <Text>{children}</Text> : children
  }
  return (
    <View style={[tailwind('bg-white p-4 rounded-md justify-between'), style]}>
      {renderHeader()}
      {renderContent()}
      {renderFooter()}
    </View>
  )
}
