import { rules } from '@/components/Form/rules'
import { showCaptcha } from '@/services/helpers'
import { AntDesign } from '@expo/vector-icons'
import { useTailwind } from 'tailwind-rn'
import appApi from '@/services/request/app.api'

export const useFormItem = () => {
  const tailwind = useTailwind()

  const getValidCodeItem: any = (
    props: {
      name?: string
      label?: string
      depsPath?: string
      hasCaptcha?: boolean
      sendParams?: RxApp.AppMessageVo
      leballess?: boolean
      despsless?: boolean
      onSendRequest?: (params?: any) => Promise<any>
      [key: string]: unknown
    } = {}
  ) => {
    const {
      name = 'validCode',
      label = '验证码',
      depsPath = 'mobile',
      hasCaptcha,
      sendParams,
      leballess,
      despsless,
      onSendRequest,
      ...itemProps
    } = props
    return {
      type: 'sms',
      name,
      label: leballess ? undefined : label,
      deps: despsless ? undefined : [{ path: depsPath, props: true }],
      required: true,
      props: (_: any, handler: any) => ({
        placeholder: `请输入6位${label}`,
        maxLength: 6,
        type: 'noline',
        keyboardType: 'numeric',
        disabled: despsless ? undefined : !handler.getPathValid(depsPath),
        returnKeyType: 'done',
        prefix: leballess ? (
          <AntDesign name='Safety' size={20} style={tailwind('text-primary')} />
        ) : null,
        containStyle: leballess
          ? undefined
          : tailwind('bg-fill-1 rounded-sm border-none'),
        InputStyle: tailwind('bg-white'),
        onSend: () => {
          if (onSendRequest) return onSendRequest(sendParams)
          const send$ = appApi.sendSmsUsingPOST
          if (hasCaptcha) {
            return new Promise((resolve, reject) => {
              showCaptcha().afterClose((res) => {
                if (!res?.messageToken) {
                  reject()
                  return
                }
                send$({
                  mobile: handler.getPathValue(depsPath),
                  messageToken: res?.messageToken,
                  ...sendParams
                }).then(() => {
                  resolve(true)
                })
              })
            })
          }
          return send$({
            mobile: handler.getPathValue(depsPath),
            ...sendParams
          })
        },
        ...itemProps
      }),
      rules: [rules.validCode]
    }
  }

  const getMobileItem: any = (
    props: {
      name?: string
      label?: string
      required?: boolean
      leballess?: boolean
      [key: string]: unknown
    } = {}
  ) => {
    const {
      name = 'mobile',
      label = '手机号',
      required = true,
      leballess,
      ...itemProps
    } = props
    return {
      type: 'input',
      name,
      label: leballess ? undefined : label,
      required,
      props: {
        placeholder: `请输入${label}`,
        maxLength: 11,
        type: 'noline',
        keyboardType: 'numeric',
        returnKeyType: 'next',
        prefix: leballess ? (
          <AntDesign
            name='mobile1'
            size={20}
            style={tailwind('text-primary')}
          />
        ) : null,
        ...itemProps
      },
      rules: [rules.phoneNumber]
    }
  }

  return { getValidCodeItem, getMobileItem }
}
