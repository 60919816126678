import { useMemo } from 'react'
import { useTailwind } from 'tailwind-rn'
import { Form, FormConfigs, Layout } from '@/components'
import { rules } from '@/components/Form/rules'
import { hiddenMobile, logoutUser } from '@/utils/utils'
import { useGlobalData } from '@/hooks/useGlobalData'
import appApi from '@/services/request/app.api'

export default function PasswordModify() {
  const tailwind = useTailwind()
  const { userInfo } = useGlobalData()

  const onSubmit = async ({ passWordNew, passWordOld }: any) =>
    appApi
      .updateLoginPassWordUsingGET({
        passWordNew,
        passWordOld
      })
      .then(async () => {
        await logoutUser()
        return true
      })

  const configs: FormConfigs = useMemo(
    () => [
      {
        type: 'input',
        name: 'mobile',
        label: '手机号',
        value: hiddenMobile(userInfo?.mobile),
        props: {
          type: 'noline',
          disabled: true
        }
      },
      {
        type: 'input',
        name: 'passWordOld',
        label: '旧密码',
        required: true,
        props: {
          placeholder: '请输入旧密码',
          type: 'noline',
          returnKeyType: 'next',
          secureTextEntry: true
        },
        rules: [rules.password]
      },
      {
        type: 'input',
        name: 'passWordNew',
        label: '新密码',
        required: true,
        props: () => ({
          placeholder: '请输入新密码',
          type: 'noline',
          returnKeyType: 'next',
          secureTextEntry: true
        }),
        rules: [rules.password]
      },
      {
        type: 'input',
        name: 'confPassword',
        label: '确认新密码',
        required: true,
        props: () => ({
          placeholder: '请确认新密码',
          type: 'noline',
          returnKeyType: 'done',
          secureTextEntry: true
        }),
        rules: [
          rules.password,
          {
            message: '密码应该保持一致',
            validator: (field, handler) =>
              field.value === handler.getPathValue('passWordNew')
          }
        ]
      }
    ],
    []
  )

  return (
    <Layout>
      <Form
        configs={configs}
        fieldStyle={tailwind('bg-white rounded-md')}
        formContainStyle={tailwind('flex-1 justify-between')}
        onSubmit={onSubmit}
        subtext='提交'
        labelStyle={{ minWidth: 100 }}
      />
    </Layout>
  )
}
