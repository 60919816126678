/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Pressable, StyleProp, View, ViewStyle } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { Text } from '../Text'

interface CheckboxProps {
  value?: boolean
  onChange?: (checked: boolean) => void
  containStyle?: StyleProp<ViewStyle>
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { value, onChange, children, containStyle } = props
  const tailwind = useTailwind()
  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    if ('value' in props) {
      setChecked(!!value)
    }
  }, [value])

  const handlePress = () => {
    onChange ? onChange?.(!checked) : setChecked(!checked)
  }
  const style = tailwind(
    `w-4 h-4 flex justify-center items-center rounded border ${
      checked ? 'bg-primary border-primary' : 'bg-white border-gray-300'
    }`
  )
  return (
    <Pressable
      style={tailwind(
        `${children != null ? 'flex-row' : 'w-4 h-4'} items-center`
      )}
      onPress={handlePress}
    >
      <Text style={[style, containStyle]} color='white' selectable={false}>
        ✓
      </Text>
      {children != null && <View style={tailwind('flex-row')}>{children}</View>}
      {children != null && <Pressable style={tailwind('flex-1')} />}
    </Pressable>
  )
}
