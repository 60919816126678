import React, { useEffect } from 'react'
import { View } from 'react-native'

const wx = require('weixin-js-sdk')

export interface ResultProps {
  route: {
    params: any
  }
}

export default function Result(props: ResultProps) {
  const { route } = props

  useEffect(() => {
    if (wx) {
      wx.miniProgram.redirectTo({
        url: `/pagesResult/result/Result?type=success&showBack=${route.params?.showBack}`
      })
    }
  }, [])

  return <View />
}
