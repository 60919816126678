import { useMemo, useRef } from 'react'
import { useTailwind } from 'tailwind-rn'
import { toNumber } from 'lodash'
import { Form, FormConfigs, Layout, Notice, Modal } from '@/components'
import { OverlayInstance } from '@/components/Overlay/service'
import { rules } from '@/components/Form/rules'
import { useGlobalData } from '@/hooks/useGlobalData'
import appApi from '@/services/request/app.api'

export default function PersonalSign(props: any) {
  const { navigation, route } = props
  const { page, reportDataId } = route?.params || {}
  const tailwind = useTailwind()
  const modalInstance = useRef<OverlayInstance>()
  const { setGlobalData } = useGlobalData()

  const onSubmit = async (values: any) =>
    appApi.confirmVerificationCodeUsingGET(values).then(() =>
      appApi.selfUsingGET().then(async (res) => {
        await setGlobalData({ userInfo: res })
        if (page === 'wfq') {
          navigation.navigate('ReportQuery', {
            id: reportDataId
          })
        } else {
          navigation.replace('Root', { screen: 'Myself' })
        }
        return true
      })
    )

  const configs: FormConfigs = useMemo(
    () => [
      {
        type: 'sms',
        name: 'code',
        required: true,
        label: '授权码',
        props: () => ({
          placeholder: '请输入6位授权码',
          maxLength: 6,
          type: 'noline',
          keyboardType: 'numeric',
          returnKeyType: 'done',
          InputStyle: tailwind('bg-white rounded-r-md rounded-l-none'),
          containStyle: tailwind('bg-fill-1 border-none'),
          onSend: () => {
            return appApi.sendVerificationCodeUsingGET().then((res) => {
              if (toNumber(res?.signAuthStatus) === 1) {
                modalInstance.current = Modal.show({
                  content: '安心签用户在该项目已授权',
                  confirmText: '确认',
                  cancelText: null,
                  onConfirm: () => {
                    return appApi.selfUsingGET().then(async (self) => {
                      await setGlobalData({ userInfo: self })
                      modalInstance.current?.destroy()
                      if (page === 'wfq') {
                        navigation.navigate('ReportQuery', {
                          id: reportDataId
                        })
                      } else {
                        navigation.replace('Root', { screen: 'Myself' })
                      }
                      return true
                    })
                  },
                  overlayClosable: false,
                  closable: false
                })
              }
              return Promise.resolve(true)
            })
          },
          btnText: '获取授权码'
        }),
        rules: [rules.validCode]
      },
      {
        type: 'agree',
        name: 'contract',
        props: {
          list: [
            {
              label: '电子签章服务协议',
              link: '/static/pdf/电子签章服务协议.pdf'
            }
          ],
          end: true
        },
        rules: [rules.contract]
      }
    ],
    []
  )

  return (
    <Layout>
      <Notice
        title='个人电子签章授权'
        subTitle='请授权平台为您开通电子签章服务，以便您在平台上适应数字证书签署电子合同'
      />
      <Form
        configs={configs}
        fieldStyle={tailwind('bg-white rounded-md')}
        formContainStyle={tailwind('flex-1 justify-between')}
        onSubmit={onSubmit}
        subtext='提交'
      />
    </Layout>
  )
}
