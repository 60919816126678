/* eslint-disable no-undef */
import { useRef } from 'react'
import { Form, FormConfigs, Layout, Notice, Toast } from '@/components'
import { rules } from '@/components/Form/rules'
import { useGlobalData } from '@/hooks/useGlobalData'
import appApi from '@/services/request/app.api'
import { SafeAreaView } from 'react-native'

const wx = require('weixin-js-sdk')

export default function MyEnterprise(props: any) {
  const { navigation } = props
  const form = useRef<any>(null)
  const { setGlobalData } = useGlobalData()

  const configs: FormConfigs = [
    {
      type: 'input',
      name: 'inquireUserName',
      label: '姓名',
      required: true,
      props: {
        type: 'noline',
        placeholder: '请填写姓名',
        maxLength: 50
      }
    },
    {
      type: 'input',
      name: 'inquireIdNum',
      label: '身份证号',
      required: true,
      props: {
        type: 'noline',
        placeholder: '请填写身份证号',
        maxLength: 18
      },
      rules: [rules.idCard]
    },
    {
      type: 'input',
      name: 'mobile',
      label: '手机号',
      required: true,
      props: {
        type: 'noline',
        placeholder: '请填写手机号',
        maxLength: 11
      },
      rules: [rules.phoneNumber]
    },
    {
      type: 'input',
      name: 'inquireOrgName',
      label: '企业名称',
      required: true,
      props: {
        type: 'noline',
        placeholder: '请填写企业名称',
        maxLength: 100
      }
    },
    {
      type: 'input',
      name: 'inquireOrgNo',
      label: '社会信用代码',
      required: true,
      props: {
        type: 'noline',
        placeholder: '请填写社会信用代码'
      }
    },
    {
      type: 'imageUpload',
      name: 'workCardPhoto',
      label: '工牌照片',
      props: {
        maxImageSize: 99,
        multiple: true
      }
    },
    {
      type: 'imageUpload',
      name: 'workEnvPhoto',
      label: '公司环境照片',
      props: {
        maxImageSize: 99,
        multiple: true
      }
    },
    {
      type: 'imageUpload',
      name: 'workFrontPhoto',
      label: '公司前台照片',
      props: {
        maxImageSize: 99,
        multiple: true
      }
    }
  ]
  const onSubmit = async (value: any) =>
    appApi.orgInfoApplyAddPOST(value).then(() =>
      appApi.selfUsingGET().then(async (res) => {
        await setGlobalData({ userInfo: res })

        try {
          wx.miniProgram.postMessage({
            data: { userInfo: res || '' }
          })
          wx.miniProgram.navigateBack()
        } catch (error) {
          Toast.show(JSON.stringify(error))
        }

        return true
      })
    )

  return (
    <SafeAreaView>
      <Layout>
        <Notice title='请添加您的企业信息，以便被查询人了解您的身份' />
        <Form
          ref={form}
          configs={configs}
          onSubmit={onSubmit}
          subtext='确认'
          layout='vertical'
        />
      </Layout>
    </SafeAreaView>
  )
}
