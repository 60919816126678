import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTailwind } from 'tailwind-rn'
import { Text } from '@/components'

interface NoticeProps {
  /**
   * 主标题
   */
  title: string
  /**
   * 副标题
   */
  subTitle?: string
  /**
   * @description 容器样式
   */
  containStyle?: StyleProp<ViewStyle>
}

export const Notice: React.FC<NoticeProps> = ({
  title,
  subTitle,
  containStyle
}) => {
  const tailwind = useTailwind()
  const renderTitle = title ? (
    <Text style={tailwind('text-primary')} weight='semibold'>
      {title}
    </Text>
  ) : null
  const renderSubTitle = subTitle ? (
    <Text style={tailwind('text-primary')} weight='medium'>
      {subTitle}
    </Text>
  ) : null
  return (
    <View
      style={[
        tailwind('bg-primary bg-opacity-10 py-2 px-4 rounded-md mb-4 flex-row'),
        containStyle
      ]}
    >
      <View style={tailwind('mr-2')}>
        <MaterialCommunityIcons
          name='information'
          size={16}
          style={[{ marginTop: 2 }, tailwind('text-primary')]}
        />
      </View>
      <View style={tailwind('flex-1')}>
        {renderTitle}
        {renderSubTitle}
      </View>
    </View>
  )
}
